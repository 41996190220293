var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("fp-map", {
        attrs: {
          height: "calc(100vh - 380px)",
          value: _vm.location,
          disabled: true,
          "map-display-type": _vm.mapLayerType
        },
        on: {
          "update:mapDisplayType": function($event) {
            _vm.mapLayerType = $event
          },
          "update:map-display-type": function($event) {
            _vm.mapLayerType = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }