// TODO: Put all mapping stuff into its own control wrapper
import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import FPMap, {
  FPMapLocation,
  FPMapLocationPoint,
  MapLayerType
} from "@fd/lib/vue/components/FP.Map.vue";

type LocationDialogResult = FPMapLocation | false;
type LocationDialogOptions = {
  location?: FPMapLocation;
  zoom?: number;
  minZoom?: number;
  maxZoom?: number;
  boundsSize?: number | null;
};

const LocationDialog = FDVue.extend({
  name: "sp-location-dialog",

  mixins: [dialogSupport],
  components: {
    "fp-map": FPMap
  },

  data: function() {
    return {
      originalLocation: null as L.LatLngLiteral | null,
      location: null as L.LatLngLiteral | null,
      zoom: undefined as number | undefined,
      minZoom: undefined as number | undefined,
      maxZoom: undefined as number | undefined,
      boundsSize: undefined as number | null | undefined,
      dialogOpen: false
    };
  },
  computed: {
    unwatchedMethodNames() {
      return ["open", "showDialogWithAction", "loadLocation", "loadCurrentLocation"];
    },
    environmentLocation(): FPMapLocationPoint | undefined {
      let environment = this.$store.state.curEnvironment;
      if (!environment.latitude || !environment.longitude) return undefined;
      return { lat: environment.latitude, lng: environment.longitude };
    },
    mapLayerType: {
      get(): MapLayerType {
        return this.$store.state.mapLayerType;
      },
      set(val: MapLayerType) {
        this.$store.commit("SET_MAP_LAYER_TYPE", val);
      }
    },
    latitude: {
      get(): number | undefined {
        return this.location?.lat;
      },
      set(val: number) {
        if (!this.location) return;
        this.location = { lat: val, lng: this.location.lng };
      }
    },
    longitude: {
      get(): number | undefined {
        return this.location?.lng;
      },
      set(val: number) {
        if (!this.location) return;
        this.location = { lat: this.location.lat, lng: val };
      }
    }
  },

  watch: {},

  methods: {
    async loadCurrentLocation() {
      return new Promise<{ latitude: number; longitude: number }>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          position => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          error => {
            console.error(error);
            reject(error);
          },
          {
            enableHighAccuracy: true
          }
        );
      });
    },
    async loadInitialLocation(location?: L.LatLngLiteral) {
      this.originalLocation = location ?? null;
      if (!location) {
        this.processing = true;
        try {
          let currentLocation = await this.loadCurrentLocation();
          location = { lat: currentLocation.latitude, lng: currentLocation.longitude };
        } catch (error) {
          location = { lat: 0, lng: 0 };
          this.handleError(error);
        } finally {
          this.processing = false;
        }
      }
      this.location = location;
    },
    resetLocation() {
      this.location = this.originalLocation;
    },
    async setLocationToCurrent() {
      this.processing = true;
      try {
        let currentLocation = await this.loadCurrentLocation();
        this.location = { lat: currentLocation.latitude, lng: currentLocation.longitude };
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },
    async open(options?: LocationDialogOptions) {
      this.zoom = options?.zoom;
      this.minZoom = options?.minZoom;
      this.maxZoom = options?.maxZoom;
      if (options?.boundsSize !== undefined) this.boundsSize = options?.boundsSize;

      this.optOutOfErrorHandling();
      return await this.showDialogWithAction(() => {
        this.dialogOpen = true;
        this.loadInitialLocation(options?.location);
      });
    },
    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false as LocationDialogResult);
    },
    acceptDialog() {
      this.closeDialog!(this.location as LocationDialogResult);
    }
  },

  mounted() {}
});

export default LocationDialog;

export async function showLocationDialog(
  options?: LocationDialogOptions
): Promise<LocationDialogResult> {
  let dialog = createDialog(LocationDialog);
  return await dialog.open(options);
}

