import { PropType } from "vue";
import FDVue from "@fd/lib/vue";
import { ApplicationType, PaintCode, SetUpType, Tsa, WorkCategory } from "../../../services";
import { NormGroupWithNorms } from "./SP.BuildSheetNormForm.vue";

export default FDVue.extend({
  name: "sp-build-sheet-form-paint",

  components: {
    "sp-build-sheet-norm-form": () => import("./SP.BuildSheetNormForm.vue")
  },
  props: {
    processing: { type: Boolean },
    canEditBuildSheetResponses: { type: Boolean },

    applicationTypes: { type: Array as PropType<Array<ApplicationType>> },
    paintCodes: { type: Array as PropType<Array<PaintCode>> },
    setUpTypes: { type: Array as PropType<Array<SetUpType>> },
    tsas: { type: Array as PropType<Array<Tsa>> },
    workCategories: { type: Array as PropType<Array<WorkCategory>> },

    normGroupsWithResponses: { type: Array as PropType<Array<NormGroupWithNorms>> },
    notes: { type: String },
    equipmentNumber: { type: String },
    diameter: { type: String },
    squareFeet: { type: String },
    linearFeet: { type: String },
    paintReason: { type: String },
    coatNumber: { type: Number },
    applicationTypeID: { type: String },
    paintCodeID: { type: String },
    setUpTypeID: { type: String },
    tsaID: { type: String },
    workCategoryID: { type: String }
  },
  data: function() {
    return {
      //FOR MOCKUP PURPOSES
      paintreason: {
        prep: false,
        touchup: false,
        prime: false,
        damage: false,
        paint: false,
        rework: false
      }
    };
  },
  computed: {
    managedNormGroupsWithResponses: {
      get(): Array<NormGroupWithNorms> {
        return this.normGroupsWithResponses;
      },
      set(val: Array<NormGroupWithNorms>) {
        this.$emit("update:normGroupsWithResponses", val);
      }
    },
    canEditBuildSheet(): boolean {
      return this.canEditBuildSheetResponses ?? false;
    },
    paintReasonValue: {
      get(): string {
        return this.paintReason;
      },
      set(val: string) {
        this.$emit("update:paintReason", val);
      }
    },
    coatNumberValue: {
      get(): number {
        return this.coatNumber;
      },
      set(val: number) {
        console.log(`coatNumberValue.set ${val}`);
        this.$emit("update:coatNumber", val);
      }
    }
  },
  watch: {
    coatNumber(newvalue) {
      console.log(`coatNumber newValue: ${newvalue}`);
    }
  },
  methods: {}
});

