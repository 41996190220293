import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import {
  ApplicationType,
  BuildSheet,
  ContractorNorm,
  ContractorNormGroup,
  PaintCode,
  ScaffoldElevationModifier,
  ScaffoldRequestTypes,
  ScaffoldTypeModifier,
  SetUpType,
  Tsa,
  WorkCategory,
  WorkOrderNormResponse,
  WorkOrderNormResponseWithDetails,
  WorkOrderWithAllDetails,
  applicationTypeService,
  buildSheetService,
  paintCodeService,
  setUpTypeService,
  tsaService,
  walkdownReferenceDataService,
  workCategoryService,
  workOrderNormResponseService,
  workOrderService
} from "../../../services";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import { SortItemsWithName } from "../../../utils/person";
import { NormGroupWithNorms, NormWithResponse } from "../forms/SP.BuildSheetNormForm.vue";

type WorkOrderNormsEntryDialogResult = string | boolean;

const WorkOrderNormsEntryDialog = FDVue.extend({
  name: "sp-work-order-norms-entry-dialog",

  mixins: [dialogSupport],

  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "sp-scaffold-build-sheet-form": () => import("../forms/SP.BuildSheetForm.vue"),
    "sp-paint-build-sheet-form": () => import("../forms/SP.BuildSheetFormPaint.vue"),
    "sp-insulation-build-sheet-form": () => import("../forms/SP.BuildSheetFormInsulation.vue")
  },

  data: function() {
    return {
      workOrder: {} as WorkOrderWithAllDetails,
      buildSheet: {} as BuildSheet,
      // SCAFFOLD
      allScaffoldTypes: [] as ScaffoldTypeModifier[],
      allScaffoldElevations: [] as ScaffoldElevationModifier[],
      // PAINT
      allApplicationTypes: [] as ApplicationType[],
      allPaintCodes: [] as PaintCode[],
      allSetUpTypes: [] as SetUpType[],
      allTsas: [] as Tsa[],
      allWorkCategories: [] as WorkCategory[],

      totalTimesheetHours: 0,
      normGroupsWithNorms: [] as NormGroupWithNorms[],
      responses: [] as WorkOrderNormResponseWithDetails[],

      hazardStatusResult: undefined
    };
  },
  computed: {
    //#region Insulation Mockup Data
    allExchangerTypes() {
      return ["Shell", "Flanges", "Vacuum Rings", "Cone Insulation Rings"];
    },
    allFinishTypes() {
      return ["Smooth", "Box Ribbed", "Corrugated", "Expanded Metal", "Stucco"];
    },
    allInsulationTypes() {
      return [
        "Armaflex(in.)",
        "Cal Sil (in.)",
        "Cal Sil Block (in.)",
        "Cellular Glass (in.)",
        "Cellular Glass Block (in.)",
        "Ceramic Fiber (in.)",
        "Cryogel (mm)",
        "Duct Wrap (in.)",
        "Extruded Polystyrene (mm)",
        "Fiberglass (in.)",
        "Insulthin (mm)",
        "Micro-Lok (in.)",
        "Min Wool Pipe (in.)",
        "Min Wool Tank Wrap (in.)",
        "Perlite (in.)",
        "Perlite Block (in.)",
        "Phenolic Foam (in.)",
        "Polyisocyanurate (in.)",
        "Polyurethane Foam (in.)",
        "Pyrogel HPS (mm)",
        "Pyrogel XTE (mm)",
        "Pyrogel XTF (mm)",
        "Spin Glass (in.)",
        "Temp Mat (in.)",
        "Other"
      ];
    },
    allMetalFinishSizes() {
      return ["0.01", "0.016", "0.02", "0.032", "0.04"];
    },
    allMetalTypes() {
      return ["Aluminum", "Stainless Steel", "Galvanized"];
    },
    allServiceTypes() {
      return ["Hot Service", "Cold Service", "Acoustic Service"];
    },
    allTankTypes() {
      return ["Shell", "Roof"];
    },
    //#endregion
    unwatchedMethodNames(): string[] {
      return ["open", "getFieldRef"];
    },
    isScaffoldWorkOrder(): boolean {
      return (
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Erect ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Modify ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Dismantle
      );
    },
    isPaintWorkOrder(): boolean {
      return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Paint;
    },
    isInsulationWorkOrder(): boolean {
      return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Insulation;
    },
    isMaintenanceWorkOrder(): boolean {
      return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Maintenance;
    }
  },
  watch: {},

  methods: {
    ...mapActions({
      loadStoreNorms: "LOAD_CONTRACTOR_NORMS",
      loadStoreNormGroups: "LOAD_CONTRACTOR_NORM_GROUPS"
    }),
    // #region SCAFFOLD Build Sheet Loading
    async loadScaffoldTypes(): Promise<void> {
      this.allScaffoldTypes = SortItemsWithName(
        await walkdownReferenceDataService.getActiveScaffoldTypeModifiers()
      );
      if (!!this.allScaffoldTypes?.length && !this.buildSheet.scaffoldTypeModifierID?.length) {
        this.buildSheet.scaffoldTypeModifierID = this.allScaffoldTypes[0].id;
      }
    },
    async loadScaffoldElevations(): Promise<void> {
      this.allScaffoldElevations = SortItemsWithName(
        await walkdownReferenceDataService.getAllScaffoldElevationModifiers()
      );
      if (
        !!this.allScaffoldElevations?.length &&
        !this.buildSheet.scaffoldElevationModifierID?.length
      ) {
        this.buildSheet.scaffoldElevationModifierID = this.allScaffoldElevations[0].id;
      }
    },
    // #endregion

    // #region PAINT Build Sheet Loading
    async loadApplicationTypes() {
      this.allApplicationTypes = SortItemsWithName(
        await applicationTypeService.getAll(false, null, null)
      );
    },
    async loadPaintCodes() {
      this.allPaintCodes = SortItemsWithName(await paintCodeService.getAll(false, null, null));
    },
    async loadSetUpTypes() {
      this.allSetUpTypes = SortItemsWithName(await setUpTypeService.getAll(false, null, null));
    },
    async loadTsas() {
      this.allTsas = SortItemsWithName(await tsaService.getAll(false, null, null));
    },
    async loadWorkCategories() {
      this.allWorkCategories = SortItemsWithName(
        await workCategoryService.getAll(false, null, null)
      );
    },
    // #endregion

    async loadTimesheeTrackedHoursForNorms() {
      this.totalTimesheetHours = await workOrderService.getTotalDirectTimeHoursForWorkOrder(
        this.workOrder.id!
      );
    },
    async loadNorms() {
      let contractorID = this.workOrder.assignedContractorID;
      if (!contractorID?.length) return;

      await Promise.all([
        this.loadStoreNorms({
          forcedArchivedState: false
        }),
        this.loadStoreNormGroups({
          forcedArchivedState: false
        }),
        this.loadNormResponses(),
        this.loadTimesheeTrackedHoursForNorms()
      ]);

      let workOrderIsScaffold = this.isScaffoldWorkOrder;
      let isPaintWorkOrder = this.isPaintWorkOrder;
      let isInsulationWorkOrder = this.isInsulationWorkOrder;
      let isMaintenanceWorkOrder = this.isMaintenanceWorkOrder;

      let allNormGroups = this.$store.state.contractorNormGroups.fullList as ContractorNormGroup[];
      let activeNormGroups = allNormGroups.filter(
        x =>
          x.contractorID == contractorID &&
          (workOrderIsScaffold == x.isScaffoldGroup ||
            isPaintWorkOrder == x.isPaintGroup ||
            isInsulationWorkOrder == x.isInsulationGroup ||
            isMaintenanceWorkOrder == x.isMaintenanceGroup)
      );

      let allNorms = this.$store.state.contractorNorms.fullList as ContractorNorm[];
      let activeNorms = allNorms.filter(x => !!x.isActive && x.contractorID == contractorID);
      let normsWithResponses = activeNorms
        .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0))
        .map((n: ContractorNorm) => {
          let response = this.responses.find(r => r.contractorNormID == n.id);
          return {
            ...n,
            answeredDescription: response?.normDescription,
            quantity: response?.quantity ?? 0,
            squareFeet: response?.squareFeet ?? 0,
            hours: response?.hours ?? 0.0,
            text: response?.text
          } as NormWithResponse;
        });

      let answeredNormIDs = normsWithResponses
        .filter(x => !!x.answeredDescription?.length)
        .map(x => x.id);
      let unusedResponses = this.responses.filter(
        r => !answeredNormIDs.includes(r.contractorNormID)
      );
      if (unusedResponses.length) {
        let count = normsWithResponses.length;
        let oldNormsWithResponses = unusedResponses
          .sort((a, b) => (a.normDisplayOrder ?? 0) - (b.normDisplayOrder ?? 0))
          .map(
            r =>
              ({
                id: r.contractorNormID,
                displayOrder: count + (r.normDisplayOrder ?? 0),
                answeredDescription: r.normDescription,
                quantity: r.quantity,
                squareFeet: r.squareFeet,
                hours: r.hours,
                text: r.text
              } as NormWithResponse)
          );
        normsWithResponses = normsWithResponses.concat(oldNormsWithResponses);
      }

      this.normGroupsWithNorms = activeNormGroups.map(group => {
        let norms = normsWithResponses.filter(norm => norm.contractorNormGroupID == group.id);
        return {
          ...group,
          normsWithResponses: norms
        };
      });
    },
    async loadNormResponses() {
      this.responses = await workOrderNormResponseService.getResponsesForWorkOrder(
        this.workOrder.id!
      );
    },
    async loadData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        var buildSheet = await buildSheetService.getByWorkOrderID(this.workOrder.id!);
        this.buildSheet = { ...buildSheet };
        var actions = [this.loadNorms()];

        if (this.isScaffoldWorkOrder) {
          actions.push(...[this.loadScaffoldTypes(), this.loadScaffoldElevations()]);
        }
        if (this.isPaintWorkOrder) {
          actions.push(
            ...[
              this.loadApplicationTypes(),
              this.loadPaintCodes(),
              this.loadSetUpTypes(),
              this.loadTsas(),
              this.loadWorkCategories()
            ]
          );
        }

        await Promise.all([actions]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(workOrder: WorkOrderWithAllDetails): Promise<WorkOrderNormsEntryDialogResult> {
      this.workOrder = workOrder;
      this.loadData();
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        let normsWithResponses = this.normGroupsWithNorms.reduce((responses, group) => {
          return responses.concat(group.normsWithResponses);
        }, [] as Array<NormWithResponse>);
        let data = normsWithResponses
          .filter(
            x =>
              this.$parse.sanitizedNumber(x.quantity) != 0 ||
              this.$parse.sanitizedNumber(x.squareFeet) != 0 ||
              this.$parse.sanitizedNumber(x.hours) != 0 ||
              !!x.text?.length
          )
          .map(
            x =>
              ({
                workOrderID: this.workOrder.id!,
                contractorNormID: x.id,
                normDisplayOrder: this.$parse.number(x.displayOrder),
                normDescription: x.description,
                quantity: this.$parse.number(x.quantity),
                squareFeet: this.$parse.number(x.squareFeet),
                hours: this.$parse.number(x.hours),
                text: x.text
              } as WorkOrderNormResponse)
          );
        console.log(`saveDialog coatNumber: ${this.buildSheet.coatNumber}`);
        await workOrderNormResponseService.updateWorkOrderNormResponses(
          this.workOrder.id!,
          { ...this.buildSheet },
          data
        );
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  }
});

export default WorkOrderNormsEntryDialog;

export async function openWorkOrderNormsEntryDialog(
  workOrder: WorkOrderWithAllDetails
): Promise<WorkOrderNormsEntryDialogResult> {
  let dialog = createDialog(WorkOrderNormsEntryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(workOrder);
}

