import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import fileHandling, {
  componentsFromFileName,
  confirmUniqueName,
  canOpenFileInNewWindow,
  isFilePreviewable,
  FileData,
  isFilePhoto
} from "@fd/lib/vue/mixins/fileHandling";
import {
  ScaffoldRequestWithChildIDs,
  workPackageService,
  Tag,
  ScaffoldSearchResult,
  contractorService,
  messageService,
  noteService,
  workOrderService,
  ScaffoldRequestTypes,
  ExternalLink,
  externalLinkService,
  ScaffoldRequestTypeDetails,
  ScaffoldRequestSubTypeWithParent,
  scaffoldRequestTypeHelper,
  scaffoldRequestSubTypeHelper,
  scaffoldDesignService,
  ScaffoldDesignWithDetails,
  ProjectLocation,
  projectLocationService,
  ContractorWithTags,
  scaffoldService,
  ContractorPurchaseOrder,
  ScaffoldRequestStatuses,
  Person,
  Discipline,
  disciplineService,
  personService,
  Environment,
  WorkOrderWithAllDetails
} from "../services";
import { scaffoldRequestService } from "../services";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import { showItemSelectionDialog } from "./components/ItemSelectionDialog.vue";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import {
  ParseScaffoldRequestWithExtraDetails,
  WorkPackageWithNameCode,
  scaffoldRequest
} from "../dataMixins/scaffoldRequest";
import {
  openActiveWorkForScaffoldDialog,
  WorkForScaffoldDetails
} from "./components/ActiveWorkForScaffoldDialog.vue";
import messaging, {
  ParseMessageWithSenderDetails,
  SortMessagesArray
} from "../dataMixins/messaging";
import notes, { ParseNoteWithSenderDetails, SortNotesArray } from "../dataMixins/notes";
import { WorkForScaffoldDetailsFromScaffoldRequest } from "../dataMixins/scaffoldRequestList";
import {
  ParseWorkOrderWithAllDetails,
  WorkForScaffoldDetailsFromWorkOrder
} from "../dataMixins/workOrderList";
import { openExternalLinkDetails } from "./components/ExternalLinkDialog.vue";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import { Attachment } from "../dataMixins/attachment";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { filterByContractors } from "../services/taggableItems";
import { GetPersonName, HasName, SortItemsWithName } from "../utils/person";
import { BasicSelectItem } from "@fd/lib/vue/utility/select";
import { valueInArray } from "@fd/lib/client-util/array";
import { showRequestApprovalDialog } from "./components/dialogs/SP.RequestApprovalDialog.vue";

type Keyword = Tag;

type FormattedScaffoldDesign = ScaffoldDesignWithDetails & {
  formattedScaffoldNumber: string;
};

export default FDVue.extend({
  name: "sp-scaffold-request-existing",

  mixins: [serviceErrorHandling, tabbedView, fileHandling, scaffoldRequest, messaging, notes],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue"),
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue"),
    "fd-duration-picker": () => import("@fd/lib/vue/components/DurationPicker.vue"),
    "fd-work-package-selector": () => import("./components/WorkPackageSelectionDialog.vue"),
    "fd-subheader": () => import("@fd/lib/vue/components/layout/Subheader.vue"),
    "sp-work-order-scope-details-form": () =>
      import("./components/forms/SP.WorkOrderScopeDetailsForm.vue")
  },

  data: function() {
    return {
      // *** GLOBAL ***
      hideActionButtons: false,
      detailsReadonly: false,
      slidein: false,
      screenLoaded: false,

      // Form data errors
      detailsTabError: false,
      notesTabError: false,
      optionsTabError: false,
      photosTabError: false,
      messagingTabError: false,

      firstTabKey: `1`,
      detailsTab: new PageTab({
        nameKey: "scaffold-requests.existing-scaffold-request.tabs.details",
        key: `1`,
        visible: true
      }),
      scopeTab: {
        tabname: this.$t("scaffold-requests.existing-scaffold-request.tabs.scope-change"),
        key: `2`,
        visible: true
      } as Tab,
      photosTab: {
        tabname: "Photos",
        key: `3`,
        visible: false
      } as Tab,
      notesTab: {
        tabname: "Notes",
        key: `4`,
        visible: false
      } as Tab,
      optionsTab: {
        tabname: "Options",
        key: `5`,
        visible: false
      } as Tab,
      messagingTab: {
        tabname: "Messaging",
        key: `6`,
        visible: false
      } as Tab,
      attachmentsTab: {
        tabname: "Attachments",
        key: "9",
        visible: false
      } as Tab,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      submitting: false,
      cancelling: false,
      approving: false,
      declining: false,

      /*** DATA ***/
      // Reference
      allRequestTypes: [] as ScaffoldRequestTypeDetails[],
      allRequestSubTypes: [] as ScaffoldRequestSubTypeWithParent[],
      requestingContractors: [] as ContractorWithTags[],
      allContractors: [] as ContractorWithTags[],
      allRequestors: [] as (Person & HasName)[],
      allDisciplines: [] as Discipline[],
      allVisibleAreas: [] as ProjectLocation[],
      allVisibleSubAreas: [] as ProjectLocation[],

      /* ** TAG NUMBER ** */
      availableScaffolds: [] as ScaffoldSearchResult[],
      selectedScaffold: null as ScaffoldSearchResult | null | undefined,
      // Determines whether the area/subarea/etc. have been set via scaffold selection
      dataSetByScaffold: false,

      /*** Design NUMBER ***/
      availableDesigns: [] as FormattedScaffoldDesign[],
      selectedDesign: null as FormattedScaffoldDesign | null | undefined,
      // Determines whether the area/subarea/etc. have been set via ScaffoldDesign selection
      dataSetByDesign: false,

      /*** KEYWORDS ***/
      selectedKeywords: [] as Keyword[],

      /*** IWPs ***/
      availableIWPs: [] as WorkPackageWithNameCode[],
      selectedIWPs: [] as WorkPackageWithNameCode[],

      // Main entity

      // *** ATTACHMENTS ***
      touchedFileName: "",
      showPhotoTabAttachmentAlert: false,
      showAttachmentTabPhotoAlert: false,
      tablesearchfiles: "",
      allFiles: [] as FileData[],
      externalLinks: [] as ExternalLink[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },

  computed: {
    // *** GLOBAL ***
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [
        this.notesTab,
        this.scopeTab,
        this.optionsTab,
        this.photosTab,
        this.messagingTab,
        this.attachmentsTab
      ] as Tab[];
    },

    currentUserCanAddMessage(): boolean {
      return true;
    },
    currentUserCanAddNote(): boolean {
      return true;
    },

    requestWillBeArchived(): boolean {
      if (!this.scaffoldRequest.archivedDate) return false;
      return this.scaffoldRequest.archivedDate.getTime() > new Date().getTime();
    },

    requestIsForScaffold(): boolean {
      let requestType = this.requestTypes.find(x => x.value == this.scaffoldRequest.requestType)
        ?.value;
      return (
        requestType == ScaffoldRequestTypes.Erect ||
        requestType == ScaffoldRequestTypes.Dismantle ||
        requestType == ScaffoldRequestTypes.Modify
      );
    },
    requestIsForMaintenance(): boolean {
      return (
        this.requestTypes.find(x => x.value == this.scaffoldRequest.requestType)?.value ==
        ScaffoldRequestTypes.Maintenance
      );
    },
    requestIsForPaint(): boolean {
      return (
        this.requestTypes.find(x => x.value == this.scaffoldRequest.requestType)?.value ==
        ScaffoldRequestTypes.Paint
      );
    },
    requestIsForInsulation(): boolean {
      return (
        this.requestTypes.find(x => x.value == this.scaffoldRequest.requestType)?.value ==
        ScaffoldRequestTypes.Insulation
      );
    },

    requestCanBeSaved(): boolean {
      // Request can be saved if:
      // Current user has permission to submit requests, and the request status is either New, Draft or Declined
      // If the current status is "Walkdown" and the current user is assigned to perform said walkdown
      if (
        this.currentUserCanSubmitScaffoldRequests &&
        !!this.scaffoldRequest.scaffoldRequestStatus &&
        (this.scaffoldRequest.scaffoldRequestStatus <= 1 ||
          this.scaffoldRequest.scaffoldRequestStatus == 4)
      )
        return true;

      return false;
    },

    requestCanBeApproved(): boolean {
      return (
        this.scaffoldRequest.scaffoldRequestStatus == 2 &&
        this.scaffoldRequest.currentUserPermissions.canUpdateApproval
      );
    },

    requestCanBeDeclined(): boolean {
      return (
        this.scaffoldRequest.scaffoldRequestStatus == 2 &&
        this.scaffoldRequest.currentUserPermissions.canUpdateApproval
      );
    },

    // *** DETAILS ***
    canSelectRequestingContractor(): boolean {
      return !!this.requestingContractors && this.requestingContractors.length > 1;
    },
    canSelectScaffoldDesign(): boolean {
      // Designs are used for scaffold jobs now, not requests
      return false;
      // return (
      //   this.requestIsForScaffold &&
      //   this.isErectRequest &&
      //   this.$store.state.curEnvironment.enableScaffoldDesign
      // );
    },
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },
    allKeywords(): Keyword[] {
      return this.$store.state.tags.fullList as Keyword[];
    },
    requestTypes(): BasicSelectItem[] {
      return this.allRequestTypes.map(x => ({ text: x.displayName, value: x.value }));
    },
    requestSubTypes(): BasicSelectItem[] {
      return this.allRequestSubTypes
        .filter(x => x.parentRequestType == this.scaffoldRequest.requestType)
        .map(x => ({ text: this.$t(`scaffold-requests.sub-types.${x.value}`), value: x.value }));
    },
    areas(): ProjectLocation[] {
      let contractorID = this.scaffoldRequest.requestingContractorID;
      if (!contractorID) return [];

      let selectedContractor = this.requestingContractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      var areas = this.allVisibleAreas.filter(
        x =>
          !!selectedContractor?.includesAllAreas || valueInArray(x.id, selectedContractor?.areaIDs)
      );
      if (!this.scaffoldRequest.areaID && !this.detailsReadonly && areas.length == 1) {
        this.$nextTick(() => {
          this.scaffoldRequest.areaID = areas[0].id;
        });
      }
      return areas;
    },
    subAreas(): ProjectLocation[] {
      let areaID = this.scaffoldRequest.areaID;
      if (!areaID) return [];

      let subAreas = this.allVisibleSubAreas.filter(x => {
        return x.parentLocationID == areaID;
      });
      if (!this.scaffoldRequest.subAreaID && !this.detailsReadonly && subAreas.length == 1) {
        this.$nextTick(() => {
          this.scaffoldRequest.subAreaID = subAreas[0].id;
        });
      }
      return subAreas;
    },
    disciplines(): Discipline[] {
      let contractorID = this.scaffoldRequest.requestingContractorID;
      if (!contractorID) return [];

      let selectedContractor = this.requestingContractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      var disciplines = this.allDisciplines.filter(
        x =>
          !!selectedContractor?.includesAllDisciplines ||
          valueInArray(x.id, selectedContractor?.disciplineIDs)
      );

      if (!this.scaffoldRequest.disciplineID && !this.detailsReadonly && disciplines.length == 1) {
        this.$nextTick(() => {
          this.scaffoldRequest.disciplineID = disciplines[0].id;
        });
      }

      return disciplines;
    },
    selectedRequestingContractorName(): string | undefined {
      return this.requestingContractors.find(
        x => x.id == this.scaffoldRequest.requestingContractorID
      )?.name;
    },
    requestors(): Person[] {
      let contractorID = this.scaffoldRequest.requestingContractorID;
      if (!contractorID) return [];

      let selectedContractor = this.requestingContractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      let disciplineID = this.scaffoldRequest.disciplineID;
      if (!disciplineID) return [];

      let selectedDiscipline = this.disciplines.find(x => x.id == disciplineID);
      if (!selectedDiscipline) return [];

      var requestors = this.allRequestors;
      // .filter(x => {
      //   return (
      //     !!selectedContractor?.employeeIDs?.length &&
      //     selectedContractor!.employeeIDs.includes(x.id!) &&
      //     !!selectedDiscipline?.employeeIDs?.length &&
      //     selectedDiscipline!.employeeIDs.includes(x.id!)
      //   );
      // });
      if (
        !this.scaffoldRequest.requestingEmployeeID &&
        !this.detailsReadonly &&
        requestors.length == 1
      ) {
        this.$nextTick(() => {
          this.scaffoldRequest.requestingEmployeeID = requestors[0].id;
        });
      }
      return requestors;
    },
    missingRequiredClientWorkOrderData(): boolean {
      return (
        !!this.scaffoldRequest.isClientWorkOrder &&
        !this.scaffoldRequest.clientWorkOrderReferenceNumber?.length
      );
    },
    missingRequiredChangeOrderData(): boolean {
      return (
        !!this.scaffoldRequest.isChangeOrder &&
        !this.scaffoldRequest.changeOrderReferenceNumber?.length
      );
    },
    missingRequiredReworkData(): boolean {
      return !!this.scaffoldRequest.isRework && !this.scaffoldRequest.reworkReferenceNumber?.length;
    },
    missingRequiredServiceOrderData(): boolean {
      return (
        !!this.scaffoldRequest.isServiceOrder &&
        !this.scaffoldRequest.serviceOrderReferenceNumber?.length
      );
    },
    missingRequiredPurchaseOrderData(): boolean {
      let enablePurchaseOrders = this.$store.state.curEnvironment.enablePurchaseOrders;
      if (!enablePurchaseOrders) {
        this.scaffoldRequest.purchaseOrderID = null;
        return false;
      }

      if (!!this.scaffoldRequest.purchaseOrderID?.length) {
        // Verify the selected purchase order is still in the selectable list, since the contractor may have changed.
        this.scaffoldRequest.purchaseOrderID = this.selectablePurchaseOrders.find(
          x => x.id == this.scaffoldRequest.purchaseOrderID
        )?.id;
      }

      return !this.scaffoldRequest.purchaseOrderID?.length;
    },
    scopeTabError(): boolean {
      return (
        this.missingRequiredClientWorkOrderData ||
        this.missingRequiredChangeOrderData ||
        this.missingRequiredReworkData ||
        this.missingRequiredServiceeOrderData ||
        this.missingRequiredPurchaseOrderData
      );
    },
    allPurchaseOrders(): ContractorPurchaseOrder[] {
      let allPurchaseOrders = this.$store.state.contractorPurchaseOrders
        .fullList as ContractorPurchaseOrder[];
      let sortedPurchaseOrders = allPurchaseOrders.sort(
        (a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0)
      );
      return sortedPurchaseOrders;
    },
    selectablePurchaseOrders(): ContractorPurchaseOrder[] {
      let requestingContractorID = this.allContractors.find(
        x => x.id == this.scaffoldRequest.requestingContractorID
      )?.id;
      if (!requestingContractorID?.length) return [];

      return filterByContractors([requestingContractorID], this.allPurchaseOrders);
    },
    requestCanHaveWalkdown(): boolean {
      return this.requestIsForScaffold;
    },
    defaultScaffoldRequestRequiresWalkDown(): boolean {
      if (!this.scaffoldRequest) return false;
      let curEnvironment = this.$store.state.curEnvironment as Environment;
      if (!curEnvironment) return false;

      let requiresWalkdown;
      if (this.scaffoldRequest.requestType == ScaffoldRequestTypes.Erect)
        requiresWalkdown = curEnvironment.defaultErectScaffoldRequestRequiresWalkDown;
      else if (this.scaffoldRequest.requestType == ScaffoldRequestTypes.Modify)
        requiresWalkdown = curEnvironment.defaultModifyScaffoldRequestRequiresWalkDown;
      else if (this.scaffoldRequest.requestType == ScaffoldRequestTypes.Dismantle)
        requiresWalkdown = curEnvironment.defaultDismantleScaffoldRequestRequiresWalkDown;

      return requiresWalkdown ?? false;
    },
    nonPhotoAttachments(): Attachment[] {
      let attachments = [] as Attachment[];

      this.allFiles.forEach(file => {
        attachments.push({
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          file: file
        });
      });

      this.externalLinks.forEach(link => {
        attachments.push({
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      return attachments.filter(x => !x.isPhoto);
    },
    photoFiles(): FileData[] {
      return this.allFiles.filter(x => x.isPhoto);
    }
  },

  methods: {
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadPurchaseOrders: "LOAD_CONTRACTOR_PURCHASE_ORDERS"
    }),

    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/scaffoldrequests");
    },
    validateScopeForm(): boolean {
      let scopeForm = this.$refs.scopechangeform as any;
      if (!scopeForm) {
        return !this.scopeTabError;
      }
      let formValid = scopeForm.validate();
      let scopeValid = !this.scopeTabError;
      return formValid && scopeValid;
    },

    validate(): boolean {
      console.log(`Validating request details forms.`);
      this.detailsTabError = !((this.$refs.detailsform as HTMLFormElement)?.validate() ?? false);
      this.notesTabError = !((this.$refs.notesform as HTMLFormElement)?.validate() ?? true);
      this.optionsTabError = !((this.$refs.optionsform as HTMLFormElement)?.validate() ?? true);
      this.photosTabError = !((this.$refs.filesform as HTMLFormElement)?.validate() ?? true);
      return (
        this.validateScopeForm() &&
        !(this.detailsTabError || this.notesTabError || this.optionsTabError || this.photosTabError)
      );
    },

    async save(closeOnComplete: boolean) {
      this.processing = true;
      this.saving = true;
      try {
        if (!(await this.saveRequestData(true))) {
          this.processing = false;
          this.saving = false;
          return;
        }
        if (closeOnComplete) {
          this.$router.push(this.$store.getters.backBreadcrumb?.to || "/scaffoldrequests");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    formatDate(item: Date | string | undefined | null): string {
      return DateUtil.stripTimeFromLocalizedDateTime(item);
    },

    async openWorkDetailsForScaffoldDialog(): Promise<boolean> {
      this.processing = true;
      var allRequests = (
        await scaffoldRequestService.getSubmittedRequestsForScaffoldTag(
          this.scaffoldRequest.scaffoldNumber ?? 0
        )
      ).map(x => ParseScaffoldRequestWithExtraDetails(x));
      var convertedRequests = allRequests.map(x => WorkForScaffoldDetailsFromScaffoldRequest(x));

      var allWorkOrders = (
        await workOrderService.getActiveWorkOrdersForScaffoldTag(
          null,
          this.scaffoldRequest.scaffoldNumber ?? 0
        )
      ).map(x => ParseWorkOrderWithAllDetails(x));
      let convertedWorkOrders = allWorkOrders.map(x => WorkForScaffoldDetailsFromWorkOrder(x));

      var allScaffoldWork: WorkForScaffoldDetails[] = convertedRequests.concat(convertedWorkOrders);
      let dismantleScaffoldWork = allScaffoldWork.filter(
        x => x.requestType == ScaffoldRequestTypes.Dismantle
      );
      let otherDismantleIDs = dismantleScaffoldWork.filter(
        x => x.scaffoldRequestId != this.scaffoldRequest.id
      );

      let result = true;
      if (otherDismantleIDs.length > 0) {
        console.log("WO's Scaffold Has Other WOs.  Show Popup!");
        result = await openActiveWorkForScaffoldDialog(
          this.scaffoldRequest.scaffoldNumber,
          allScaffoldWork,
          this.scaffoldRequest.id,
          false,
          true,
          true
        );
      }
      this.processing = false;
      return result;
    },

    async saveRequestData(isDraft: boolean): Promise<boolean> {
      var requestSaved = await this.saveRequestDetails(isDraft);
      if (requestSaved) {
        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.save-success", [
            this.scaffoldRequest.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      }
      return requestSaved;
    },

    async saveRequestDetails(isDraft: boolean): Promise<boolean> {
      if (this.detailsReadonly) return false;
      console.log(`Saving request details...`);

      await this.updateIsDraft(isDraft);
      if (!this.validate()) {
        var message = this.$t("scaffold-requests.existing-scaffold-request.error-message");
        if (this.detailsTabError)
          message += "\n\t- " + this.$t("scaffold-requests.existing-scaffold-request.tabs.details");
        if (this.notesTabError)
          message += "\n\t- " + this.$t("scaffold-requests.existing-scaffold-request.tabs.notes");
        if (this.scopeTabError)
          message +=
            "\n\t- " + this.$t("scaffold-requests.existing-scaffold-request.tabs.scope-change");
        if (this.optionsTabError)
          message += "\n\t- " + this.$t("scaffold-requests.existing-scaffold-request.tabs.options");
        if (this.photosTabError)
          message += "\n\t- " + this.$t("scaffold-requests.existing-scaffold-request.tabs.files");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return false;
      }

      // if this exists but is an "invalid" value, it shouldn't actually exist
      if (
        !!this.scaffoldRequest.requiredUntilDate &&
        this.scaffoldRequest.requiredUntilDate.getTime() ==
          new Date("Mon Jan 01 1900 00:00:00").getTime()
      ) {
        this.scaffoldRequest.requiredUntilDate = undefined;
      }

      // Save web request
      // The following are numeric values entered as strings due to being text field entry.

      this.scaffoldRequest.tagIDs =
        this.selectedKeywords.length > 0 ? this.selectedKeywords.map(x => x.id!) : undefined;

      if (!this.scaffoldRequest.archived) {
        this.scaffoldRequest.archivedDate = null;
      } else if (this.scaffoldRequest.archived && !this.scaffoldRequest.archivedDate) {
        this.scaffoldRequest.archivedDate = new Date(new Date().toUTCString());
      }

      // Get the list of selected IWP IDs
      this.scaffoldRequest.workPackageIDs = this.selectedIWPs.map(iwp => iwp.id!);

      if (!!this.selectedScaffold) {
        this.scaffoldRequest.scaffoldID = this.selectedScaffold.id;
        this.scaffoldRequest.scaffoldNumber = this.selectedScaffold.internalNumber;
      } else {
        this.scaffoldRequest.scaffoldID = undefined;
      }

      await scaffoldRequestService.updateScaffoldRequestDetails(this.scaffoldRequest);

      return true;
    },

    /*** REQUEST ACTIONS ***/
    async cancelRequest() {
      this.processing = true;
      this.cancelling = true;
      try {
        if (!(await this.saveRequestData(true))) {
          this.cancelling = false;
          this.processing = false;
          return;
        }
        // get reason
        var title = this.$t("scaffold-request-approvals.cancel-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.cancelling = false;
          this.processing = false;
          return false;
        }

        let cancelled = await scaffoldRequestService.cancelScaffoldRequest(
          this.scaffoldRequest.id!,
          reason
        );

        if (cancelled) {
          var snackbarPayload = {
            text: this.$t("scaffold-requests.existing-scaffold-request.cancel-success", [
              this.scaffoldRequest.internalRequestNumber
            ]),
            type: "success",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

          this.$router.push(this.$store.getters.backBreadcrumb?.to || "/scaffoldrequests");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.cancelling = false;
        this.processing = false;
      }
    },
    async submitRequest() {
      this.processing = true;
      this.submitting = true;
      try {
        if (!(await this.saveRequestData(false))) {
          this.submitting = false;
          this.processing = false;
          return;
        }

        let submitted = await scaffoldRequestService.submitScaffoldRequest(
          this.scaffoldRequest.id!
        );

        if (submitted) {
          var snackbarPayload = {
            text: this.$t("scaffold-requests.existing-scaffold-request.submit-success", [
              this.scaffoldRequest.internalRequestNumber
            ]),
            type: "success",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

          this.$router.push(this.$store.getters.backBreadcrumb?.to || "/scaffoldrequests");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },
    async approveRequest() {
      var proceed = await this.openWorkDetailsForScaffoldDialog();
      if (!proceed) {
        return;
      }

      this.processing = true;
      this.approving = true;
      try {
        var assignableContractors = [] as ContractorWithTags[];
        if (this.requestIsForScaffold) {
          assignableContractors = this.allContractors.filter(x => !!x.isScaffoldCompany);
        } else if (this.requestIsForPaint) {
          assignableContractors = this.allContractors.filter(x => !!x.isPaintCompany);
        } else if (this.requestIsForInsulation) {
          assignableContractors = this.allContractors.filter(x => !!x.isInsulationCompany);
        } else if (this.requestIsForMaintenance) {
          assignableContractors = this.allContractors.filter(x => !!x.isMaintenanceCompany);
        }

        var requestApprovalDetails = await showRequestApprovalDialog(
          this.scaffoldRequest.internalRequestNumber,
          assignableContractors
        );

        // If details is undefined the dialog was cancelled
        if (!requestApprovalDetails?.contractorID?.length) {
          // Change the value to something else and then back to its current to force a rebind
          this.approving = false;
          this.processing = false;
          return false;
        }

        let newWorkOrderID = await scaffoldRequestService.updateScaffoldRequestApproval(
          this.scaffoldRequest.id!,
          true,
          requestApprovalDetails.contractorID,
          ""
        );

        if (!!newWorkOrderID && !!requestApprovalDetails?.isUrgent) {
          await workOrderService.updateItem(
            newWorkOrderID,
            {
              id: newWorkOrderID,
              isUrgent: true,
              isUrgentDetail: requestApprovalDetails.isUrgentDetail
            } as WorkOrderWithAllDetails,
            "ScaffoldRequestExisting.approveRequest - Setting isUrgent"
          );
        }

        await this.loadRequestDetails();

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.approve-success", [
            this.scaffoldRequest.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },
    async declineRequest() {
      this.processing = true;
      this.declining = true;
      try {
        // get reason
        var title = this.$t("scaffold-request-approvals.decline-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.declining = false;
          this.processing = false;
          return false;
        }

        await scaffoldRequestService.updateScaffoldRequestApproval(
          this.scaffoldRequest.id!,
          false,
          null,
          reason
        );
        await this.loadRequestDetails();

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.decline-success", [
            this.scaffoldRequest.internalRequestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    },

    /*** LOADING ***/
    // DOES NOT manage processing or error message logic
    async loadRequestDetails() {
      var scaffoldRequest = await scaffoldRequestService.getByIDWithDetails(this.$route.params.id);
      this.scaffoldRequest = {
        ...scaffoldRequest,
        archived: !!scaffoldRequest.archivedDate
      };
      var isLocked =
        !!scaffoldRequest.scaffoldRequestStatus &&
        (scaffoldRequest.scaffoldRequestStatus == ScaffoldRequestStatuses.Submitted ||
          scaffoldRequest.scaffoldRequestStatus == ScaffoldRequestStatuses.Approved ||
          scaffoldRequest.scaffoldRequestStatus >= ScaffoldRequestStatuses.Cancelled);
      this.detailsReadonly = isLocked;
    },
    // DOES NOT manage processing or error message logic
    async loadRequestTypes(): Promise<void> {
      if (this.requestIsForMaintenance) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllMaintenanceRequestTypeDetails();
      } else if (this.requestIsForPaint) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllPaintRequestTypeDetails();
      } else if (this.requestIsForInsulation) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllInsulationRequestTypeDetails();
      } else if (this.requestIsForScaffold) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllScaffoldRequestTypeDetails();
      } else {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllRequestTypeDetails();
      }
    },
    // DOES NOT manage processing or error message logic
    async loadRequestSubTypes(): Promise<void> {
      this.allRequestSubTypes = await scaffoldRequestSubTypeHelper.getAllRequestSubTypesWithParent();
    },
    // DOES NOT manage processing or error message logic
    async loadVisibleAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allVisibleAreas = SortItemsWithName(areas);
    },
    // DOES NOT manage processing or error message logic
    async loadVisibleSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allVisibleSubAreas = SortItemsWithName(subAreas);
    },
    // DOES NOT manage processing or error message logic
    async loadRequestingContractors(): Promise<void> {
      let requestingContractors = await await contractorService.getScaffoldRequestingContractors();
      this.requestingContractors = SortItemsWithName(requestingContractors);
    },
    async loadAllContractors() {
      let allContractors = await contractorService.getAll(false, null, null);
      this.allContractors = SortItemsWithName(allContractors);
    },
    // DOES NOT manage processing or error message logic
    async loadDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getAll(false, null, null);
      this.allDisciplines = SortItemsWithName(disciplines);
    },
    // DOES NOT manage processing or error message logic
    async loadRequestors(): Promise<void> {
      let requestors = await personService.getAllActiveRequestors();
      this.allRequestors = SortItemsWithName(
        requestors.map(x => {
          return {
            ...x,
            name: GetPersonName(x)
          };
        })
      );
    },
    async loadScaffolds(searchString: string) {
      if (!searchString?.length) this.availableScaffolds = [];
      else {
        let scaffolds = await scaffoldService.searchAll(searchString, false);
        this.availableScaffolds = scaffolds.map(x => {
          return {
            ...x,
            description: this.getScaffoldDescription(x),
            details: this.getScaffoldDetails(x),
            search: `${x.internalNumber} ${x.existingRequestNumber} ${
              x.externalReferenceNumber
            } ${x.subAreaName ?? x.areaName}`
          } as ScaffoldSearchResult;
        });
      }
    },
    async loadScaffoldDesigns() {
      if (!!this.availableDesigns?.length) return;

      let designs = await scaffoldDesignService.getAllReleased();
      this.availableDesigns = designs.map(
        d =>
          ({
            ...d,
            formattedScaffoldNumber: `T-${`00000${d.scaffoldNumber}`.slice(-5)}`
          } as FormattedScaffoldDesign)
      );
    },

    // *** IWPs ***
    async loadWorkPackages(searchString: string) {
      if (!searchString?.length) this.availableIWPs = [];
      else {
        let allIWPs = await workPackageService.searchAll(searchString);
        this.availableIWPs = allIWPs.map(x => {
          return {
            ...x,
            nameCode: `${x.name} | ${x.activityID}`
          };
        });
      }
    },

    /*** NOTES ***/
    async addNewNote() {
      if (!this.newNoteText.length || !this.scaffoldRequest?.id?.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newNote = await noteService.addNewNoteForScaffoldRequest(
          this.newNoteText,
          this.scaffoldRequest.id
        );
        this.inlineMessage.message = "";
        let noteToAdd = ParseNoteWithSenderDetails(newNote);
        noteToAdd.isNew = true;
        this.notes.push(noteToAdd);
        this.notes = SortNotesArray(this.notes);
        this.newNoteText = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    /*** MESSAGES ***/
    async addNewMessage() {
      if (!this.newMessageText.length || !this.scaffoldRequest?.id?.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newMessage = await messageService.addNewMessageForScaffoldRequest(
          this.newMessageText,
          this.scaffoldRequest.id
        );
        this.inlineMessage.message = "";
        this.messages.push(ParseMessageWithSenderDetails(newMessage, this.curUserID));
        this.messages = SortMessagesArray(this.messages);
        this.newMessageText = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // *** ATTACHMENTS ***
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: Attachment) {
      if (!item.canOpenInNew) return;

      if (!!item.file && item.canOpenInNew) {
        await this.openFileInNewWindow(item.file);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async editAttachment(item: Attachment) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.file && item.file.isPreviewable) {
        await this.editFile(item.file);
      } else if (!!item.file) {
        await this.editNameForFile(item.file);
      }
    },
    async deleteAttachment(item: Attachment) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.file) {
        await this.deleteFile(item.file);
      }
    },

    // Links
    async loadLinks() {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        var links = await externalLinkService.getByScaffoldRequestID(this.scaffoldRequest.id!);
        this.externalLinks = links;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        newLink.scaffoldRequestID = this.scaffoldRequest.id;
        await externalLinkService.addItem(newLink);
        this.externalLinks.push(newLink);

        this.showAttachmentTabPhotoAlert = false;
        this.showPhotoTabAttachmentAlert = false;

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.save-link-success", [
            newLink.name
          ]),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = newLink.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        let currentProcessing = this.processing;
        this.processing = true;
        try {
          await externalLinkService.updateItem(link.id!, {
            ...link,
            name: editedLink.name,
            address: editedLink.address
          });
          link.name = editedLink.name;
          link.address = editedLink.address;

          var snackbarPayload = {
            text: this.$t("scaffold-requests.existing-scaffold-request.update-link-success", [
              link.name
            ]),
            type: "success"
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          this.touchedFileName = link.name ?? "";
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = currentProcessing;
        }
      }
    },
    async deleteLink(link: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        await externalLinkService.deleteItem(link.id!);
        this.externalLinks.splice(this.externalLinks.indexOf(link), 1);

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.delete-link-success", [
            link.name
          ]),
          type: "info",
          undoCallback: async () => {
            await this.saveNewExternalLink(link);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = link.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },

    // Files & Photos
    async loadFiles() {
      var fileNames = await scaffoldRequestService.getScaffoldRequestFileList(
        this.scaffoldRequest.id!
      );
      this.allFiles = fileNames.map(function(fileName) {
        return {
          name: fileName,
          isPreviewable: isFilePreviewable(fileName),
          isPhoto: isFilePhoto(fileName)
        } as FileData;
      });
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async selectNewFile(originalFile: File) {
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      if (!fileData) return;

      // GIF files with animations will lose their animation during this process
      // Both due to the quality compression done above (resizing the dimensions of an animated GIF does nothing), and also going through the edit image process
      // This is OK as we shouldn't need animations for any reason
      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        await this.saveNewFileData(fileData);
      }
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        await this.saveNewFileData(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: FileData, originalFileName: string) {
      if (!fileData) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // If we're only renaming the file, the data may not be downloaded yet
          let fileNameToDownload = originalFileName ?? fileData.name;
          fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
            this.scaffoldRequest.id!,
            fileNameToDownload
          );
        }
        await scaffoldRequestService.uploadScaffoldRequestFile(
          this.scaffoldRequest.id!,
          fileData.name,
          fileData.file as Blob
        );

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await scaffoldRequestService.deleteScaffoldRequestFile(
            this.scaffoldRequest.id!,
            originalFileName
          );
        }
        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.update-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.update-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData | undefined) {
      if (!fileData) return;

      this.processing = true;
      try {
        await scaffoldRequestService.uploadScaffoldRequestFile(
          this.scaffoldRequest.id!,
          fileData.name,
          fileData.file as Blob
        );

        this.allFiles.push(fileData);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.save-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.save-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.touchedFileName = fileData.name;
        this.showPhotoTabAttachmentAlert = this.selectedTab == this.photosTab && !fileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.selectedTab == this.attachmentsTab && fileData.isPhoto == true;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.saveEditedFileData(fileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    editFile(fileData: FileData) {
      if (!fileData.isPhoto) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      if (!!fileData.file) {
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.editImageSource = `/services/FormidableDesigns.Services.V1.ScaffoldRequestService.DownloadScaffoldRequestFile?requestId=${this.scaffoldRequest.id}&fileName=${fileData.name}`;
      }
    },
    async downloadFile(fileData: FileData) {
      if (!!fileData.file) {
        downloadBlob(fileData.file, fileData.name);
        return;
      }

      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.scaffoldRequest.id!,
          fileName
        );
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        // the data probably hasn't been downloaded yet
        fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.scaffoldRequest.id!,
          fileData.name
        );
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async viewFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;

      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        var file = await scaffoldRequestService.downloadScaffoldRequestFile(
          this.scaffoldRequest.id!,
          fileData.name
        );
        fileData.file = file;
      }
      if (!!fileData.file) {
        this.imageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.imageSource = `/services/FormidableDesigns.Services.V1.ScaffoldRequestService.DownloadScaffoldRequestFile?requestId=${this.scaffoldRequest.id}&fileName=${fileData.name}`;
      }
    },
    async deleteFile(fileData: FileData) {
      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await scaffoldRequestService.downloadScaffoldRequestFile(
            this.scaffoldRequest.id!,
            fileData.name
          );
        }
        await scaffoldRequestService.deleteScaffoldRequestFile(
          this.scaffoldRequest.id!,
          fileData.name
        );

        this.allFiles.splice(this.allFiles.indexOf(fileData), 1);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.delete-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.delete-file-success", [
              fileData.name
            ]);
        var snackbarPayload = {
          text: snackbarText,
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  watch: {
    selectedScaffold: async function(newValue, oldValue) {
      if (!!oldValue && !newValue) {
        this.scaffoldRequest.scaffoldID = undefined;
        this.scaffoldRequest.areaID = undefined;
        this.scaffoldRequest.subAreaID = undefined;
        this.scaffoldRequest.specificWorkLocation = undefined;
        this.dataSetByScaffold = false;
      } else if (!!newValue) {
        let scaffold: ScaffoldSearchResult = newValue;
        this.scaffoldRequest.scaffoldID = scaffold.id;
        this.scaffoldRequest.scaffoldNumber = scaffold.internalNumber;
        if (this.screenLoaded) {
          var proceed = await this.openWorkDetailsForScaffoldDialog();
          if (!proceed) {
            this.scaffoldRequest.scaffoldID = undefined;
            let self = this;
            this.$nextTick(() => {
              self.selectedScaffold = null;
            });
            return;
          }
        }
        this.scaffoldRequest.areaID = scaffold.areaID;
        this.scaffoldRequest.subAreaID = scaffold.subAreaID;
        this.scaffoldRequest.specificWorkLocation = scaffold.specificWorkLocation;
        this.dataSetByScaffold = true;
        this.dataSetByDesign = false;
      }
    },
    selectedDesign: async function(newValue, oldValue) {
      if (!!oldValue && !newValue) {
        this.scaffoldRequest.scaffoldID = undefined;
        this.scaffoldRequest.scaffoldDesignID = undefined;
        this.scaffoldRequest.areaID = undefined;
        this.scaffoldRequest.subAreaID = undefined;
        this.scaffoldRequest.specificWorkLocation = undefined;
        this.dataSetByDesign = false;
      } else if (!!newValue) {
        let design: ScaffoldDesignWithDetails = newValue;
        this.scaffoldRequest.scaffoldNumber = design.scaffoldNumber;
        this.scaffoldRequest.scaffoldID = design.scaffoldID;
        this.scaffoldRequest.scaffoldDesignID = design.id;

        this.scaffoldRequest.areaID = this.allVisibleAreas.find(x => x.id == design.areaID)?.id;
        this.scaffoldRequest.subAreaID = this.allVisibleSubAreas.find(
          x => x.id == design.subAreaID
        )?.id;
        this.scaffoldRequest.specificWorkLocation = design.specificWorkLocation;
        this.dataSetByDesign = true;
        this.dataSetByScaffold = false;
      }
    },
    "scaffoldRequest.requestType": function(newValue, oldValue) {
      // We want to force the `isWalkdownRequired` value to change when the user manually changes the type
      // But this is also called after loading data and setting the requestType object and we don't want to undo the saved value whenever the screen loads
      if (!this.scaffoldRequest || !this.screenLoaded) return;

      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (newValue == 1) this.selectedScaffold = null;

      // The request type has changed, reset the `isWalkdownRequired` based on the environment settings.
      // This may "undo" a user turning on walkdown for an optional type, but we need to make sure it doesn't get stuck on when moving away from a walkdown-mandatory type
      this.scaffoldRequest.isWalkdownRequired = this.defaultScaffoldRequestRequiresWalkDown;
    },
    scaffoldRequest(newValue: ScaffoldRequestWithChildIDs) {
      var breadcrumbText = `#${newValue.internalRequestNumber}`;
      if (newValue.scaffoldRequestStatus == 3 || newValue.scaffoldRequestStatus! > 5) {
        breadcrumbText = `WO #${newValue.workOrderNumber} (${this.$t(
          "scaffold-requests.request"
        )} # ${newValue.internalRequestNumber})`;
      }

      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if (this.$route.name == "RequestExisting") {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffoldrequests") {
          this.notifyNewBreadcrumb({
            text: this.$t("scaffold-requests.list-title"),
            to: "/scaffoldrequests",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        this.notifyNewBreadcrumb({
          text: breadcrumbText,
          to: `/scaffoldrequests/${newValue.id}`
        });
      } else if (this.$route.name == "RequestApprovalExisting") {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffoldrequestapprovals") {
          this.notifyNewBreadcrumb({
            text: this.$t("scaffold-request-approvals.list-title"),
            to: "/scaffoldrequestapprovals",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        this.notifyNewBreadcrumb({
          text: breadcrumbText,
          to: `/scaffoldrequestapprovals/${newValue.id}`
        });
      }
    },
    "scaffoldRequest.areaID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.scaffoldRequest.areaID &&
        !this.areas.map(x => x.id).includes(this.scaffoldRequest.areaID)
      ) {
        this.scaffoldRequest.areaID = null;
      }
      if (
        !!this.scaffoldRequest.subAreaID &&
        !this.subAreas.map(x => x.id).includes(this.scaffoldRequest.subAreaID)
      ) {
        this.scaffoldRequest.subAreaID = null;
      }
    },
    "scaffoldRequest.subAreaID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.scaffoldRequest.subAreaID &&
        !this.subAreas.map(x => x.id).includes(this.scaffoldRequest.subAreaID)
      ) {
        this.scaffoldRequest.subAreaID = null;
      }
    },
    "scaffoldRequest.requestingContractorID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.scaffoldRequest.disciplineID &&
        !this.disciplines.map(x => x.id).includes(this.scaffoldRequest.disciplineID)
      ) {
        this.scaffoldRequest.disciplineID = null;
      }

      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.scaffoldRequest.requestingEmployeeID &&
        !this.requestors.map(x => x.id).includes(this.scaffoldRequest.requestingEmployeeID)
      ) {
        this.scaffoldRequest.requestingEmployeeID = null;
      }
    },
    "scaffoldRequest.disciplineID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !!this.scaffoldRequest.requestingEmployeeID &&
        !this.requestors.map(x => x.id).includes(this.scaffoldRequest.requestingEmployeeID)
      ) {
        this.scaffoldRequest.requestingEmployeeID = null;
      }
    }
  },

  created: async function() {
    // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
    // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
    if (this.$route.name == "RequestExisting") {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffoldrequests") {
        this.notifyNewBreadcrumb({
          text: this.$t("scaffold-requests.list-title"),
          to: "/scaffoldrequests",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: ``,
        to: `/scaffoldrequests/${this.$route.params.id}`
      });

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "scaffold-request-existing",
        parentalContext: "scaffoldrequests",
        searchStringForFiltering: "",
        selectedTab: this.firstTabKey
      });
    } else if (this.$route.name == "RequestApprovalExisting") {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffoldrequestapprovals") {
        this.notifyNewBreadcrumb({
          text: this.$t("scaffold-request-approvals.list-title"),
          to: "/scaffoldrequestapprovals",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: ``,
        to: `/scaffoldrequestapprovals/${this.$route.params.id}`
      });

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "scaffold-request-approvals-existing",
        parentalContext: "scaffold-request-approvals",
        searchStringForFiltering: "",
        selectedTab: this.firstTabKey
      });
    }

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    this.processing = true;

    try {
      await Promise.all([
        this.loadVisibleAreas(),
        this.loadVisibleSubAreas(),
        this.loadRequestingContractors(),
        this.loadAllContractors(),
        this.loadDisciplines(),
        this.loadRequestors(),
        this.$store.dispatch("LOAD_TAGS"),
        this.loadRequestTypes(),
        this.loadRequestSubTypes(),
        this.loadPurchaseOrders({
          forcedArchivedState: this.showArchived,
          archivedFromDate: this.showArchivedFromDate,
          archivedToDate: this.showArchivedToDate
        })
      ]);

      // processing has been set to false after the reference data loaded.
      this.processing = true;
      await this.loadRequestDetails();
      // Types did an initial load so the data is available for the lists when the request loads
      // But once we know what kind of request this is, we reload them to restrict the types based on the request type (scaff/maint/ins/paint)
      await this.loadRequestTypes();
      // Processing has automatically been set to false after this load happens
      this.processing = true;

      // Requested By ID is required (which in turn requires a discipline and contractor) so this should never happen
      // However, contractor is required and can be hidden and therefore if empty the user cannot fix it themselves.
      if (
        !this.scaffoldRequest.requestingContractorID &&
        !this.detailsReadonly &&
        !!this.curUserAccess.homeContractorID
      )
        this.scaffoldRequest.requestingContractorID = this.curUserAccess.homeContractorID;

      if (!!this.scaffoldRequest.scaffoldID) {
        await this.loadScaffolds(`${this.scaffoldRequest.scaffoldID}`);
        this.selectedScaffold = this.availableScaffolds.find(
          x => x.id == this.scaffoldRequest.scaffoldID
        );
        this.dataSetByScaffold = true;
      }

      if (!!this.scaffoldRequest.scaffoldDesignID) {
        await this.loadScaffoldDesigns();
        this.selectedDesign = this.availableDesigns.find(
          x => x.id == this.scaffoldRequest.scaffoldDesignID
        );
        this.dataSetByDesign = true;
      }

      this.selectedKeywords = this.scaffoldRequest.tagIDs
        ? (this.scaffoldRequest.tagIDs
            .map(x => this.allKeywords.find(y => y.id == x))
            .filter(x => !!x) as Keyword[])
        : [];

      // The control displaying Work Packages NEEDS data to exist in its source in order to be displayed in the control
      // Therefore, we start by loading all referenced IWPs from the request into the source data
      if (this.scaffoldRequest.workPackageIDs?.length) {
        let iwps = [] as WorkPackageWithNameCode[];
        for (const iwpID of this.scaffoldRequest.workPackageIDs) {
          let iwpsForID = await workPackageService.searchAll(iwpID);
          iwps = iwps.concat(
            iwpsForID.map(x => {
              return { ...x, nameCode: `${x.name} | ${x.activityID}` };
            })
          );
        }
        this.availableIWPs = iwps;

        this.selectedIWPs = this.availableIWPs.filter(iwp => {
          return (
            this.scaffoldRequest.workPackageIDs &&
            this.scaffoldRequest.workPackageIDs.includes(iwp.id!)
          );
        }, this);
      }

      this.processing = true;
      let messages = await messageService.getForScaffoldRequest(this.scaffoldRequest.id!);
      this.messages = SortMessagesArray(messages).map(x =>
        ParseMessageWithSenderDetails(x, this.curUserID)
      );
      let notes = await noteService.getForScaffoldRequest(this.scaffoldRequest.id!);
      this.notes = notes.map(x => ParseNoteWithSenderDetails(x));
      if (!!this.scaffoldRequest.notes?.length) {
        this.notes.push({
          isPinned: true,
          isNew: false,
          initials: "",
          name: `${this.$t("scaffold-requests.notes")}`,
          role: "",
          date: "",
          time: "",
          text: this.scaffoldRequest.notes,
          sender: undefined,
          id: undefined,
          noteThreadID: undefined,
          personID: undefined,
          sentTime: new Date(0),
          archivedDate: undefined,
          created: undefined,
          createdBy: undefined,
          updated: undefined,
          updatedBy: undefined
        });
      }
      this.notes = SortNotesArray(this.notes);

      await this.loadFiles();
      await this.loadLinks();

      this.screenLoaded = true;
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

