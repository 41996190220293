var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "fd-norm-answer-container", attrs: { flat: "", tile: "" } },
    [
      !_vm.normGroupsWithResponses.length
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { "justify-content": "center" },
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c("fd-alert", {
                        attrs: {
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.no-norms-configured-message"
                          ),
                          type: "hint"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c(
                "v-expansion-panels",
                {
                  staticStyle: { "border-radius": "0" },
                  attrs: { multiple: "", accordion: "" },
                  model: {
                    value: _vm.openPanels,
                    callback: function($$v) {
                      _vm.openPanels = $$v
                    },
                    expression: "openPanels"
                  }
                },
                _vm._l(_vm.normGroupsWithResponses, function(group, index) {
                  return _c(
                    "v-expansion-panel",
                    {
                      key: index,
                      staticClass: "fd-contractor-norm-group-panel"
                    },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(_vm._s(group.name))
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        [
                          !group.normsWithResponses ||
                          group.normsWithResponses.length == 0
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            "justify-content": "center"
                                          },
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("fd-alert", {
                                            attrs: {
                                              label: _vm.$t(
                                                "build-sheet.norm-group-has-no-norms"
                                              ),
                                              type: "hint"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(group.normsWithResponses, function(norm) {
                            return _c(
                              "v-row",
                              {
                                key: norm.id,
                                staticClass: "fd-norm-answer-row",
                                attrs: { "no-gutters": "" }
                              },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "fd-norm-answer-content" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-1",
                                        attrs: { cols: "5", sm: "7" }
                                      },
                                      [
                                        !!norm.description
                                          ? _c(
                                              "span",
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(norm.description) +
                                                    "\n                  "
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              !!norm.answeredDescription &&
                                                              norm.answeredDescription !=
                                                                norm.description
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-1",
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "black",
                                                                          dark:
                                                                            ""
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "info"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          dense: "",
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "norms.work-order-entry-dialog.previous-norm-description-message"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass: "pl-3",
                                                        attrs: {
                                                          dense: "",
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              norm.answeredDescription
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !norm.description
                                          ? _c(
                                              "v-row",
                                              {
                                                attrs: {
                                                  "no-gutters": "",
                                                  dense: ""
                                                }
                                              },
                                              [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value:
                                                      norm.answeredDescription
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "black",
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [_vm._v("info")]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "norms.work-order-entry-dialog.norm-is-no-longer-active-message"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { staticClass: "grow pl-0 py-1" },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "pl-1",
                                                attrs: { cols: "6" }
                                              },
                                              [
                                                _c("fd-text-field", {
                                                  ref: _vm.getFieldRef(
                                                    "quantity",
                                                    norm
                                                  ),
                                                  refInFor: true,
                                                  staticClass:
                                                    "fd-norm-answer-text-field",
                                                  attrs: {
                                                    "data-cy": "quantity",
                                                    value: norm.quantity,
                                                    dense: "",
                                                    "hide-details": "",
                                                    disabled: _vm.processing,
                                                    readonly: !_vm.canEditBuildSheet,
                                                    label: _vm.$t(
                                                      "norms.work-order-entry-dialog.quantity-label"
                                                    ),
                                                    "hide-spin-buttons": "",
                                                    type: "number",
                                                    min: "0",
                                                    "highlight-on-focus": ""
                                                  },
                                                  on: {
                                                    input: v =>
                                                      _vm.normQuantityUpdated(
                                                        norm,
                                                        v
                                                      ),
                                                    keydown: [
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "up",
                                                            38,
                                                            $event.key,
                                                            ["Up", "ArrowUp"]
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                        return (e =>
                                                          _vm.selectPreviousField(
                                                            e,
                                                            "quantity",
                                                            norm
                                                          )).apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "down",
                                                            40,
                                                            $event.key,
                                                            [
                                                              "Down",
                                                              "ArrowDown"
                                                            ]
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                        return (e =>
                                                          _vm.selectNextField(
                                                            e,
                                                            "quantity",
                                                            norm
                                                          )).apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        return (e =>
                                                          _vm.enterPressed(
                                                            e,
                                                            "quantity",
                                                            norm
                                                          )).apply(
                                                          null,
                                                          arguments
                                                        )
                                                      }
                                                    ]
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "pl-1",
                                                attrs: { cols: "6" }
                                              },
                                              [
                                                _c("fd-text-field", {
                                                  ref: _vm.getFieldRef(
                                                    "hours",
                                                    norm
                                                  ),
                                                  refInFor: true,
                                                  staticClass:
                                                    "fd-norm-answer-text-field",
                                                  attrs: {
                                                    "data-cy": "hours",
                                                    value: norm.hours,
                                                    dense: "",
                                                    "hide-details": "",
                                                    disabled: _vm.processing,
                                                    readonly: !_vm.canEditBuildSheet,
                                                    label: _vm.$t(
                                                      "norms.work-order-entry-dialog.hours-label"
                                                    ),
                                                    "hide-spin-buttons": "",
                                                    type: "number",
                                                    min: "0",
                                                    "highlight-on-focus": ""
                                                  },
                                                  on: {
                                                    input: v =>
                                                      _vm.normHoursUpdated(
                                                        norm,
                                                        v
                                                      ),
                                                    keydown: [
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "up",
                                                            38,
                                                            $event.key,
                                                            ["Up", "ArrowUp"]
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                        return (e =>
                                                          _vm.selectPreviousField(
                                                            e,
                                                            "hours",
                                                            norm
                                                          )).apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "down",
                                                            40,
                                                            $event.key,
                                                            [
                                                              "Down",
                                                              "ArrowDown"
                                                            ]
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                        return (e =>
                                                          _vm.selectNextField(
                                                            e,
                                                            "hours",
                                                            norm
                                                          )).apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                      function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        return (e =>
                                                          _vm.enterPressed(
                                                            e,
                                                            "hours",
                                                            norm
                                                          )).apply(
                                                          null,
                                                          arguments
                                                        )
                                                      }
                                                    ]
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    !!_vm.canEditBuildSheet
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "shrink pl-0 pr-2 pt-1 pr-sm-1"
                                          },
                                          [
                                            !_vm.$vuetify.breakpoint.xs
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      "x-small": "",
                                                      disabled: _vm.processing
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.clearNormResponses(
                                                          norm
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("common.clear")
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.$vuetify.breakpoint.xs
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "fd-build-sheet-responsive-clear-button",
                                                    attrs: {
                                                      "x-small": "",
                                                      rounded: "",
                                                      outlined: "",
                                                      disabled: _vm.processing
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.clearNormResponses(
                                                          norm
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "14px"
                                                        },
                                                        attrs: { dark: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-close\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }