// TODO: Put all mapping stuff into its own control wrapper
import FDVue from "@fd/lib/vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Vue, { PropType } from "vue";
import FPMap, {
  FPMapLocation,
  FPMapLocationColour,
  FPMapLocationPoint,
  MapLayerType
} from "@fd/lib/vue/components/FP.Map.vue";
import SPScaffoldLocationPopup, { LocationPopupScaffold } from "./SP.ScaffoldLocationPopup.vue";
import { ScaffoldStatuses, WorkOrderStatuses } from "../../../../services";
import { valueInArray } from "../../../../../../lib/client-util/array";
const iconOptions = {
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
};
L.Icon.Default.mergeOptions(iconOptions);

export type ScaffoldWithLocation = LocationPopupScaffold & {};

const groupingRadius = 0.0001; // ~40 feet

export default FDVue.extend({
  name: "fd-my-location",

  props: {
    scaffolds: { type: Array as PropType<Array<ScaffoldWithLocation>> },
    zoom: { type: Number },
    minZoom: { type: Number },
    maxZoom: { type: Number },
    height: { type: String, default: "400px" }
  },

  components: {
    "sp-scaffold-locations-popup": SPScaffoldLocationPopup,
    "fp-map": FPMap
  },

  data: function() {
    return {
      selectedScaffolds: undefined as Array<ScaffoldWithLocation> | undefined
    };
  },

  computed: {
    environmentLocation(): FPMapLocationPoint | undefined {
      let environment = this.$store.state.curEnvironment;
      if (!environment.latitude || !environment.longitude) return undefined;
      return { lat: environment.latitude, lng: environment.longitude };
    },
    locations(): FPMapLocation[] {
      let scaffoldsWithLocations = this.scaffolds.filter(x => !!x.latitude && !!x.longitude);
      let processedScaffoldIDs = [] as string[];
      let locations: FPMapLocation[] = [];
      for (let scaffold of scaffoldsWithLocations) {
        if (valueInArray(scaffold.id, processedScaffoldIDs)) {
          // console.log(`\t\t Included in previous grouping`);
          continue;
        }

        let colour: FPMapLocationColour = "blue";
        if (scaffold.lastWorkOrderStatus == WorkOrderStatuses.Started) colour = "green";
        else if (scaffold.lastWorkOrderStatus == WorkOrderStatuses.OnHold) colour = "orange";
        else if (
          scaffold.scaffoldStatus == ScaffoldStatuses.Dismantled ||
          scaffold.scaffoldStatus == ScaffoldStatuses.Cancelled
        ) {
          colour = "red";
        }
        let mapLocation = {
          lat: scaffold.latitude!,
          lng: scaffold.longitude!,
          colour: colour
        } as FPMapLocation;

        let relatedScaffolds = scaffoldsWithLocations.filter(
          x =>
            x.latitude! < scaffold.latitude! + groupingRadius &&
            x.latitude! > scaffold.latitude! - groupingRadius &&
            x.longitude! < scaffold.longitude! + groupingRadius &&
            x.longitude! > scaffold.longitude! - groupingRadius
        );
        mapLocation.groupedLocations = relatedScaffolds.length;
        processedScaffoldIDs = processedScaffoldIDs.concat(relatedScaffolds.map(x => x.id!));

        locations.push(mapLocation);
      }
      return locations;
    },
    mapLayerType: {
      get(): MapLayerType {
        return this.$store.state.mapLayerType;
      },
      set(val: MapLayerType) {
        this.$store.commit("SET_MAP_LAYER_TYPE", val);
      }
    }
  },

  methods: {
    getLocationHtml(): string {
      let popup = this.$refs.currentpopup as Vue;
      return popup?.$el.outerHTML ?? "";
    },
    locationSelected(location: FPMapLocation) {
      let relatedScaffolds = this.scaffolds.filter(
        x =>
          !!x.latitude &&
          !!x.longitude &&
          x.latitude < location.lat + groupingRadius &&
          x.latitude > location.lat - groupingRadius &&
          x.longitude < location.lng + groupingRadius &&
          x.longitude > location.lng - groupingRadius
      );
      this.selectedScaffolds = relatedScaffolds;
      // let scaffold = this.scaffolds.find(
      //   x => x.latitude == location.lat && x.longitude == location.lng
      // );
      // this.selectedScaffold = scaffold;
    },
    locationDeselected() {
      // this.selectedScaffold = undefined;
      this.selectedScaffolds = undefined;
    }
  },

  mounted() {},

  beforeDestroy() {}
});

