import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { isoDateWithOffsetString } from "@fd/lib/client-util/datetime";
import { BasicSelectItem } from "@fd/lib/vue/utility/select";
import { Environment } from "../../../services";

type ThreeWeekLookAheadParametersDialogResult =
  | {
      date: Date;
      dailyWorkHours: number;
      mondayIsWorkDay: boolean;
      tuesdayIsWorkDay: boolean;
      wednesdayIsWorkDay: boolean;
      thursdayIsWorkDay: boolean;
      fridayIsWorkDay: boolean;
      saturdayIsWorkDay: boolean;
      sundayIsWorkDay: boolean;
    }
  | undefined;

const ThreeWeekLookAheadParametersDialog = FDVue.extend({
  name: "sp-three-week-look-ahead-parameters-dialog",

  mixins: [dialogSupport],

  components: {},

  data() {
    return {
      date: new Date(),
      dailyWorkHours: 0,
      mondayIsWorkDay: false,
      tuesdayIsWorkDay: false,
      wednesdayIsWorkDay: false,
      thursdayIsWorkDay: false,
      fridayIsWorkDay: false,
      saturdayIsWorkDay: false,
      sundayIsWorkDay: false
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["open"];
    },
    dayHourValues(): BasicSelectItem[] {
      let hours = Array.from(Array(24).keys()).map(
        x =>
          ({
            text: (x + 1).toFixed(0),
            value: x + 1
          } as BasicSelectItem)
      );
      return hours;
    },
    calculatedSundayDate(): Date {
      // console.log(`calculatedSundayDate`);
      // console.log(`\t date: ${this.date} (${isoDateWithOffsetString(this.date)})`);
      let sundayDate = new Date(isoDateWithOffsetString(this.date));
      // console.log(`\t sundayDate BEFORE: ${sundayDate}`);
      // console.log(`\t\t getDate(): ${sundayDate.getDate()}`);
      // console.log(`\t\t getDay(): ${sundayDate.getDay()}`);
      sundayDate.setDate(sundayDate.getDate() - sundayDate.getDay());
      // console.log(`\t sundayDate AFTER: ${sundayDate}`);
      return sundayDate;
    }
  },

  watch: {},

  mounted: async function() {},

  methods: {
    async open(): Promise<ThreeWeekLookAheadParametersDialogResult> {
      let environment = this.$store.state.curEnvironment as Environment;
      if (!!environment) {
        this.dailyWorkHours = this.$parse.sanitizedNumber(environment.dailyWorkHours);
        this.mondayIsWorkDay = environment.mondayIsWorkDay ?? false;
        this.tuesdayIsWorkDay = environment.tuesdayIsWorkDay ?? false;
        this.wednesdayIsWorkDay = environment.wednesdayIsWorkDay ?? false;
        this.thursdayIsWorkDay = environment.thursdayIsWorkDay ?? false;
        this.fridayIsWorkDay = environment.fridayIsWorkDay ?? false;
        this.saturdayIsWorkDay = environment.saturdayIsWorkDay ?? false;
        this.sundayIsWorkDay = environment.sundayIsWorkDay ?? false;
      }
      if (await this.showDialog()) {
        return {
          date: this.calculatedSundayDate,
          dailyWorkHours: this.dailyWorkHours,
          mondayIsWorkDay: this.mondayIsWorkDay,
          tuesdayIsWorkDay: this.tuesdayIsWorkDay,
          wednesdayIsWorkDay: this.wednesdayIsWorkDay,
          thursdayIsWorkDay: this.thursdayIsWorkDay,
          fridayIsWorkDay: this.fridayIsWorkDay,
          saturdayIsWorkDay: this.saturdayIsWorkDay,
          sundayIsWorkDay: this.sundayIsWorkDay
        };
      } else {
        return undefined;
      }
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async acceptDialog() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        this.closeDialog(true);
      } catch (error) {
      } finally {
        this.processing = false;
      }
    }
  }
});

export default ThreeWeekLookAheadParametersDialog;

export async function showThreeWeekLookAheadParametersDialog(): Promise<
  ThreeWeekLookAheadParametersDialogResult
> {
  let dialog = createDialog(ThreeWeekLookAheadParametersDialog);
  dialog.optOutOfErrorHandling();
  let result = await dialog.open();
  return result;
}

