import FDVue from "../index";
import * as DateUtil from "../../client-util/datetime";

export default FDVue.extend({
  name: "fd-archived-data-loader",

  components: {
    "fd-show-archived-switch": () => import("@fd/lib/vue/components/ShowArchivedSwitch.vue")
  },

  props: {
    showArchived: {
      default: false,
      type: Boolean
    },
    allowAllData: {
      default: false,
      type: Boolean
    },
    hideDateRangePicker: {
      default: false,
      type: Boolean
    },
    disableArchivedSwitch: {
      default: false,
      type: Boolean
    },
    hideArchivedSwitch: {
      default: false,
      type: Boolean
    },
    showArchivedDateRange: {
      default: () => [],
      type: Array
    },
    showArchivedMinDate: {
      default: undefined,
      type: Date
    },
    showArchivedMaxDate: {
      default: undefined,
      type: Date
    },
    maxArchivedDateRangeMonths: {
      default: 2,
      type: Number
    }
  },

  data: () => ({
    minDate: null as String | null,
    maxDate: null as String | null
  }),

  computed: {},

  watch: {
    showArchived() {
      this.minDate = this.showArchivedMinDate;
      this.maxDate = this.showArchivedMaxDate;
    },
    showArchivedMinDate(newValue: string | null) {
      this.minDate = newValue;
    },
    showArchivedMaxDate(newValue: string | null) {
      this.maxDate = newValue;
    }
  },

  methods: {
    showArchivedChanged(val: boolean) {
      this.$emit("input:showArchived", val);
      this.$emit("update:showArchived", val);
    },
    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.isoDateString(
        DateUtil.addMonthsToDate(val, this.maxArchivedDateRangeMonths)
      );
      this.$emit("input:showArchivedFromDate", val);
      this.$emit("update:showArchivedFromDate", val);
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.isoDateString(
        DateUtil.addMonthsToDate(val, -this.maxArchivedDateRangeMonths)
      );
      this.$emit("input:showArchivedToDate", val);
      this.$emit("update:showArchivedToDate", val);
    },
    dateRangeChanged(val: any) {
      this.$emit("input:showArchivedDateRange", val);
      this.$emit("update:showArchivedDateRange", val);
    }
  },

  created: function() {
    this.minDate = this.showArchivedMinDate;
    this.maxDate = this.showArchivedMaxDate;
  },

  mounted: function() {
    this.minDate = this.showArchivedMinDate;
    this.maxDate = this.showArchivedMaxDate;
  }
});

