import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import { filterByContractors } from "../services/taggableItems";
import { createNewPerson } from "./components/dialogs/PersonNewDialog.vue";
import { createNewCrew } from "./components/dialogs/CrewDetailsDialog.vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import {
  Classification,
  ContractorClassificationWithName,
  ContractorNorm,
  ContractorNormGroup,
  contractorNormGroupService,
  ContractorPurchaseOrder,
  contractorPurchaseOrderService,
  ContractorWithTags,
  ContractorWorkSubTypeWithDetails,
  CrewWithEmployees,
  Discipline,
  PersonWithDetails,
  ProjectCostCode,
  ProjectLocation,
  projectLocationService,
  WorkSubType,
  WorkType
} from "../services";
import rules from "@fd/lib/vue/rules";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import archivedDataList from "../dataMixins/archivedDataList";
import { GetPersonName, HasName } from "../utils/person";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";
import { VDataTable } from "@fd/lib/vue/types";
import userAccess from "../dataMixins/userAccess";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import { createNewContractorNormGroup } from "./components/dialogs/SP.ContractorNormGroupNewDialog.vue";

type ContractorWithDetails = ContractorWithTags & { archived: boolean };
type DisciplineWithSelected = Discipline & { selected: boolean };
type AreaWithSelected = ProjectLocation & { selected: boolean };
type CostCodeWithSelected = ProjectCostCode & { selected: boolean };
type WorkTypeWithSelected = WorkType & { subTypeNames: string[]; selected: boolean };
type WorkSubTypeWithSelected = WorkSubType & { selected: boolean };
type PersonWithDetailsAndArchived = PersonWithDetails & HasName & { archived: boolean };
type FormattedCrewWithEmployees = CrewWithEmployees & {
  employees: number;
  employeeListString: string; // This property is included to allow employee names to be searchable to find crews
  archived: boolean;
};
type FormattedContractorNormGroup = ContractorNormGroup & {
  archived: boolean;
};
type FormattedContractorPurchaseOrder = ContractorPurchaseOrder & {
  archived: boolean;
};
type ClassificationWithSelectedAndCustomRates = Classification & {
  selected: boolean;
  contractorRegularRate: number;
  contractorOvertimeRate: number;
  contractorDoubleTimeRate: number;
};

const disciplineSelection = FDVue.extend({
  data: function() {
    return {
      contractor: {} as ContractorWithTags,
      // *** DISCIPLINES ***
      showOnlyIncludedDisciplines: false,
      disciplinesTableSearch: "",
      selectableDisciplines: [] as Array<DisciplineWithSelected>
    };
  },

  computed: {
    // *** DISCIPLINES ***
    disciplinesUsageType: {
      get(): string {
        return this.contractor.includesAllDisciplines ? "entire" : "selection";
      },
      set(val: string) {
        this.contractor.includesAllDisciplines = val == "entire";
      }
    },

    disciplines(): Array<DisciplineWithSelected> {
      let returnValue = this.selectableDisciplines;
      if (this.showOnlyIncludedDisciplines) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedDisciplineIDs(): string[] {
      return this.selectableDisciplines.filter(x => x.selected).map(x => x.id!);
    },

    searchedDisciplines(): Array<DisciplineWithSelected> {
      let disciplines = this.disciplines;
      // This is a hack because the disciplines list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      if (this.disciplinesTableSearch) {
        let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
          .disciplinesDataTable as any)?.customFilter;
        if (customFilter) {
          disciplines = disciplines.filter(
            x =>
              customFilter(x.name!, this.disciplinesTableSearch, x) ||
              customFilter(x.description!, this.disciplinesTableSearch, x)
          );
        }
      }

      return disciplines;
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedDisciplinesSelected(): boolean {
      var searchedDisciplines = this.searchedDisciplines;
      return searchedDisciplines.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedDisciplinesSelected(): boolean {
      var searchedDisciplines = this.searchedDisciplines;
      return (
        searchedDisciplines.findIndex(x => x.selected) !== -1 &&
        searchedDisciplines.findIndex(x => !x.selected) !== -1
      );
    }
  },

  methods: {
    // *** GLOBAL ***

    ...mapActions({
      loadDisciplines: "LOAD_DISCIPLINES"
    }),

    // *** DISCIPLINES ***
    flipDisciplineSelected(item: DisciplineWithSelected & { selected: boolean }) {
      item.selected = !item.selected;
    },

    flipSearchedDisciplinesSelected() {
      let selected = !this.allSearchedDisciplinesSelected;
      let searchedDisciplines = this.searchedDisciplines;
      for (let discipline of searchedDisciplines) {
        if (discipline.selected !== selected) {
          discipline.selected = selected;
        }
      }
    }
  }
});
const areaSelection = FDVue.extend({
  data: function() {
    return {
      contractor: {} as ContractorWithTags,
      // *** AREAS ***
      showOnlyIncludedAreas: false,
      areasTableSearch: "",
      selectableAreas: [] as Array<AreaWithSelected>
    };
  },

  computed: {
    // *** AREAS ***
    areasUsageType: {
      get(): string {
        return this.contractor.includesAllAreas ? "entire" : "selection";
      },
      set(val: string) {
        this.contractor.includesAllAreas = val == "entire";
      }
    },

    areas(): Array<AreaWithSelected> {
      let returnValue = this.selectableAreas;
      if (this.showOnlyIncludedAreas) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedAreaIDs(): string[] {
      return this.selectableAreas.filter(x => x.selected).map(x => x.id!);
    },

    searchedAreas(): Array<AreaWithSelected> {
      let areas = this.areas;
      // This is a hack because the areas list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      if (this.areasTableSearch) {
        let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
          .areasDataTable as any)?.customFilter;
        if (customFilter) {
          areas = areas.filter(
            x =>
              customFilter(x.name!, this.areasTableSearch, x) ||
              customFilter(x.description!, this.areasTableSearch, x)
          );
        }
      }
      return areas;
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedAreasSelected(): boolean {
      var searchedAreas = this.searchedAreas;
      return searchedAreas.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedAreasSelected(): boolean {
      var searchedAreas = this.searchedAreas;
      return (
        searchedAreas.findIndex(x => x.selected) !== -1 &&
        searchedAreas.findIndex(x => !x.selected) !== -1
      );
    }
  },

  methods: {
    // *** GLOBAL ***
    ...mapActions({}),

    // *** AREAS ***
    flipAreaSelected(item: AreaWithSelected & { selected: boolean }) {
      item.selected = !item.selected;
    },

    flipSearchedAreasSelected() {
      let selected = !this.allSearchedAreasSelected;
      var searchedAreas = this.searchedAreas;
      for (let area of searchedAreas) {
        if (area.selected !== selected) {
          area.selected = selected;
        }
      }
    }
  }
});
const costCodeSelection = FDVue.extend({
  data: function() {
    return {
      contractor: {} as ContractorWithTags,
      // *** COSTCODES ***
      showOnlyIncludedCostCodes: false,
      costCodesTableSearch: "",
      selectableCostCodes: [] as Array<CostCodeWithSelected>
    };
  },

  computed: {
    // *** COSTCODES ***
    costCodesUsageType: {
      get(): string {
        return this.contractor.includesAllCostCodes ? "entire" : "selection";
      },
      set(val: string) {
        this.contractor.includesAllCostCodes = val == "entire";
      }
    },

    costCodes(): Array<CostCodeWithSelected> {
      let returnValue = this.selectableCostCodes;
      if (this.showOnlyIncludedCostCodes) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedCostCodeIDs(): string[] {
      return this.selectableCostCodes.filter(x => x.selected).map(x => x.id!);
    },

    searchedCostCodes(): Array<CostCodeWithSelected> {
      let costCodes = this.costCodes;
      // This is a hack because the costCodes list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      if (this.costCodesTableSearch) {
        let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
          .costCodesDataTable as any)?.customFilter;
        if (customFilter) {
          costCodes = costCodes.filter(x => customFilter(x.name!, this.costCodesTableSearch, x));
        }
      }
      return costCodes;
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedCostCodesSelected(): boolean {
      var searchedCostCodes = this.searchedCostCodes;
      return searchedCostCodes.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedCostCodesSelected(): boolean {
      var searchedCostCodes = this.searchedCostCodes;
      return (
        searchedCostCodes.findIndex(x => x.selected) !== -1 &&
        searchedCostCodes.findIndex(x => !x.selected) !== -1
      );
    }
  },

  methods: {
    // *** GLOBAL ***
    ...mapActions({
      loadCostCodes: "LOAD_PROJECT_COST_CODES"
    }),

    // *** COSTCODES ***
    flipCostCodeSelected(item: CostCodeWithSelected & { selected: boolean }) {
      item.selected = !item.selected;
    },

    flipSearchedCostCodesSelected() {
      let selected = !this.allSearchedCostCodesSelected;
      var searchedCostCodes = this.searchedCostCodes;
      for (let costCode of searchedCostCodes) {
        if (costCode.selected !== selected) {
          costCode.selected = selected;
        }
      }
    }
  }
});
const workTypeSelection = FDVue.extend({
  data: function() {
    return {
      contractor: {} as ContractorWithTags,
      // *** WORKTYPES ***
      showOnlyIncludedWorkTypes: false,
      workTypesTableSearch: "",

      selectableWorkTypes: [] as Array<WorkTypeWithSelected>,
      selectableWorkSubTypes: [] as Array<WorkSubTypeWithSelected>
    };
  },

  computed: {
    // *** WORKTYPES ***
    workTypesUsageType: {
      get(): string {
        return this.contractor.includesAllWorkTypes ? "entire" : "selection";
      },
      set(val: string) {
        this.contractor.includesAllWorkTypes = val == "entire";
      }
    },

    workTypes(): Array<WorkTypeWithSelected> {
      let returnValue = this.selectableWorkTypes;
      if (this.showOnlyIncludedWorkTypes) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedWorkTypeIDs(): string[] {
      return this.selectableWorkTypes.filter(x => x.selected).map(x => x.id!);
    },

    searchedWorkTypes(): Array<WorkTypeWithSelected> {
      let workTypes = this.workTypes;
      // This is a hack because the workTypes list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      if (this.workTypesTableSearch) {
        let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
          .workTypesDataTable as any)?.customFilter;
        if (customFilter) {
          workTypes = workTypes.filter(x => customFilter(x.name!, this.workTypesTableSearch, x));
        }
      }
      return workTypes;
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedWorkTypesSelected(): boolean {
      var searchedWorkTypes = this.searchedWorkTypes;
      return searchedWorkTypes.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedWorkTypesSelected(): boolean {
      var searchedWorkTypes = this.searchedWorkTypes;
      return (
        searchedWorkTypes.findIndex(x => x.selected) !== -1 &&
        searchedWorkTypes.findIndex(x => !x.selected) !== -1
      );
    },

    /// *** WORK SUB TYPES ***
    workSubTypes(): Array<WorkSubTypeWithSelected> {
      let returnValue = this.selectableWorkSubTypes;
      if (this.showOnlyIncludedWorkTypes) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedWorkSubTypes(): WorkSubTypeWithSelected[] {
      return this.selectableWorkSubTypes.filter(x => x.selected);
    },

    selectedWorkSubTypeIDs(): string[] {
      return this.selectedWorkSubTypes.map(x => x.id!);
    },

    searchedWorkSubTypes(): Array<WorkSubTypeWithSelected> {
      let workSubTypes = this.workSubTypes;
      // This is a hack because the workSubTypes list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      if (this.workTypesTableSearch) {
        let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
          .workSubTypesDataTable as any)?.customFilter;
        if (customFilter) {
          workSubTypes = workSubTypes.filter(x =>
            customFilter(x.name!, this.workTypesTableSearch, x)
          );
        }
      }

      return workSubTypes;
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedWorkSubTypesSelected(): boolean {
      var searchedWorkSubTypes = this.searchedWorkSubTypes;
      return searchedWorkSubTypes.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedWorkSubTypesSelected(): boolean {
      var searchedWorkSubTypes = this.searchedWorkSubTypes;
      return (
        searchedWorkSubTypes.findIndex(x => x.selected) !== -1 &&
        searchedWorkSubTypes.findIndex(x => !x.selected) !== -1
      );
    }
  },

  methods: {
    // *** GLOBAL ***
    ...mapActions({
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES"
    }),

    // *** WORKTYPES ***
    flipWorkTypeSelected(item: WorkTypeWithSelected) {
      item.selected = !item.selected;
      if (item.selected) {
        this.selectWorkSubTypesForWorkType(item);
      } else {
        this.deselectWorkSubTypesForWorkType(item);
      }
    },

    flipSearchedWorkTypesSelected() {
      let selected = !this.allSearchedWorkTypesSelected;
      let searchedWorkTypes = this.searchedWorkTypes;
      for (let workType of searchedWorkTypes) {
        if (workType.selected !== selected) {
          workType.selected = selected;
          if (selected) {
            this.selectWorkSubTypesForWorkType(workType);
          } else {
            this.deselectWorkSubTypesForWorkType(workType);
          }
        }
      }
    },

    // *** WORK SUB TYPES ***
    workSubTypesForWorkType(item: WorkTypeWithSelected): Array<WorkSubTypeWithSelected> {
      if (!item || !this.workSubTypes?.length) return [];
      return this.workSubTypes.filter(x => x.workTypeID == item.id);
    },
    selectWorkSubTypesForWorkType(item: WorkTypeWithSelected) {
      let workSubTypesForWorkType = this.workSubTypesForWorkType(item);
      for (let workSubType of workSubTypesForWorkType) {
        if (!workSubType.selected) workSubType.selected = true;
      }
    },
    deselectWorkSubTypesForWorkType(item: WorkTypeWithSelected) {
      let workSubTypesForWorkType = this.workSubTypesForWorkType(item);
      for (let workSubType of workSubTypesForWorkType) {
        if (workSubType.selected) workSubType.selected = false;
      }
    },
    flipWorkSubTypeSelected(item: WorkSubTypeWithSelected) {
      item.selected = !item.selected;
    },

    flipSearchedWorkSubTypesSelected() {
      let selected = !this.allSearchedWorkSubTypesSelected;
      let searchedWorkSubTypes = this.searchedWorkSubTypes;
      for (let workSubType of searchedWorkSubTypes) {
        if (workSubType.selected !== selected) {
          workSubType.selected = selected;
        }
      }
    }
  }
});
const classificationSelection = FDVue.extend({
  data: function() {
    return {
      contractor: {} as ContractorWithTags,
      // Used to change pages in the datatable programmatically
      classificationstablepage: 1,
      // *** Classifications ***
      showOnlyIncludedClassifications: false,
      classificationsTableSearch: "",
      selectableClassifications: [] as Array<ClassificationWithSelectedAndCustomRates>
    };
  },
  computed: {
    // *** CLASSIFICATIONS ***
    classifications(): Array<ClassificationWithSelectedAndCustomRates> {
      let returnValue = this.selectableClassifications;
      if (this.showOnlyIncludedClassifications) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedClassifications(): ClassificationWithSelectedAndCustomRates[] {
      return this.selectableClassifications.filter(x => x.selected);
    },
    selectedClassificationIDs(): string[] {
      return this.selectedClassifications.map(x => x.id!);
    },

    searchedClassifications(): Array<ClassificationWithSelectedAndCustomRates> {
      // This is a hack because the classifications list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .classificationsDataTable as any)?.customFilter;
      if (this.classificationsTableSearch && customFilter) {
        return this.classifications.filter(
          x =>
            customFilter(x.name!, this.classificationsTableSearch, x) ||
            customFilter(x.description!, this.classificationsTableSearch, x)
        );
      } else {
        return this.classifications;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedClassificationsSelected(): boolean {
      return this.searchedClassifications.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedClassificationsSelected(): boolean {
      var searchedClassifications = this.searchedClassifications;
      return (
        searchedClassifications.findIndex(x => x.selected) !== -1 &&
        searchedClassifications.findIndex(x => !x.selected) !== -1
      );
    }
  },
  methods: {
    // *** GLOBAL ***
    ...mapActions({
      loadClassifications: "LOAD_CLASSIFICATIONS"
    }),

    // *** INLINE NAVIGATION ***
    getFieldRef(fieldName: string, item: ClassificationWithSelectedAndCustomRates) {
      let id = item.id!.replace("-", "").replace("-", "");
      return `${fieldName}_${id}`;
    },
    focusFieldForVisibleClassificationsItemAtIndex(fieldName: string, index: number) {
      let visibleItems = (this.$refs.classificationsDataTable as VDataTable).internalCurrentItems;
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousClassificationsField(
      fieldName: string,
      item: ClassificationWithSelectedAndCustomRates
    ) {
      let datatable = this.$refs.classificationsDataTable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (this.classificationstablepage <= 1) return;
        this.classificationstablepage -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleClassificationsItemAtIndex(
            fieldName,
            datatable.computedItemsPerPage
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleClassificationsItemAtIndex(fieldName, previousIndex);
    },
    async selectNextClassificationsField(
      fieldName: string,
      item: ClassificationWithSelectedAndCustomRates
    ) {
      let datatable = this.$refs.classificationsDataTable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex >= visibleParts.length - 1) {
        let maxPage =
          datatable.computedItemsPerPage <= 0
            ? 1
            : Math.ceil(this.classifications.length / datatable.computedItemsPerPage);
        if (this.classificationstablepage >= maxPage) return;

        this.classificationstablepage += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleClassificationsItemAtIndex(fieldName, 0);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleClassificationsItemAtIndex(fieldName, nextIndex);
    },
    async classificationsEnterPressed(
      e: KeyboardEvent,
      fieldName: string,
      item: ClassificationWithSelectedAndCustomRates
    ) {
      if (e.shiftKey) await this.selectPreviousClassificationsField(fieldName, item);
      else await this.selectNextClassificationsField(fieldName, item);
    },

    // *** CLASSIFICATIONS ***
    flipClassificationSelected(
      item: ClassificationWithSelectedAndCustomRates & { selected: boolean }
    ) {
      item.selected = !item.selected;
    },

    flipSearchedClassificationsSelected() {
      let selected = !this.allSearchedClassificationsSelected;
      for (let classification of this.searchedClassifications) {
        if (classification.selected !== selected) {
          classification.selected = selected;
        }
      }
    }
  }
});

export default FDVue.extend({
  mixins: [
    errorHandling,
    rules,
    tabbedView,
    archivedDataList,
    disciplineSelection,
    areaSelection,
    costCodeSelection,
    workTypeSelection,
    classificationSelection,
    userAccess
  ],

  name: "sp-contractor-existing",

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-people-list": () => import("./components/PeopleListTable.vue"),
    "fd-norm-groups-form": () =>
      import("./components/forms/SP.ContractorExistingNormGroupsForm.vue")
  },

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,

      slidein: false,

      firstTabKey: `0`,
      detailsTab: {
        tabname: this.$t("common.details"),
        key: "0",
        visible: true
      } as Tab,
      timesheetsTab: {
        tabname: this.$t("contractors.tabs.timesheets"),
        key: "1",
        visible: true
      } as Tab,
      disciplinesTab: {
        tabname: this.$t("contractors.tabs.disciplines"),
        key: "2",
        visible: false
      } as Tab,
      areasTab: {
        tabname: "Areas",
        key: "3",
        visible: false
      } as Tab,
      costCodesTab: {
        tabname: "Cost Codes",
        key: "4",
        visible: false
      } as Tab,
      classificationsTab: {
        tabname: this.$t("contractors.tabs.classifications"),
        key: "5",
        visible: false
      } as Tab,
      workTypesTab: {
        tabname: "Work Types",
        key: "6",
        visible: false
      } as Tab,
      peopleTab: {
        tabname: "People",
        key: "7",
        visible: false
      } as Tab,
      crewsTab: {
        tabname: this.$t("contractors.tabs.crews"),
        key: "8",
        visible: false
      } as Tab,
      normsTab: new PageTab({
        nameKey: "contractors.tabs.norms",
        key: "9"
      }),
      purchaseOrdersTab: new PageTab({
        nameKey: "contractors.tabs.purchase-orders",
        key: "10"
      }),

      contractor: {} as ContractorWithDetails,

      selectedTags: [] as any[],

      norms: [] as ContractorNorm[],
      normGroups: [] as FormattedContractorNormGroup[],

      purchaseOrders: [] as FormattedContractorPurchaseOrder[]
    };
  },

  computed: {
    detailsReadonly(): boolean {
      return !this.currentUserCanConfigureSettings;
    },
    unwatchedMethodNames(): string[] {
      return ["getFieldRef", "workSubTypesForWorkType"];
    },
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      let tabs = [];

      if (this.currentUserCanConfigureSettings) tabs.push(this.timesheetsTab);
      if (this.currentUserCanConfigureSettings) tabs.push(this.disciplinesTab);
      if (this.currentUserCanConfigureSettings) tabs.push(this.areasTab);
      if (this.currentUserCanConfigureSettings) tabs.push(this.costCodesTab);
      if (this.currentUserCanConfigureSettings) tabs.push(this.classificationsTab);
      if (this.currentUserCanConfigureSettings) tabs.push(this.workTypesTab);

      tabs.push(this.peopleTab);
      tabs.push(this.crewsTab);

      if (!!this.$store.state.curEnvironment.enableNorms && this.currentUserCanConfigureSettings)
        tabs.push(this.normsTab);

      if (
        !!this.$store.state.curEnvironment.enablePurchaseOrders &&
        this.currentUserCanConfigureSettings
      )
        tabs.push(this.purchaseOrdersTab);

      return tabs;
    },
    availableTags(): any[] {
      return this.$store.getters.getSortedEnabledInUseTags(this.selectedTags);
    },

    // *** Timesheets ***

    timesheetsError(): boolean {
      return (
        !!this.contractor.tracksEmployeeTime &&
        !this.contractor.allowDirectTimesheets &&
        !this.contractor.allowIndirectTimesheets &&
        !this.contractor.allowEquipmentTimesheets
      );
    },

    // *** PEOPLE ***
    users(): PersonWithDetailsAndArchived[] {
      let users = this.$store.state.users.fullList;
      let contractorID = [this.$route.params.id];
      return filterByContractors(
        contractorID,
        users.map((x: any) => {
          return {
            ...x,
            name: GetPersonName(x),
            archived: !!x.archivedDate
          } as PersonWithDetailsAndArchived;
        })
      );
    },

    // *** CREWS ***
    crews(): FormattedCrewWithEmployees[] {
      let users = this.users;
      let allCrews = this.$store.state.crews.fullList as CrewWithEmployees[];
      let contractorID = [this.$route.params.id];
      let formattedCrews = allCrews.map((x: CrewWithEmployees) => {
        let employeeIDs = x.employees?.map(e => e.employeeID!);
        let employees = users.filter(u => employeeIDs?.includes(u.id!));
        let employeeNames = employees.map(u => u.name);
        return {
          ...x,
          description: truncateWithEllipsis(stripHtml(x.description)),
          employees: x.employees?.length ?? 0,
          employeeListString: employeeNames.join(" "),
          archived: !!x.archivedDate
        } as FormattedCrewWithEmployees;
      });
      return filterByContractors(contractorID, formattedCrews);
    },

    searchStringForFiltering: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    ...mapMutations({
      setContractor: "SET_CONTRACTOR",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractor: "LOAD_CONTRACTOR",
      updateContractor: "UPDATE_CONTRACTOR",
      deleteContractor: "DELETE_CONTRACTOR",
      loadTags: "LOAD_TAGS",
      loadStoreUsers: "LOAD_USERS",
      updateUser: "UPDATE_USER",
      deleteUser: "DELETE_USER",
      loadStoreCrews: "LOAD_CREWS",
      updateCrew: "UPDATE_CREW",
      deleteCrew: "DELETE_CREW",
      loadStoreNorms: "LOAD_CONTRACTOR_NORMS",
      loadStoreNormGroups: "LOAD_CONTRACTOR_NORM_GROUPS",
      addNormGroup: "ADD_CONTRACTOR_NORM_GROUP",
      updateNormGroup: "UPDATE_CONTRACTOR_NORM_GROUP",
      deleteNorm: "DELETE_CONTRACTOR_NORM",
      deleteNormGroup: "DELETE_CONTRACTOR_NORM_GROUP",
      loadStorePurchaseOrders: "LOAD_CONTRACTOR_PURCHASE_ORDERS",
      addPurchaseOrder: "ADD_CONTRACTOR_PURCHASE_ORDER",
      updatePurchaseOrder: "UPDATE_CONTRACTOR_PURCHASE_ORDER",
      deletePurchaseOrder: "DELETE_CONTRACTOR_PURCHASE_ORDER"
    }),

    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    validate() {
      let detailserror = !((this.$refs.form as HTMLFormElement)?.validate() ?? true);
      return !(detailserror || this.timesheetsError);
    },
    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!this.validate()) {
        this.inlineMessage.message = this.$t("contractors.form-errors-message");
        this.inlineMessage.type = "error";
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.contractor.archived) {
          this.contractor.archivedDate = null;
        } else if (this.contractor.archived && !this.contractor.archivedDate) {
          this.contractor.archivedDate = new Date(new Date().toUTCString());
        }

        let workSubTypes = this.selectedWorkSubTypes.map(
          x =>
            ({
              name: x.name,
              contractorID: this.contractor.id!,
              workSubTypeID: x.id
            } as ContractorWorkSubTypeWithDetails)
        );
        let classifications = this.selectedClassifications.map(
          x =>
            ({
              name: x.name,
              contractorID: this.contractor.id!,
              classificationID: x.id,
              regularRate: +(x.contractorRegularRate ?? 0),
              overtimeRate: +(x.contractorOvertimeRate ?? 0),
              doubleTimeRate: +(x.contractorDoubleTimeRate ?? 0)
            } as ContractorClassificationWithName)
        );

        await this.updateContractor({
          ...this.contractor,
          tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map((x: any) => x.id) : null,
          disciplineIDs: this.selectedDisciplineIDs ?? null,
          areaIDs: this.selectedAreaIDs ?? null,
          costCodeIDs: this.selectedCostCodeIDs ?? null,
          workTypeIDs: this.selectedWorkTypeIDs ?? null,
          workSubTypes: workSubTypes,
          classifications: classifications,
          perDiemHoursRequirement: +(this.contractor.perDiemHoursRequirement ?? 0),
          employeeOvertimeHoursThreshold: !!this.contractor.employeeOvertimeHoursThreshold
            ? +this.contractor.employeeOvertimeHoursThreshold
            : null
        } as ContractorWithTags);
        if (closeOnComplete) {
          this.$router.push("/contractors");
        }
      } catch (error) {
        this.handleError(error as Error, "contractors.save-network-error");
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteContractor({ id: this.$route.params.id, name: this.contractor.name });
        this.$router.push("/contractors");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    // Called when changing ShowArchived.
    async loadData() {
      this.processing = true;
      try {
        await Promise.all([
          this.loadUsers(),
          this.loadCrews(),
          this.loadNorms(),
          this.loadNormGroups(),
          this.loadPurchaseOrders()
        ]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/contractors");
    },

    // *** PEOPLE ***
    async openNewUserDialog() {
      this.optOutOfErrorHandling();
      await createNewPerson({
        contractorID: this.$route.params.id
      });
      await this.loadUsers();
    },

    // the following works with the delete "Action" button in the Datatable.
    async deletePeopleTableItem(item: PersonWithDetails) {
      await this.deleteUser(item);
    },

    async flipPersonArchived(item: PersonWithDetailsAndArchived) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateUser({
          id: item.id,
          archivedDate: archivedDate,
          firstName: item.firstName,
          lastName: item.lastName
        });
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async flipPersonCanLogIn(item: PersonWithDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for disabled, since we're flipping it
        var loginDisabledDate = item.isLoginActive ? new Date(new Date().toUTCString()) : null;
        await this.updateUser({
          id: item.id,
          loginDisabledDate: loginDisabledDate,
          firstName: item.firstName,
          lastName: item.lastName
        });
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async loadUsers() {
      await this.loadStoreUsers({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
    },

    // *** CREWS ***
    async openNewCrewDialog() {
      this.optOutOfErrorHandling();
      await createNewCrew(this.$route.params.id);
      await this.loadCrews();
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteCrewsTableItem(item: CrewWithEmployees) {
      await this.deleteCrew(item);
    },

    async flipCrewArchived(item: FormattedCrewWithEmployees) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateCrew({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
      } catch (error) {
        this.handleError(error as Error, "crews.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async loadCrews() {
      await this.loadStoreCrews({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
    },

    // *** NORM GROUPS ***
    async openNewNormGroupDialog() {
      this.optOutOfErrorHandling();
      if (await createNewContractorNormGroup(this.$route.params.id)) {
        this.processing = true;
        try {
          await this.loadNormGroups();
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = false;
        }
      }
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteNormsTableItem(item: ContractorNorm) {
      if (!this.$store.state.curEnvironment.enableNorms) return;

      this.processing = true;
      try {
        await this.deleteNorm(item);
        await this.loadNorms();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteNormGroupsTableItem(item: FormattedContractorNormGroup) {
      if (!this.$store.state.curEnvironment.enableNorms) return;

      this.processing = true;
      try {
        await this.deleteNormGroup(item);
        await Promise.all([this.loadNormGroups(), this.loadNorms()]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async flipNormGroupArchived(item: FormattedContractorNormGroup) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateNormGroup({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
        item.archived = !!archivedDate;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadNorms() {
      if (!this.$store.state.curEnvironment.enableNorms) return;

      // Don't load regular norms by archived here, only always show unarchived
      // Even if a group is archived, its norms aren't
      // Looking at archived norms can happen on the group details page.
      await this.loadStoreNorms();
      let contractorID = [this.$route.params.id];
      let allNorms = this.$store.state.contractorNorms.fullList as ContractorNorm[];
      let formattedNorms = allNorms.sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));
      this.norms = filterByContractors(contractorID, formattedNorms);
    },
    async loadNormGroups() {
      if (!this.$store.state.curEnvironment.enableNorms) return;

      await this.loadStoreNormGroups({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
      let contractorID = [this.$route.params.id];
      let allNormGroups = this.$store.state.contractorNormGroups.fullList as ContractorNormGroup[];
      let formattedNormGroups = allNormGroups
        .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0))
        .map(
          (x: ContractorNormGroup) =>
            ({
              ...x,
              archived: !!x.archivedDate
            } as FormattedContractorNormGroup)
        );
      //   .filter(
      //   x => x.contractorID == this.contractor.id
      // );
      this.normGroups = filterByContractors(contractorID, formattedNormGroups);
    },

    // *** SORTING ***
    async confirmNormGroupItemOrdering() {
      console.log(`confirmNormGroupItemOrdering total Norm Groups: ${this.normGroups.length}`);

      let itemsToUpdate = [] as ContractorNormGroup[];

      this.normGroups.forEach((normGroup, index) => {
        let newOrder = index + 1;
        if (!normGroup.displayOrder || normGroup.displayOrder != newOrder) {
          console.log(`    ${normGroup.displayOrder} --> ${newOrder}`);
          normGroup.displayOrder = newOrder;
          itemsToUpdate.push(normGroup);
        }
      });

      if (itemsToUpdate.length > 0) {
        console.log(` updating ${itemsToUpdate.length} norm groups' ordering`);
        await contractorNormGroupService.updateNormGroupOrders(itemsToUpdate);

        var snackbarPayload = {
          text: this.$t("contractors.norm-groups.snack-bar-order-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      }
    },
    async dragNormGroupEnded(e: SortableEvent) {
      console.log(`dragNormGroupEnded`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.normGroups.splice(newIndex, 0, ...this.normGroups.splice(oldIndex, 1));

      this.processing = true;
      try {
        await this.confirmNormGroupItemOrdering();
      } catch (error) {
        this.handleError(error as Error);
        await this.loadData();
      } finally {
        this.processing = false;
      }
    },

    // *** PURCHASE ORDERS ***
    async openNewPurchaseOrderDialog() {
      this.optOutOfErrorHandling();
      let text = await showTextPromptDialog({
        title: this.$t("contractors.purchase-orders.new.dialog-title"),
        label: this.$t("contractors.purchase-orders.new.text-label"),
        rules: [this.rules.required]
      });
      if (!!text?.length) {
        let lastOrder = 0;
        if (this.purchaseOrders.length)
          lastOrder = this.purchaseOrders.slice(-1)[0].displayOrder ?? 0;
        let newPurchaseOrder = {
          contractorID: this.$route.params.id,
          purchaseOrderNumber: text,
          displayOrder: lastOrder + 1,
          isActive: true,
          archived: false
        } as FormattedContractorPurchaseOrder;
        newPurchaseOrder.id = await this.addPurchaseOrder(newPurchaseOrder);
        this.purchaseOrders.push(newPurchaseOrder);
      }
    },
    async openEditPurchaseOrderDialog(item: FormattedContractorPurchaseOrder) {
      this.optOutOfErrorHandling();
      let text = await showTextPromptDialog({
        title: this.$t("contractors.purchase-orders.new.dialog-title"),
        label: this.$t("contractors.purchase-orders.new.text-label"),
        rules: [this.rules.required],
        text: item.description
      });
      if (!!text?.length) {
        await this.updatePurchaseOrder({
          ...item,
          purchaseOrderNumber: text
        } as ContractorPurchaseOrder);
        item.description = text;
      }
    },

    // the following works with the delete "Action" button in the Datatable.
    async deletePurchaseOrdersTableItem(item: FormattedContractorPurchaseOrder) {
      if (!this.$store.state.curEnvironment.enablePurchaseOrders) return;

      await this.deletePurchaseOrder(item);
      await this.loadPurchaseOrders();
    },

    async flipPurchaseOrderArchived(item: FormattedContractorPurchaseOrder) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updatePurchaseOrder({
          id: item.id,
          archivedDate: archivedDate,
          name: truncateWithEllipsis(item.description, 30)
        });
        item.archived = !!archivedDate;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadPurchaseOrders() {
      if (!this.$store.state.curEnvironment.enablePurchaseOrders) return;

      await this.loadStorePurchaseOrders({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
      let contractorID = [this.$route.params.id];
      let allPurchaseOrders = this.$store.state.contractorPurchaseOrders
        .fullList as ContractorPurchaseOrder[];
      let formattedPurchaseOrders = allPurchaseOrders
        .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0))
        .map(
          (x: ContractorPurchaseOrder) =>
            ({
              ...x,
              description: truncateWithEllipsis(stripHtml(x.description)),
              archived: !!x.archivedDate
            } as FormattedContractorPurchaseOrder)
        );
      //   .filter(
      //   x => x.contractorID == this.contractor.id
      // );
      this.purchaseOrders = filterByContractors(contractorID, formattedPurchaseOrders);
    },

    // *** SORTING ***
    async confirmPurchaseOrderItemOrdering() {
      console.log(
        `confirmPurchaseOrderItemOrdering total PurchaseOrders: ${this.purchaseOrders.length}`
      );

      let itemsToUpdate = [] as ContractorPurchaseOrder[];

      this.purchaseOrders.forEach((purchaseOrder, index) => {
        let newOrder = index + 1;
        if (!purchaseOrder.displayOrder || purchaseOrder.displayOrder != newOrder) {
          console.log(`    ${purchaseOrder.displayOrder} --> ${newOrder}`);
          purchaseOrder.displayOrder = newOrder;
          itemsToUpdate.push(purchaseOrder);
        }
      });

      if (itemsToUpdate.length > 0) {
        console.log(` updating ${itemsToUpdate.length} purchaseOrders' ordering`);
        await contractorPurchaseOrderService.updatePurchaseOrderOrders(itemsToUpdate);

        var snackbarPayload = {
          text: this.$t("contractors.purchase-orders.snack-bar-order-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      }
    },
    async dragPurchaseOrderEnded(e: SortableEvent) {
      console.log(`dragPurchaseOrderEnded`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.purchaseOrders.splice(newIndex, 0, ...this.purchaseOrders.splice(oldIndex, 1));

      this.processing = true;
      try {
        await this.confirmPurchaseOrderItemOrdering();
      } catch (error) {
        this.handleError(error as Error);
        await this.loadData();
      } finally {
        this.processing = false;
      }
    }
  },

  watch: {
    contractor(newValue) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/contractors") {
        this.notifyNewBreadcrumb({
          text: this.$t("contractors.list-title"),
          to: "/contractors",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/contractors/${this.$route.params.id}`
      });
    },
    "contractor.employeesReceivePerDiem"(newValue) {
      if (!newValue) this.contractor.automaticallyApplyWeekendPerDiem = false;
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "contractors-existing",
      parentalContext: "contractors",
      searchStringForFiltering: "",
      tagsForFiltering: [],
      selectedTab: this.firstTabKey,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date()
    });
    // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
    // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/contractors") {
      this.notifyNewBreadcrumb({
        text: this.$t("contractors.list-title"),
        to: "/contractors",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }
    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true
    });

    this.processing = true;
    try {
      await Promise.all([
        this.loadContractor(this.$route.params.id),
        this.loadTags(),
        this.loadDisciplines(),
        this.loadCostCodes(),
        this.loadWorkTypes(),
        this.loadWorkSubTypes(),
        this.loadClassifications(),
        this.loadUsers(),
        this.loadCrews(),
        this.loadNorms(),
        this.loadNormGroups(),
        this.loadPurchaseOrders()
      ]);

      this.processing = true;
      let allAreas = await projectLocationService.getAllAreas(false, null, null);

      let contractor = this.$store.state.contractors.fullList.find(
        (x: any) => x.id == this.$route.params.id
      );
      this.contractor = {
        ...contractor,
        archived: !!contractor.archivedDate
      };
      if (contractor.tagIDs) {
        let tags = this.$store.state.tags.fullList;
        this.selectedTags = contractor.tagIDs
          .map((x: any) => tags.find((y: any) => y.id == x))
          .filter((x: any) => x);
      } else {
        this.selectedTags = [];
      }

      let contractorDisciplineIDs = this.contractor.disciplineIDs;
      this.selectableDisciplines = (this.$store.state.disciplines.fullList as Discipline[]).map(
        x =>
          ({
            ...x,
            selected: contractorDisciplineIDs?.length
              ? contractorDisciplineIDs.indexOf(x.id!) > -1
              : false,
            description: truncateWithEllipsis(stripHtml(x.description))
          } as DisciplineWithSelected)
      );
      let contractorAreaIDs = this.contractor.areaIDs;
      this.selectableAreas = allAreas.map(
        x =>
          ({
            ...x,
            selected: contractorAreaIDs?.length ? contractorAreaIDs.indexOf(x.id!) > -1 : false,
            description: truncateWithEllipsis(stripHtml(x.description))
          } as AreaWithSelected)
      );
      let contractorCostCodeIDs = this.contractor.costCodeIDs;
      this.selectableCostCodes = (this.$store.state.projectCostCodes
        .fullList as ProjectCostCode[]).map(
        x =>
          ({
            ...x,
            selected: contractorCostCodeIDs?.length
              ? contractorCostCodeIDs.indexOf(x.id!) > -1
              : false
          } as CostCodeWithSelected)
      );
      let contractorWorkTypeIDs = this.contractor.workTypeIDs;
      this.selectableWorkTypes = (this.$store.state.workTypes.fullList as WorkType[]).map(
        x =>
          ({
            ...x,
            selected: contractorWorkTypeIDs?.length
              ? contractorWorkTypeIDs.indexOf(x.id!) > -1
              : false
          } as WorkTypeWithSelected)
      );
      let contractorWorkSubTypes = this.contractor.workSubTypes;
      this.selectableWorkSubTypes = (this.$store.state.workSubTypes.fullList as WorkSubType[]).map(
        x => {
          let relatedContractorWorkSubType = contractorWorkSubTypes?.find(
            wst => wst.workSubTypeID == x.id
          );
          return {
            ...x,
            selected: !!relatedContractorWorkSubType
          } as WorkSubTypeWithSelected;
        }
      );
      let contractorClassifications = this.contractor.classifications;
      this.selectableClassifications = (this.$store.state.classifications
        .fullList as Classification[]).map(x => {
        let relatedContractorClassification = contractorClassifications?.find(
          cc => cc.classificationID == x.id
        );
        return {
          ...x,
          selected: !!relatedContractorClassification,
          contractorRegularRate: relatedContractorClassification?.regularRate ?? x.regularRate,
          contractorOvertimeRate: relatedContractorClassification?.overtimeRate ?? x.overtimeRate,
          contractorDoubleTimeRate:
            relatedContractorClassification?.doubleTimeRate ?? x.doubleTimeRate,
          description: truncateWithEllipsis(stripHtml(x.description))
        } as ClassificationWithSelectedAndCustomRates;
      });
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

