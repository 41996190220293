var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { flat: "", tile: "" } },
    [
      _c(
        "v-col",
        { staticClass: "pa-0", attrs: { cols: "12" } },
        [
          _c(
            "v-card-title",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c("fd-inline-add-button", {
                on: {
                  click: function($event) {
                    return _vm.openNewNormGroupDialog()
                  }
                }
              }),
              _vm._v(" "),
              _c("v-divider", {
                staticClass: "mx-4",
                attrs: { inset: "", vertical: "" }
              }),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function({ on }) {
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              {
                                staticClass: "ml-1",
                                attrs: { color: "black", dark: "" }
                              },
                              on
                            ),
                            [_vm._v("info")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "contractors.norm-groups.listing-header-additional-info"
                        )
                      )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "pl-0",
                attrs: {
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": "",
                  clearable: ""
                },
                model: {
                  value: _vm.searchStringForFiltering,
                  callback: function($$v) {
                    _vm.searchStringForFiltering = $$v
                  },
                  expression: "searchStringForFiltering"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              { name: "fd-table-sortable", rawName: "v-fd-table-sortable" },
              {
                name: "fd-column",
                rawName: "v-fd-column:displayOrder.hidden",
                value: "",
                expression: "''",
                arg: "displayOrder",
                modifiers: { hidden: true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:name.no-sort",
                value: _vm.$t("contractors.norm-groups.name-label"),
                expression: "$t('contractors.norm-groups.name-label')",
                arg: "name",
                modifiers: { "no-sort": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:description.no-sort",
                value: _vm.$t("contractors.norm-groups.description-label"),
                expression: "$t('contractors.norm-groups.description-label')",
                arg: "description",
                modifiers: { "no-sort": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:isScaffoldGroup.no-sort.hide-when-extra-small.hide-when-header-text-empty",
                value:
                  !_vm.showCraftOptions || !_vm.showScaffoldColumn
                    ? ""
                    : _vm.$t("contractors.norm-groups.is-scaffold-group"),
                expression:
                  "\n        !showCraftOptions || !showScaffoldColumn\n          ? ''\n          : $t('contractors.norm-groups.is-scaffold-group')\n      ",
                arg: "isScaffoldGroup",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:isMaintenanceGroup.no-sort.hide-when-extra-small.hide-when-header-text-empty",
                value:
                  !_vm.showCraftOptions || !_vm.showMaintenanceColumn
                    ? ""
                    : _vm.$t("contractors.norm-groups.is-maintenance-group"),
                expression:
                  "\n        !showCraftOptions || !showMaintenanceColumn\n          ? ''\n          : $t('contractors.norm-groups.is-maintenance-group')\n      ",
                arg: "isMaintenanceGroup",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:isPaintGroup.no-sort.hide-when-extra-small.hide-when-header-text-empty",
                value:
                  !_vm.showCraftOptions || !_vm.showPaintColumn
                    ? ""
                    : _vm.$t("contractors.norm-groups.is-paint-group"),
                expression:
                  "\n        !showCraftOptions || !showPaintColumn ? '' : $t('contractors.norm-groups.is-paint-group')\n      ",
                arg: "isPaintGroup",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:isInsulationGroup.no-sort.hide-when-extra-small.hide-when-header-text-empty",
                value:
                  !_vm.showCraftOptions || !_vm.showInsulationColumn
                    ? ""
                    : _vm.$t("contractors.norm-groups.is-insulation-group"),
                expression:
                  "\n        !showCraftOptions || !showInsulationColumn\n          ? ''\n          : $t('contractors.norm-groups.is-insulation-group')\n      ",
                arg: "isInsulationGroup",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:archived.hide-when-extra-small.hide-when-header-text-empty",
                value: _vm.showArchived ? _vm.$t("common.archived") : "",
                expression:
                  "\n        showArchived ? $t('common.archived') : ''\n      ",
                arg: "archived",
                modifiers: {
                  "hide-when-extra-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                arg: "actions",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-actions-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: _vm.navigateToGroup,
                expression: "navigateToGroup",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            ref: "normgroupsdatatable",
            staticClass: "fd-actions-table",
            attrs: {
              "data-cy": "normgroupslist",
              disabled: _vm.processing || _vm.showArchived,
              items: _vm.normGroups,
              search: _vm.searchStringForFiltering,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["displayOrder"],
              "mobile-breakpoint": "0",
              "items-per-page": 100,
              "footer-props": {
                "items-per-page-options": [5, 25, 50, 100, -1]
              },
              "show-expand": ""
            },
            on: { "sort:end": _vm.dragNormGroupEnded },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function({ item: group }) {
                  return [
                    _c(
                      "td",
                      {
                        staticClass: "fd-embedded-table-container",
                        attrs: { colspan: "99" }
                      },
                      [
                        _c("v-subheader", { staticClass: "white--text" }, [
                          _vm._v(_vm._s(_vm.$t("contractors.norms.header")))
                        ]),
                        _vm._v(" "),
                        _c("v-data-table", {
                          directives: [
                            {
                              name: "fd-column",
                              rawName: "v-fd-column:displayOrder.hidden",
                              value: "",
                              expression: "''",
                              arg: "displayOrder",
                              modifiers: { hidden: true }
                            },
                            {
                              name: "fd-column",
                              rawName: "v-fd-column:description.no-sort",
                              value: _vm.$t(
                                "contractors.norms.description-label"
                              ),
                              expression:
                                "$t('contractors.norms.description-label')",
                              arg: "description",
                              modifiers: { "no-sort": true }
                            },
                            {
                              name: "fd-column",
                              rawName: "v-fd-column:isActive.no-sort",
                              value: _vm.$t("common.active"),
                              expression: "$t('common.active')",
                              arg: "isActive",
                              modifiers: { "no-sort": true }
                            },
                            {
                              name: "fd-column",
                              rawName:
                                "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                              arg: "actions",
                              modifiers: {
                                "no-sort": true,
                                "hide-when-extra-small": true,
                                "class_fd-actions-cell": true
                              }
                            },
                            {
                              name: "fd-row-navigate",
                              rawName:
                                "v-fd-row-navigate.show-when-extra-small",
                              value: _vm.navigateToNorm,
                              expression: "navigateToNorm",
                              modifiers: { "show-when-extra-small": true }
                            }
                          ],
                          staticClass: "fd-actions-table",
                          attrs: {
                            "data-cy": "normslist",
                            disabled: _vm.processing || _vm.showArchived,
                            items: _vm.normsForGroup(group),
                            search: _vm.searchStringForFiltering,
                            loading: _vm.processing,
                            "loading-text": _vm.$t(
                              "common.table-loading-message"
                            ),
                            "sort-by": ["displayOrder"],
                            "mobile-breakpoint": "0",
                            "items-per-page": 100,
                            "footer-props": {
                              "items-per-page-options": [5, 25, 50, 100, -1]
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item.isActive",
                                fn: function({ item: norm }) {
                                  return [
                                    _c("fd-checkbox", {
                                      attrs: {
                                        value: norm.isActive,
                                        readonly: true
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.actions",
                                fn: function({ item: norm }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-actions-column-min-width"
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { left: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              link: "",
                                                              disabled:
                                                                _vm.processing,
                                                              to: _vm.normNavigationPath(
                                                                norm
                                                              )
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-pencil\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.edit"))
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { right: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              disabled:
                                                                _vm.processing
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteNormsTableItem(
                                                                    norm
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-delete\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.remove"))
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.fd-drag",
                fn: function() {
                  return [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("far fa-grip-lines")
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "footer.prepend",
                fn: function() {
                  return [
                    _c("fd-archived-data-loader", {
                      staticClass: "ml-2 mr-5",
                      attrs: {
                        showArchived: _vm.managedShowArchived,
                        showArchivedDateRange: _vm.managedShowArchivedDateRange,
                        showArchivedMinDate: _vm.showArchivedMinDate,
                        showArchivedMaxDate: _vm.showArchivedMaxDate,
                        loading: _vm.archivedLoading,
                        disabled: _vm.processing,
                        "hide-date-range-picker": ""
                      },
                      on: {
                        "update:showArchived": function($event) {
                          _vm.managedShowArchived = $event
                        },
                        "update:show-archived": function($event) {
                          _vm.managedShowArchived = $event
                        },
                        "update:showArchivedDateRange": function($event) {
                          _vm.managedShowArchivedDateRange = $event
                        },
                        "update:show-archived-date-range": function($event) {
                          _vm.managedShowArchivedDateRange = $event
                        }
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "item.fd-nav",
                fn: function() {
                  return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                },
                proxy: true
              },
              {
                key: "item.isScaffoldGroup",
                fn: function({ item }) {
                  return [
                    _c("fd-checkbox", {
                      attrs: {
                        value: item.isScaffoldGroup,
                        disabled: _vm.processing,
                        readonly: true
                      }
                    })
                  ]
                }
              },
              {
                key: "item.isMaintenanceGroup",
                fn: function({ item }) {
                  return [
                    _c("fd-checkbox", {
                      attrs: {
                        value: item.isMaintenanceGroup,
                        disabled: _vm.processing,
                        readonly: true
                      }
                    })
                  ]
                }
              },
              {
                key: "item.isPaintGroup",
                fn: function({ item }) {
                  return [
                    _c("fd-checkbox", {
                      attrs: {
                        value: item.isPaintGroup,
                        disabled: _vm.processing,
                        readonly: true
                      }
                    })
                  ]
                }
              },
              {
                key: "item.isInsulationGroup",
                fn: function({ item }) {
                  return [
                    _c("fd-checkbox", {
                      attrs: {
                        value: item.isInsulationGroup,
                        disabled: _vm.processing,
                        readonly: true
                      }
                    })
                  ]
                }
              },
              {
                key: "item.archived",
                fn: function({ item }) {
                  return [
                    _c("v-simple-checkbox", {
                      attrs: { value: item.archived, disabled: _vm.processing },
                      on: {
                        input: function($event) {
                          return _vm.flipNormGroupArchived(item)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: _vm.normGroupNavigationPath(
                                                item
                                              )
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled: _vm.processing
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteNormGroupsTableItem(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  mdi-delete\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.remove")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }