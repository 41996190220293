import FDVue from "@fd/lib/vue";
import FPMap, { FPMapLocation, MapLayerType } from "@fd/lib/vue/components/FP.Map.vue";

export default FDVue.extend({
  name: "fd-my-location",

  components: {
    "fp-map": FPMap
  },

  props: {
    latitude: { type: Number, default: 0, required: true },
    longitude: { type: Number, default: 0, required: true }
  },

  computed: {
    location(): FPMapLocation | undefined {
      if (!this.latitude || !this.longitude) return undefined;
      return {
        lat: this.latitude,
        lng: this.longitude,
        colour: null
      };
    },
    mapLayerType: {
      get(): MapLayerType {
        return this.$store.state.mapLayerType;
      },
      set(val: MapLayerType) {
        this.$store.commit("SET_MAP_LAYER_TYPE", val);
      }
    }
  },

  watch: {},

  methods: {},

  mounted() {},

  beforeDestroy() {}
});

