var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", attrs: { "data-cy": "scaffoldsexisting" } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/scaffolds`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  !!_vm.scaffold.actualErectDate
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            {
                                              "ml-2":
                                                _vm.$vuetify.breakpoint.xsOnly
                                            },
                                            "py-1",
                                            "px-3",
                                            "fd-status-indicator"
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                !_vm.scaffold
                                                  .actualDismantleDate
                                                  ? _vm.$t(
                                                      "scaffolds.existing-scaffold.currently-standing-days-message",
                                                      [
                                                        _vm.scaffold
                                                          .daysStanding
                                                      ]
                                                    )
                                                  : _vm.$t(
                                                      "scaffolds.existing-scaffold.no-longer-standing-days-message",
                                                      [
                                                        _vm.scaffold
                                                          .daysStanding
                                                      ]
                                                    )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !!_vm.scaffold.externalReferenceNumber
                                    ? _c(
                                        "v-chip",
                                        {
                                          class: [
                                            "ml-2",
                                            "py-1",
                                            "px-3",
                                            "fd-status-chip"
                                          ],
                                          attrs: { color: "white" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.scaffold
                                                  .externalReferenceNumber
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticClass: "pt-3",
                          attrs: {
                            "icons-and-text": "",
                            "show-arrows": _vm.$vuetify.breakpoint.smAndUp,
                            "next-icon": "fa-arrow-circle-right",
                            "prev-icon": "fa-arrow-circle-left"
                          },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.detailsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.detailsTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.workOrdersTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.workOrdersTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.workOrdersTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.workOrdersTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.workOrdersTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-file-alt")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          false &&
                          (_vm.inventoryTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.inventoryTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.inventoryTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.inventoryTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.inventoryTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-inventory")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          false &&
                          (_vm.transactionsTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.transactionsTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.transactionsTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(
                                        _vm.transactionsTab
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.transactionsTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-truck-loading")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.countSheetsTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.countSheetsTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.countSheetsTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.countSheetsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.countSheetsTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-list-ol")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.materialTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.materialTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.materialTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.materialTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.materialTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-box")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$store.state.curEnvironment
                            .enableScaffoldInspection &&
                          (_vm.inspectionsTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.inspectionsTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.inspectionsTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.inspectionsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.inspectionsTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$store.getters.icon("inspections")
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$store.state.curEnvironment
                            .enableScaffoldInspection &&
                          (_vm.qrCodeTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.qrCodeTab.key,
                                  attrs: { href: `#tab-${_vm.qrCodeTab.key}` },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.qrCodeTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.qrCodeTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("mdi-qrcode")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$store.state.curEnvironment
                            .enableScaffoldLocation &&
                          (_vm.locationTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.locationTab.key,
                                  attrs: {
                                    "data-cy": "location",
                                    href: `#tab-${_vm.locationTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.locationTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.locationTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v(
                                      "fas " +
                                        _vm._s(
                                          _vm.$store.getters.icon(
                                            "scaffold-location"
                                          )
                                        )
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", left: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                              staticStyle: {
                                                position: "relative"
                                              },
                                              attrs: { text: "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("common.more")) +
                                              "\n\n                  "
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-ellipsis-h")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { staticClass: "grey lighten-3" },
                                _vm._l(_vm.hiddenTabDefinitions, function(
                                  item
                                ) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.tabname,
                                      on: {
                                        click: function($event) {
                                          return _vm.showNewTabFromMoreMenu(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.tabname) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: `tab-${_vm.detailsTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "detailsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "mx-0 px-2 fd-inline-request-tags-qualifier",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("fd-chip-selector", {
                                            staticClass: "fd-readonly",
                                            attrs: {
                                              "available-items":
                                                _vm.availableKeywords,
                                              "selected-items":
                                                _vm.selectedKeywords,
                                              "item-label": "name",
                                              disabled: _vm.processing,
                                              readonly: true,
                                              "control-label": _vm.$t(
                                                "common.add-tags"
                                              ),
                                              "chip-class":
                                                "keywords-selection-chip-colors",
                                              outlined: false
                                            },
                                            on: {
                                              "update:selectedItems": function(
                                                $event
                                              ) {
                                                _vm.selectedKeywords = $event
                                              },
                                              "update:selected-items": function(
                                                $event
                                              ) {
                                                _vm.selectedKeywords = $event
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-2 pt-5",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              "data-cy": "tag-number",
                                              label: _vm.$t(
                                                "scaffolds.tag-number-label"
                                              ),
                                              value:
                                                _vm.scaffold.internalNumber,
                                              disabled: _vm.processing,
                                              readonly: true
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _vm.canEditAnything
                                            ? _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  disabled:
                                                    _vm.processing ||
                                                    !_vm.canEditScaffoldStatus,
                                                  label: _vm.$t(
                                                    "scaffolds.status-label"
                                                  ),
                                                  items: _vm.selectableStatuses
                                                },
                                                model: {
                                                  value:
                                                    _vm.scaffold.scaffoldStatus,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.scaffold,
                                                      "scaffoldStatus",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scaffold.scaffoldStatus"
                                                }
                                              })
                                            : _c("fd-text-field", {
                                                staticClass: "fd-readonly",
                                                attrs: {
                                                  "data-cy": "status",
                                                  label: _vm.$t(
                                                    "scaffolds.status-label"
                                                  ),
                                                  value: _vm.scaffoldStatusName,
                                                  disabled: _vm.processing,
                                                  readonly: true
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass: "fd-readonly",
                                            attrs: {
                                              "data-cy": "area",
                                              label: _vm.$t(
                                                "scaffolds.area-label"
                                              ),
                                              value: _vm.areaName,
                                              disabled: _vm.processing,
                                              readonly: true
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass: "fd-readonly",
                                            attrs: {
                                              "data-cy": "subarea",
                                              label: _vm.$t(
                                                "scaffolds.sub-area-label"
                                              ),
                                              value: _vm.subAreaName,
                                              disabled: _vm.processing,
                                              readonly: true
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.curUserCanViewAllContractors ||
                                      _vm.curUserContractorIDs.length != 1
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                md: "6"
                                              }
                                            },
                                            [
                                              _c("fd-text-field", {
                                                staticClass: "fd-readonly",
                                                attrs: {
                                                  "data-cy": "contractor",
                                                  label: _vm.$t(
                                                    "scaffolds.contractor-label"
                                                  ),
                                                  value: _vm.contractorName,
                                                  disabled: _vm.processing,
                                                  readonly: true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.curUserCanViewAllContractors ||
                                      _vm.curUserContractorIDs.length != 1
                                        ? _c("v-col", {
                                            staticClass:
                                              "justify-space-between pt-1 pb-1",
                                            attrs: {
                                              align: "center",
                                              cols: "0",
                                              md: "6"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-date-picker", {
                                            staticClass: "fd-readonly",
                                            attrs: {
                                              outlined: "",
                                              cy: "plannederect",
                                              disabled: _vm.processing,
                                              readonly: true,
                                              label: _vm.$t(
                                                "scaffolds.planned-erect-date-label"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.scaffold.plannedErectDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.scaffold,
                                                  "plannedErectDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scaffold.plannedErectDate"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-date-picker", {
                                            staticClass: "fd-readonly",
                                            attrs: {
                                              outlined: "",
                                              cy: "planneddismantle",
                                              disabled: _vm.processing,
                                              readonly: true,
                                              label: _vm.$t(
                                                "scaffolds.planned-dismantle-date-label"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.scaffold
                                                  .plannedDismantleDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.scaffold,
                                                  "plannedDismantleDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scaffold.plannedDismantleDate"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-date-picker", {
                                            attrs: {
                                              outlined: "",
                                              cy: "actualerect",
                                              disabled:
                                                _vm.processing ||
                                                !_vm.canEditActualErectDate,
                                              readonly: !_vm.canEditAnything,
                                              label: _vm.$t(
                                                "scaffolds.actual-erect-date-label"
                                              ),
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.scaffold.actualErectDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.scaffold,
                                                  "actualErectDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scaffold.actualErectDate"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-date-picker", {
                                            attrs: {
                                              outlined: "",
                                              cy: "actualdismantle",
                                              disabled:
                                                _vm.processing ||
                                                !_vm.canEditActualDismantleDate,
                                              readonly: !_vm.canEditAnything,
                                              label: _vm.$t(
                                                "scaffolds.actual-dismantle-date-label"
                                              ),
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.scaffold
                                                  .actualDismantleDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.scaffold,
                                                  "actualDismantleDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scaffold.actualDismantleDate"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1 reduce-underlying-control-padding",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-textarea", {
                                            attrs: {
                                              outlined: "",
                                              cy: "location",
                                              label: _vm.$t(
                                                "scaffolds.location-label"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true,
                                              rows: "1",
                                              "auto-grow": ""
                                            },
                                            model: {
                                              value:
                                                _vm.scaffold
                                                  .specificWorkLocation,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.scaffold,
                                                  "specificWorkLocation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scaffold.specificWorkLocation"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1 reduce-underlying-control-padding",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _vm.$store.state.curEnvironment
                                            .trackScaffoldVLF
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    position: "relative"
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    attrs: {
                                                      outlined: "",
                                                      cy: "totalvlf",
                                                      label: _vm.$t(
                                                        "scaffolds.vertical-length-feet-label"
                                                      ),
                                                      disabled: _vm.processing,
                                                      readonly: true
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "append",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  style: _vm
                                                                    .$vuetify
                                                                    .breakpoint
                                                                    .smAndUp
                                                                    ? "width: 108px;"
                                                                    : "width: 46px;",
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                             \n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3623959454
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.scaffold.totalVLF,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.scaffold,
                                                          "totalVLF",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scaffold.totalVLF"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      class: [
                                                        _vm.$vuetify.breakpoint
                                                          .smAndUp
                                                          ? ""
                                                          : "fd-small-history",
                                                        "white--text"
                                                      ],
                                                      staticStyle: {
                                                        position: "absolute",
                                                        right: "12px"
                                                      },
                                                      style: _vm.$vuetify
                                                        .breakpoint.xsOnly
                                                        ? "top: 14px;"
                                                        : "top: 14px;",
                                                      attrs: {
                                                        color: "blue-grey",
                                                        small: "",
                                                        disabled: _vm.processing
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.vlfHistoryClicked
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          class: [
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .smAndUp
                                                              ? ""
                                                              : "mr-0"
                                                          ],
                                                          attrs: { left: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          mdi-history\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "common.history"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-3 pb-5",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            md: "12"
                                          }
                                        },
                                        [
                                          _c("fd-async-search-box", {
                                            attrs: {
                                              cy: "iwp",
                                              "data-cy": "iwp_wrapper",
                                              items: _vm.availableIWPs,
                                              selectedItems: _vm.selectedIWPs,
                                              label: _vm.$t(
                                                "scaffolds.iwp-select"
                                              ),
                                              placeholder: _vm.$t(
                                                "scaffolds.iwp-select-placeholder"
                                              ),
                                              itemText: "nameCode",
                                              chipClass:
                                                "keywords-selection-chip-colors-secondary",
                                              disabled: true,
                                              readonly: true,
                                              "menu-props": {
                                                top: true,
                                                closeOnContentClick:
                                                  _vm.$vuetify.breakpoint.mobile
                                              },
                                              attach: "[data-cy=iwp_wrapper]"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-0 pb-2",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "12"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              "data-cy": "archived",
                                              label: _vm.$t(
                                                "common.archived-label"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            },
                                            model: {
                                              value: _vm.scaffold.archived,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.scaffold,
                                                  "archived",
                                                  $$v
                                                )
                                              },
                                              expression: "scaffold.archived"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.workOrdersTab.key,
                              attrs: { value: `tab-${_vm.workOrdersTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "workordersform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "", tile: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                                attrs: {
                                                                  color:
                                                                    "black",
                                                                  dark: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "scaffolds.existing-scaffold.work-orders.table-listing-information"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-0",
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: _vm.$t(
                                                    "common.search"
                                                  ),
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.workOrderTableSearch,
                                                  callback: function($$v) {
                                                    _vm.workOrderTableSearch = $$v
                                                  },
                                                  expression:
                                                    "workOrderTableSearch"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:internalNumber",
                                                value: _vm.$vuetify.breakpoint
                                                  .xs
                                                  ? _vm.$t(
                                                      "scaffolds.existing-scaffold.work-orders.number-label-short"
                                                    )
                                                  : _vm.$t(
                                                      "scaffolds.existing-scaffold.work-orders.number-label"
                                                    ),
                                                expression:
                                                  "\n                        $vuetify.breakpoint.xs\n                          ? $t('scaffolds.existing-scaffold.work-orders.number-label-short')\n                          : $t('scaffolds.existing-scaffold.work-orders.number-label')\n                      ",
                                                arg: "internalNumber"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:workOrderStatusName",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.status-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.status-label')\n                      ",
                                                arg: "workOrderStatusName"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:assignedContractorName.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.contractor-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.contractor-label')\n                      ",
                                                arg: "assignedContractorName",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:areaName.hide-when-medium",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.area-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.area-label')\n                      ",
                                                arg: "areaName",
                                                modifiers: {
                                                  "hide-when-medium": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:subAreaName.hide-when-medium",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.sub-area-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.sub-area-label')\n                      ",
                                                arg: "subAreaName",
                                                modifiers: {
                                                  "hide-when-medium": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:requestTypeName",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.type-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.type-label')\n                      ",
                                                arg: "requestTypeName"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:formattedRequestDate.hide-when-medium",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.requested-on-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.requested-on-label')\n                      ",
                                                arg: "formattedRequestDate",
                                                modifiers: {
                                                  "hide-when-medium": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:formattedCompletedDate.hide-when-small",
                                                value: _vm.$t(
                                                  "scaffolds.existing-scaffold.work-orders.completed-on-label"
                                                ),
                                                expression:
                                                  "\n                        $t('scaffolds.existing-scaffold.work-orders.completed-on-label')\n                      ",
                                                arg: "formattedCompletedDate",
                                                modifiers: {
                                                  "hide-when-small": true
                                                }
                                              }
                                            ],
                                            attrs: {
                                              "custom-sort":
                                                _vm.workOrderCustomSort,
                                              items: _vm.scaffoldWorkOrders,
                                              search: _vm.workOrderTableSearch,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": [
                                                "formattedCompletedDate"
                                              ],
                                              "sort-desc": true,
                                              "mobile-breakpoint": "0",
                                              "show-expand": ""
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "expanded-item",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "fd-expanding-table-single-details-section",
                                                        attrs: {
                                                          colspan:
                                                            _vm.expanderColSpan
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "fd-work-order-details",
                                                          {
                                                            staticClass:
                                                              "fd-expanding-table-single-details-section",
                                                            attrs: {
                                                              workOrder: item,
                                                              walkdown:
                                                                item.walkdown
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          false
                            ? _c(
                                "v-tab-item",
                                {
                                  key: _vm.inventoryTab.key,
                                  attrs: {
                                    value: `tab-${_vm.inventoryTab.key}`
                                  }
                                },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "inventoryform",
                                      on: { submit: _vm.preventSubmit }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "", tile: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function({
                                                              on
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "ml-1",
                                                                      attrs: {
                                                                        color:
                                                                          "black",
                                                                        dark: ""
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "info"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        92516267
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scaffolds.existing-scaffold.work-orders.table-listing-information"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    staticClass: "pl-0",
                                                    attrs: {
                                                      "append-icon": "search",
                                                      label: _vm.$t(
                                                        "common.search"
                                                      ),
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workOrderTableSearch,
                                                      callback: function($$v) {
                                                        _vm.workOrderTableSearch = $$v
                                                      },
                                                      expression:
                                                        "workOrderTableSearch"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-data-table", {
                                                directives: [
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:partNumber",
                                                    value: _vm.$t(
                                                      "scaffolds.inventory.part-number-label"
                                                    ),
                                                    expression:
                                                      "$t('scaffolds.inventory.part-number-label')",
                                                    arg: "partNumber"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:displayNumber",
                                                    value: _vm.$t(
                                                      "scaffolds.inventory.display-number-label"
                                                    ),
                                                    expression:
                                                      "$t('scaffolds.inventory.display-number-label')",
                                                    arg: "displayNumber"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:description.hide-when-extra-small",
                                                    value: _vm.$t(
                                                      "scaffolds.inventory.description-label"
                                                    ),
                                                    expression:
                                                      "\n                        $t('scaffolds.inventory.description-label')\n                      ",
                                                    arg: "description",
                                                    modifiers: {
                                                      "hide-when-extra-small": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:quantity",
                                                    value: _vm.$t(
                                                      "scaffolds.inventory.quantity-label"
                                                    ),
                                                    expression:
                                                      "$t('scaffolds.inventory.quantity-label')",
                                                    arg: "quantity"
                                                  }
                                                ],
                                                attrs: {
                                                  items: _vm.scaffold.inventory,
                                                  search:
                                                    _vm.inventoryTableSearch,
                                                  loading: _vm.processing,
                                                  "loading-text": _vm.$t(
                                                    "common.table-loading-message"
                                                  ),
                                                  "sort-by": ["completedOn"],
                                                  "mobile-breakpoint": "0",
                                                  "show-expand": ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "expanded-item",
                                                      fn: function({ item }) {
                                                        return [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "fd-expanding-table-single-details-section",
                                                              attrs: {
                                                                colspan:
                                                                  _vm.expanderColSpan
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "fd-details-section pt-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "12",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-row",
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs: {
                                                                                    cols:
                                                                                      "6"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "scaffolds.inventory.part-number-label"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.partNumber
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "12",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-row",
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs: {
                                                                                    cols:
                                                                                      "6"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "scaffolds.inventory.display-number-label"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.displayNumber
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "12",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-row",
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs: {
                                                                                    cols:
                                                                                      "6"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "scaffolds.inventory.description-label"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.description
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "12",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-row",
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs: {
                                                                                    cols:
                                                                                      "6"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "scaffolds.inventory.quantity-label"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.quantity
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  507525406
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          false
                            ? _c(
                                "v-tab-item",
                                {
                                  key: _vm.transactionsTab.key,
                                  attrs: {
                                    value: `tab-${_vm.transactionsTab.key}`
                                  }
                                },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "transactionsform",
                                      on: { submit: _vm.preventSubmit }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "", tile: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function({
                                                              on
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "ml-1",
                                                                      attrs: {
                                                                        color:
                                                                          "black",
                                                                        dark: ""
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "info"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        92516267
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scaffolds.existing-scaffold.work-orders.table-listing-information"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    staticClass: "pl-0",
                                                    attrs: {
                                                      "append-icon": "search",
                                                      label: _vm.$t(
                                                        "common.search"
                                                      ),
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workOrderTableSearch,
                                                      callback: function($$v) {
                                                        _vm.workOrderTableSearch = $$v
                                                      },
                                                      expression:
                                                        "workOrderTableSearch"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-data-table", {
                                                directives: [
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:transferNumber",
                                                    value: _vm.$t(
                                                      "scaffolds.transactions.transfer-number-label"
                                                    ),
                                                    expression:
                                                      "\n                        $t('scaffolds.transactions.transfer-number-label')\n                      ",
                                                    arg: "transferNumber"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:transferDate",
                                                    value: _vm.$t(
                                                      "scaffolds.transactions.transfer-date-label"
                                                    ),
                                                    expression:
                                                      "$t('scaffolds.transactions.transfer-date-label')",
                                                    arg: "transferDate"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:transferType",
                                                    value: _vm.$t(
                                                      "scaffolds.transactions.transfer-type-label"
                                                    ),
                                                    expression:
                                                      "$t('scaffolds.transactions.transfer-type-label')",
                                                    arg: "transferType"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:fromYard.hide-when-extra-small",
                                                    value: _vm.$t(
                                                      "scaffolds.transactions.from-yard-label"
                                                    ),
                                                    expression:
                                                      "\n                        $t('scaffolds.transactions.from-yard-label')\n                      ",
                                                    arg: "fromYard",
                                                    modifiers: {
                                                      "hide-when-extra-small": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:toYard.hide-when-extra-small",
                                                    value: _vm.$t(
                                                      "scaffolds.transactions.to-yard-label"
                                                    ),
                                                    expression:
                                                      "\n                        $t('scaffolds.transactions.to-yard-label')\n                      ",
                                                    arg: "toYard",
                                                    modifiers: {
                                                      "hide-when-extra-small": true
                                                    }
                                                  }
                                                ],
                                                attrs: {
                                                  items:
                                                    _vm.scaffold.transactions,
                                                  search:
                                                    _vm.transactionsTableSearch,
                                                  loading: _vm.processing,
                                                  "loading-text": _vm.$t(
                                                    "common.table-loading-message"
                                                  ),
                                                  "sort-by": ["transferDate"],
                                                  "mobile-breakpoint": "0",
                                                  "show-expand": ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "expanded-item",
                                                      fn: function({ item }) {
                                                        return [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "fd-expanding-table-single-details-section",
                                                              attrs: {
                                                                colspan:
                                                                  _vm.expanderColSpan
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "fd-details-section pt-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "12",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-row",
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs: {
                                                                                    cols:
                                                                                      "6"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "scaffolds.transactions.transfer-number-label"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.transferNumber
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "12",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-row",
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs: {
                                                                                    cols:
                                                                                      "6"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "scaffolds.transactions.transfer-date-label"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.transferDate
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "12",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-row",
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs: {
                                                                                    cols:
                                                                                      "6"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "scaffolds.transactions.transfer-type-label"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.transferType
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "0",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "12",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-row",
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs: {
                                                                                    cols:
                                                                                      "6"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "scaffolds.transactions.from-yard-label"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.fromYard
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "12",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-row",
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs: {
                                                                                    cols:
                                                                                      "6"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "strong",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "scaffolds.transactions.to-yard-label"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.toYard
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3476357946
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.countSheetsTab.key,
                              attrs: { value: `tab-${_vm.countSheetsTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scaffolds.existing-scaffold.count-sheets.table-listing-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.countsheettablesearch,
                                              callback: function($$v) {
                                                _vm.countsheettablesearch = $$v
                                              },
                                              expression:
                                                "countsheettablesearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:workOrderNumber",
                                            value: _vm.$t(
                                              "countsheet.list.work-order-column-label"
                                            ),
                                            expression:
                                              "$t('countsheet.list.work-order-column-label')",
                                            arg: "workOrderNumber"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:requestTypeName.hide-when-extra-small",
                                            value: _vm.$t(
                                              "countsheet.list.request-type-column-label"
                                            ),
                                            expression:
                                              "\n                      $t('countsheet.list.request-type-column-label')\n                    ",
                                            arg: "requestTypeName",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:workOrderStatusName.hide-when-medium",
                                            value: _vm.$t(
                                              "countsheet.list.work-order-status-column-label"
                                            ),
                                            expression:
                                              "\n                      $t('countsheet.list.work-order-status-column-label')\n                    ",
                                            arg: "workOrderStatusName",
                                            modifiers: {
                                              "hide-when-medium": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:transferIDsList.hide-when-small",
                                            value: _vm.$t(
                                              "countsheet.list.transfer-ids-column-label"
                                            ),
                                            expression:
                                              "\n                      $t('countsheet.list.transfer-ids-column-label')\n                    ",
                                            arg: "transferIDsList",
                                            modifiers: {
                                              "hide-when-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:submittedBy.no-sort.hide-when-extra-small",
                                            value: _vm.$t(
                                              "countsheet.list.submitter-column-label"
                                            ),
                                            expression:
                                              "\n                      $t('countsheet.list.submitter-column-label')\n                    ",
                                            arg: "submittedBy",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:submittedByContractor.no-sort.hide-when-small",
                                            value: _vm.$t(
                                              "countsheet.list.contractor-column-label"
                                            ),
                                            expression:
                                              "\n                      $t('countsheet.list.contractor-column-label')\n                    ",
                                            arg: "submittedByContractor",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:reviewStatusName.hide-when-small",
                                            value: _vm.$t(
                                              "countsheet.list.status-column-label"
                                            ),
                                            expression:
                                              "\n                      $t('countsheet.list.status-column-label')\n                    ",
                                            arg: "reviewStatusName",
                                            modifiers: {
                                              "hide-when-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:formattedReviewStatusChangeTime",
                                            value: _vm.$t(
                                              "countsheet.list.status-change-time-column-label"
                                            ),
                                            expression:
                                              "\n                      $t('countsheet.list.status-change-time-column-label')\n                    ",
                                            arg:
                                              "formattedReviewStatusChangeTime"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:reviewApprovedBy.no-sort.hide-when-small",
                                            value: _vm.$t(
                                              "countsheet.list.approved-by-column-label"
                                            ),
                                            expression:
                                              "\n                      $t('countsheet.list.approved-by-column-label')\n                    ",
                                            arg: "reviewApprovedBy",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:countSheetTypeName.hide-when-medium",
                                            value: _vm.$t(
                                              "countsheet.list.count-sheet-type-column-label"
                                            ),
                                            expression:
                                              "\n                      $t('countsheet.list.count-sheet-type-column-label')\n                    ",
                                            arg: "countSheetTypeName",
                                            modifiers: {
                                              "hide-when-medium": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:action.no-sort.hide-when-extra-small.class_fd-action-cell",
                                            arg: "action",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-action-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.show-when-extra-small",
                                            value: item =>
                                              _vm.$router.push(
                                                `/countsheets/${item.id}`
                                              ),
                                            expression:
                                              "\n                      item => $router.push(`/countsheets/${item.id}`)\n                    ",
                                            modifiers: {
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        class: ["fd-countsheet-list"],
                                        attrs: {
                                          "data-cy": "countsheetslist",
                                          items: _vm.countSheets,
                                          search: _vm.countsheettablesearch,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "mobile-breakpoint": "0"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.transferIDsList",
                                            fn: function({ item }) {
                                              return [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value: item.transferIDsList,
                                                    "no-value-key":
                                                      "countsheet.list.no-parts"
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.submittedBy",
                                            fn: function({ item }) {
                                              return [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$lookup.personBySecurityID(
                                                        item.submittedBy
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.submittedByContractor",
                                            fn: function({ item }) {
                                              return [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$lookup.contractor(
                                                        _vm.$lookupItem.personBySecurityID(
                                                          item.submittedBy
                                                        ).contractorID
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.reviewApprovedBy",
                                            fn: function({ item }) {
                                              return [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      !item.reviewApprovalIsAutomatic
                                                        ? _vm.$lookup.personBySecurityID(
                                                            item.reviewApprovedBy
                                                          )
                                                        : _vm.$t(
                                                            "countsheet.list.automatic-approval-label"
                                                          )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.action",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-1-action-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          "data-cy":
                                                                            "edit",
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to: `/countsheets/${item.id}`
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.materialTab.key,
                              attrs: { value: `tab-${_vm.materialTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scaffolds.existing-scaffold.material.table-listing-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.partstablesearch,
                                              callback: function($$v) {
                                                _vm.partstablesearch = $$v
                                              },
                                              expression: "partstablesearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:publicID",
                                            value: _vm.$t("common.code"),
                                            expression: "$t('common.code')",
                                            arg: "publicID"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:description",
                                            arg: "description"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:total",
                                            arg: "total"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:totalWeight",
                                            value: _vm.$t(
                                              "scaffolds.existing-scaffold.material.total-weight-column-label"
                                            ),
                                            expression:
                                              "\n                      $t('scaffolds.existing-scaffold.material.total-weight-column-label')\n                    ",
                                            arg: "totalWeight"
                                          }
                                        ],
                                        class: ["fd-parts-list"],
                                        attrs: {
                                          "data-cy": "standingpartslist",
                                          items: _vm.standingParts,
                                          search: _vm.partstablesearch,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "mobile-breakpoint": "0",
                                          "items-per-page":
                                            _vm.partsItemsPerPage,
                                          "footer-props": {
                                            "items-per-page-options":
                                              _vm.partsItemsPerPageOptions
                                          }
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.publicID",
                                            fn: function({ item }) {
                                              return [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value: item.publicID,
                                                    "no-value-text": _vm.$t(
                                                      "common.unknown"
                                                    )
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.name",
                                            fn: function({ item }) {
                                              return [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value: item.name,
                                                    "no-value-text": _vm.$t(
                                                      "common.unknown"
                                                    )
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.description",
                                            fn: function({ item }) {
                                              return [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value: item.description,
                                                    "no-value-text": _vm.$t(
                                                      "common.unknown"
                                                    )
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.total",
                                            fn: function({ item }) {
                                              return [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value: item.total,
                                                    "no-value-text": "0"
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.totalWeight",
                                            fn: function({ item }) {
                                              return [
                                                _c("fd-value-display", {
                                                  attrs: {
                                                    value: item.totalWeight,
                                                    "no-value-text": "0.0"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.inspectionsTab.key,
                              attrs: {
                                eager: "",
                                value: `tab-${_vm.inspectionsTab.key}`
                              }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("fd-date-range-picker", {
                                                    staticClass: "pt-3",
                                                    staticStyle: {
                                                      "margin-top": "6px"
                                                    },
                                                    attrs: {
                                                      dense: "",
                                                      min: _vm.minDate,
                                                      max: _vm.maxDate,
                                                      loading: _vm.processing,
                                                      disabled: _vm.processing,
                                                      "hide-details": "",
                                                      outlined: false,
                                                      presets:
                                                        _vm.dateRangePresetOptions
                                                    },
                                                    on: {
                                                      "input:fromDate":
                                                        _vm.fromDateChanged,
                                                      "input:toDate":
                                                        _vm.toDateChanged
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.showArchivedDateRange,
                                                      callback: function($$v) {
                                                        _vm.showArchivedDateRange = $$v
                                                      },
                                                      expression:
                                                        "showArchivedDateRange"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "pl-0 mt-1",
                                                    attrs: {
                                                      "append-icon": "search",
                                                      label: _vm.$t(
                                                        "common.search"
                                                      ),
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.inspectionstablesearch,
                                                      callback: function($$v) {
                                                        _vm.inspectionstablesearch = $$v
                                                      },
                                                      expression:
                                                        "inspectionstablesearch"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:inspectionDateTime.hide-when-medium",
                                            value: _vm.$t(
                                              "inspections.inspection-date-label"
                                            ),
                                            expression:
                                              "\n                      $t('inspections.inspection-date-label')\n                    ",
                                            arg: "inspectionDateTime",
                                            modifiers: {
                                              "hide-when-medium": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:inspectedBy.hide-when-medium",
                                            value: _vm.$t(
                                              "inspections.inspected-by-label"
                                            ),
                                            expression:
                                              "\n                      $t('inspections.inspected-by-label')\n                    ",
                                            arg: "inspectedBy",
                                            modifiers: {
                                              "hide-when-medium": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:inspectionDidPass",
                                            value: _vm.$t(
                                              "inspections.inspection-result-label"
                                            ),
                                            expression:
                                              "$t('inspections.inspection-result-label')",
                                            arg: "inspectionDidPass"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:action.no-sort.hide-when-extra-small.class_fd-action-cell",
                                            arg: "action",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-action-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.show-when-extra-small",
                                            value: item =>
                                              _vm.$router.push(
                                                `/inspections/${item.id}`
                                              ),
                                            expression:
                                              "\n                      item => $router.push(`/inspections/${item.id}`)\n                    ",
                                            modifiers: {
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        class: ["fd-inspections-list"],
                                        attrs: {
                                          "data-cy": "standinginspectionslist",
                                          items: _vm.inspections,
                                          search: _vm.inspectionstablesearch,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "mobile-breakpoint": "0",
                                          "items-per-page":
                                            _vm.inspectionsItemsPerPage,
                                          "footer-props": {
                                            "items-per-page-options":
                                              _vm.inspectionsItemsPerPageOptions
                                          },
                                          "sort-by": ["inspectionDateTime"],
                                          "sort-desc": true
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.fd-nav",
                                            fn: function() {
                                              return [
                                                _c("v-icon", [
                                                  _vm._v("mdi-chevron-right")
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.inspectionDateTime",
                                            fn: function({ item }) {
                                              return [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$format.date(
                                                        item.inspectionDateTime
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.inspectedBy",
                                            fn: function({ item }) {
                                              return [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$lookup.person(
                                                        item.inspectedBy
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.inspectionDidPass",
                                            fn: function({ item }) {
                                              return [
                                                _c("fd-checkbox", {
                                                  attrs: {
                                                    value:
                                                      item.inspectionDidPass,
                                                    readonly: true,
                                                    "on-text": _vm.$t(
                                                      "inspections.inspection-result-pass-label"
                                                    ),
                                                    "on-color":
                                                      "fd-inspection-pass",
                                                    "off-text": _vm.$t(
                                                      "inspections.inspection-result-fail-label"
                                                    ),
                                                    "off-color":
                                                      "fd-inspection-fail"
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.action",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-1-action-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to: `/inspections/${item.id}`
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-eye\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.view"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.qrCodeTab.key,
                              attrs: {
                                eager: "",
                                value: `tab-${_vm.qrCodeTab.key}`
                              }
                            },
                            [
                              _c("canvas", {
                                ref: "qrcanvas",
                                attrs: { eager: "" }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.locationTab.key,
                              attrs: { value: `tab-${_vm.locationTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-0 pt-3" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              value: _vm.scaffold.latitude,
                                              label: "Latitude",
                                              readonly: true,
                                              "hide-details": "",
                                              dense: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              value: _vm.scaffold.longitude,
                                              label: "Longitude",
                                              readonly: true,
                                              "hide-details": "",
                                              dense: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { staticClass: "grow" },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                dense: "",
                                                "no-gutters": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    outlined: ""
                                                  },
                                                  on: {
                                                    click: _vm.changeLocation
                                                  }
                                                },
                                                [_vm._v("Change")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fp-map", {
                                        attrs: {
                                          center: _vm.environmentLocation,
                                          value: _vm.scaffoldLocation,
                                          disabled: true,
                                          "map-display-type": _vm.mapLayerType
                                        },
                                        on: {
                                          "update:mapDisplayType": function(
                                            $event
                                          ) {
                                            _vm.mapLayerType = $event
                                          },
                                          "update:map-display-type": function(
                                            $event
                                          ) {
                                            _vm.mapLayerType = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-form",
                        { ref: "form", on: { submit: _vm.onSubmit } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-0", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mx-0", attrs: { cols: "12" } },
                                [
                                  _vm.inlineMessage.message
                                    ? _c("fd-alert", {
                                        attrs: {
                                          type: _vm.inlineMessage.type,
                                          label: _vm.inlineMessage.message
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _vm.$store.state.curEnvironment
                                .enableScaffoldInspection
                                ? _c(
                                    "v-menu",
                                    {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on }) {
                                              return [
                                                _vm.$store.state.curEnvironment
                                                  .enableScaffoldInspection
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            outlined: "",
                                                            small:
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .xsOnly
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-qrcode")
                                                        ]),
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "scaffolds.existing-scaffold.qrcode.action-button"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        ),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "2px !important"
                                                            },
                                                            attrs: { right: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-menu-down"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2316266991
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              on: { click: _vm.downloadQRCode }
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-download")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scaffolds.existing-scaffold.qrcode.action-download"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item",
                                            { on: { click: _vm.printQRCode } },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-printer")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scaffolds.existing-scaffold.qrcode.action-print"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: _vm.printQRCodeLabel
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-sticker")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scaffolds.existing-scaffold.qrcode.action-print-label"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "data-cy": "close",
                                    text: "",
                                    link: "",
                                    disabled: _vm.processing,
                                    small: _vm.$vuetify.breakpoint.xsOnly
                                  },
                                  on: { click: _vm.cancel }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.close")) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("fd-menu-save", {
                                attrs: {
                                  disabled:
                                    _vm.processing || !_vm.canEditAnything,
                                  loading: _vm.saving,
                                  small: _vm.$vuetify.breakpoint.xsOnly
                                },
                                on: {
                                  "click:save": function($event) {
                                    return _vm.save(false)
                                  },
                                  "click:save-and-close": function($event) {
                                    return _vm.save(true)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }