import { mapMutations, mapActions } from "vuex";
import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import rules from "@fd/lib/vue/rules";
import { BlobFile, ISO, System, TestPackage, blobFileService } from "@fd/current/client/services";
import { SortItemsWithName } from "../utils/person";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import fileHandling, {
  FileData,
  canOpenFileInNewWindow,
  componentsFromFileName,
  confirmUniqueName,
  isFilePhoto,
  isFilePreviewable
} from "@fd/lib/vue/mixins/fileHandling";
import { ExternalLink, externalLinkService } from "../services/index";
import { Attachment } from "../dataMixins/attachment";
import { openExternalLinkDetails } from "./components/ExternalLinkDialog.vue";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";

type ISOWithArchived = ISO & { archived: boolean };
type BlobFileData = BlobFile & FileData;

type AttachmentWithBlobFile = Attachment & {
  id: string | null | undefined;
  blobFile?: BlobFileData | undefined;
};

export default FDVue.extend({
  name: "sp-iso-existing",

  mixins: [serviceErrorHandling, rules, tabbedView, fileHandling],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      slidein: false,
      systemID: null as string | null,
      navigatedFromSystemTestPackage: false,
      navigatedFromTestPackage: false,
      canChangeTestPackage: false,
      partstablesearch: "",
      partsItemsPerPage: 25,
      partsItemsPerPageOptions: [5, 10, 25, 50, 100, -1],

      firstTabKey: `1`,
      detailsTab: new PageTab({
        nameKey: "systems.test-packages.isos.existing.tabs.details",
        key: `1`,
        visible: true
      }),
      attachmentsTab: new PageTab({
        nameKey: "systems.test-packages.isos.existing.tabs.attachments",
        key: "2",
        visible: true
      }),
      takeoffTab: new PageTab({
        nameKey: "systems.test-packages.isos.existing.tabs.takeoff",
        key: "3",
        visible: false
      }),

      iso: {
        testPackageID: "",
        name: "",
        code: "",
        description: "",
        estimatedWorkHours: 0,
        archived: false
      } as ISOWithArchived,

      // *** ATTACHMENTS ***
      touchedFileName: "",
      tablesearchfiles: "",
      allFiles: [] as BlobFileData[],
      externalLinks: [] as ExternalLink[],

      // *** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as BlobFileData | undefined
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.attachmentsTab, this.takeoffTab] as Tab[];
    },
    storeISO(): ISO {
      return this.$store.state.isos.fullList.find((x: ISO) => x.id == this.$route.params.id);
    },

    // Get all the ISOs that are associated to the current iso object.
    isos(): {}[] {
      return SortItemsWithName(
        this.$store.state.isos.fullList.map((x: ISO) => {
          return {
            ...x,
            description: truncateWithEllipsis(stripHtml(x.description)),
            archived: !!x.archivedDate
          };
        })
      );
    },
    systems(): System[] {
      return SortItemsWithName(this.$store.state.systems.fullList as System[]);
    },
    testPackages(): TestPackage[] {
      return this.$store.state.testPackages.fullList as TestPackage[];
    },
    testPackagesForSelectedSystem(): TestPackage[] {
      return SortItemsWithName(this.testPackages.filter(x => x.systemID == this.systemID));
    },

    //#region "*** ATTACHMENTS ***"
    attachments(): AttachmentWithBlobFile[] {
      let attachments = [] as AttachmentWithBlobFile[];

      this.allFiles.forEach(file => {
        attachments.push({
          id: file.id,
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          blobFile: file
        });
      });

      this.externalLinks.forEach(link => {
        attachments.push({
          id: link.id,
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      return attachments;
    }
    //#endregion
  },

  methods: {
    backButtonClicked() {
      this.cancel();
    },
    cancel() {
      this.close();
    },
    close() {
      if (this.navigatedFromSystemTestPackage)
        this.$router.push(`/system-testpackages/${this.iso.testPackageID}`);
      else if (this.navigatedFromTestPackage)
        this.$router.push(`/testpackages/${this.iso.testPackageID}`);
      else this.$router.push(`/isos`);
    },
    ...mapMutations({
      setISO: "SET_ISO",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadISO: "LOAD_ISO",
      updateISO: "UPDATE_ISO",
      deleteISO: "DELETE_ISO",
      loadSystems: "LOAD_SYSTEMS",
      loadTestPackages: "LOAD_TEST_PACKAGES"
    }),

    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.iso.archived) {
          this.iso.archivedDate = null;
        } else if (this.iso.archived && !this.iso.archivedDate) {
          this.iso.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateISO({
          ...this.iso,
          id: this.$route.params.id
        });

        if (closeOnComplete) {
          this.close();
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteISO({ id: this.$route.params.id, name: this.iso.name });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.close();
      }
    },

    //#region "*** FILES & ATTACHMENTS ***"
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: AttachmentWithBlobFile) {
      if (!item.canOpenInNew) return;

      if (!!item.blobFile && item.canOpenInNew) {
        await this.openFileInNewWindow(item.blobFile);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async editAttachment(item: AttachmentWithBlobFile) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.blobFile && item.blobFile.isPreviewable) {
        await this.editFile(item.blobFile);
      } else if (!!item.blobFile) {
        await this.editNameForFile(item.blobFile);
      }
    },
    async deleteAttachment(item: AttachmentWithBlobFile) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.blobFile) {
        await this.deleteFile(item.blobFile);
      }
    },

    // Links
    async loadLinks() {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        var links = await externalLinkService.getByIsoID(this.iso.id!);
        this.externalLinks = links;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        newLink.isoID = this.iso.id;
        newLink.id = await externalLinkService.addItem(newLink);
        this.externalLinks.push(newLink);

        var snackbarPayload = {
          text: this.$t("isos.existing.save-link-success", [newLink.name]),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = newLink.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        let currentProcessing = this.processing;
        this.processing = true;
        try {
          await externalLinkService.updateItem(link.id!, {
            ...link,
            name: editedLink.name,
            address: editedLink.address
          });
          link.name = editedLink.name;
          link.address = editedLink.address;

          var snackbarPayload = {
            text: this.$t("isos.existing.update-link-success", [link.name]),
            type: "success"
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          this.touchedFileName = link.name ?? "";
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = currentProcessing;
        }
      }
    },
    async deleteLink(link: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        await externalLinkService.deleteItem(link.id!);
        this.externalLinks.splice(this.externalLinks.indexOf(link), 1);

        var snackbarPayload = {
          text: this.$t("isos.existing.delete-link-success", [link.name]),
          type: "info",
          undoCallback: async () => {
            await this.saveNewExternalLink(link);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = link.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    fileRowClassName(item: any): string {
      return item.name == this.touchedFileName ? "fd-selected-table-row-background" : "";
    },
    async loadFiles() {
      var files = await blobFileService.getByIsoID(this.iso.id!);
      this.allFiles = files.map(file => {
        var fileName = file.name ?? "";
        return {
          ...file,
          isPreviewable: isFilePreviewable(fileName),
          isPhoto: isFilePhoto(fileName)
        } as BlobFileData;
      });
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async fileInputChanged(v: any) {
      if (!v.target?.files.length) return;
      await this.selectNewFile(v.target.files[0]);
    },
    async selectNewFile(originalFile: File) {
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      if (!fileData) return;

      // GIF files with animations will lose their animation during this process
      // Both due to the quality compression done above (resizing the dimensions of an animated GIF does nothing), and also going through the edit image process
      // This is OK as we shouldn't need animations for any reason
      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        await this.saveNewFileData(fileData);
      }
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        await this.saveNewFileData(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: BlobFileData, originalFileName: string) {
      if (!fileData) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // If we're only renaming the file, the data may not be downloaded yet
          let fileNameToDownload = originalFileName ?? fileData.name;
          fileData.file = await blobFileService.downloadIsoFile(fileData.id!);
        }
        await blobFileService.uploadIsoFile(this.iso.id!, fileData.name, fileData.file as Blob);

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await blobFileService.deleteIsoFile(fileData.id!);
        }

        let snackbarText = fileData.isPhoto
          ? this.$t("isos.existing.update-photo-success", [fileData.name])
          : this.$t("isos.existing.update-file-success", [fileData.name]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData | undefined) {
      await this.saveNewFile(fileData?.name, fileData?.file);
    },
    async saveNewFile(name: string | null | undefined, file?: Blob | undefined) {
      if (!name || !file) return;

      this.processing = true;
      try {
        var newID = await blobFileService.uploadIsoFile(this.iso.id!, name, file as Blob);

        var blobFile = {
          id: newID,
          isoID: this.iso.id!,
          name: name
        } as BlobFile;
        var fileData = {
          ...blobFile,
          name: name,
          file: file,
          isPreviewable: isFilePreviewable(name),
          isPhoto: isFilePhoto(name)
        };
        this.allFiles.push(fileData);

        let snackbarText = fileData.isPhoto
          ? this.$t("isos.existing.save-photo-success", [fileData.name])
          : this.$t("isos.existing.save-file-success", [fileData.name]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async editNameForFile(fileData: BlobFileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.saveEditedFileData(fileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    editFile(fileData: BlobFileData) {
      if (!fileData.isPhoto) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      if (!!fileData.file) {
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.editImageSource = `/services/FormidableDesigns.Services.V1.BlobFileService.DownloadIsoFile?blobID=${fileData.id}`;
      }
    },
    async downloadFile(fileData: BlobFileData) {
      if (!!fileData.file) {
        downloadBlob(fileData.file, fileData.name);
        return;
      }

      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await blobFileService.downloadIsoFile(fileData.id!);
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openFileInNewWindow(fileData: BlobFileData) {
      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        // the data probably hasn't been downloaded yet
        fileData.file = await blobFileService.downloadIsoFile(fileData.id!);
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async viewPhoto(fileData: BlobFileData) {
      if (!fileData.isPreviewable) return;

      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        var file = await blobFileService.downloadIsoFile(fileData.id!);
        fileData.file = file;
      }
      if (!!fileData.file) {
        this.imageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.imageSource = `/services/FormidableDesigns.Services.V1.BlobFileService.DownloadIsoFile?blobID=${fileData.id}`;
      }
    },
    async deleteFile(fileData: BlobFileData) {
      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await blobFileService.downloadIsoFile(fileData.id!);
        }
        await blobFileService.deleteIsoFile(fileData.id!);

        this.allFiles.splice(this.allFiles.indexOf(fileData), 1);

        let snackbarText = fileData.isPhoto
          ? this.$t("isos.existing.delete-photo-success", [fileData.name])
          : this.$t("isos.existing.delete-file-success", [fileData.name]);
        var snackbarPayload = {
          text: snackbarText,
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    attachmentRowClicked(e: Event, data: any) {
      this.openAttachment(data.item);
    }
    //#endregion
  },

  watch: {
    iso: async function(newValue) {
      if (!newValue) return;
      // Since we might be coming to this screen from anywhere in the test package
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if (this.navigatedFromSystemTestPackage) {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/systems") {
          this.notifyNewBreadcrumb({
            text: this.$t("systems.list.title"),
            to: "/systems",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");

          let testPackage = this.testPackages.find(
            (x: any) => x.id == newValue.testPackageID
          ) as TestPackage;
          let system = this.systems.find((x: any) => x.id == testPackage?.systemID) as TestPackage;

          this.notifyNewBreadcrumb({
            text: system?.name,
            to: `/systems/${testPackage?.systemID}`
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");

          this.notifyNewBreadcrumb({
            text: testPackage.name,
            to: `/system-testpackages/${newValue.testPackageID}`
          });

          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        this.notifyNewBreadcrumb({
          text: newValue.name,
          to: `/system-testpackage-isos/${this.$route.params.id}`
        });
      } else if (this.navigatedFromTestPackage) {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/systems") {
          this.notifyNewBreadcrumb({
            text: this.$t("test-packages.list.title"),
            to: "/testpackages",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");

          let testPackage = this.testPackages.find(
            (x: any) => x.id == newValue.testPackageID
          ) as TestPackage;

          this.notifyNewBreadcrumb({
            text: testPackage?.name,
            to: `/testpackages/${newValue.testPackageID}`
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        this.notifyNewBreadcrumb({
          text: newValue.name,
          to: `/testpackage-isos/${this.$route.params.id}`
        });
      } else {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/isos") {
          this.notifyNewBreadcrumb({
            text: this.$t("isos.list.title"),
            to: "/isos",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }
        this.notifyNewBreadcrumb({
          text: newValue.name,
          to: `/isos/${this.$route.params.id}`
        });
      }
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);
    this.navigatedFromSystemTestPackage = this.$route.name == "SystemTestPackageISOExisting";
    this.navigatedFromTestPackage = this.$route.name == "TestPackageISOExisting";
    this.canChangeTestPackage =
      !this.navigatedFromSystemTestPackage && !this.navigatedFromTestPackage;

    var parentalContext =
      this.navigatedFromSystemTestPackage || this.navigatedFromTestPackage
        ? "test-packages-existing"
        : "isos";
    this.setFilteringContext({
      context: "isos-existing",
      parentalContext: parentalContext,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      selectedTab: this.firstTabKey,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date()
    });

    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true
    });

    this.processing = true;
    try {
      await Promise.all([this.loadSystems(), this.loadTestPackages()]);
      this.processing = true;
      await this.loadISO(this.$route.params.id);
      var iso = this.$store.state.isos.fullList.find((x: ISO) => x.id == this.$route.params.id);
      this.iso = {
        ...iso,
        archived: !!iso.archivedDate
      } as ISOWithArchived;
      if (!!this.iso.testPackageID) {
        var selectedTestPackage = this.testPackages.find(x => x.id == this.iso.testPackageID);
        this.systemID = selectedTestPackage?.systemID ?? null;
      }
      await Promise.all([this.loadFiles(), this.loadLinks()]);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.processing = false;
    }
  }
});

