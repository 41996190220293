var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("countsheet.dialog.title")))]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "font-size": "12px", "line-height": "1.5" } },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "ml-2 fd-status-chip",
                      attrs: { color: "success" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm
                              .$t("countsheet.dialog.preview-label")
                              .toUpperCase()
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "justify-end pl-5 pr-5 mr-0 fd-inline-radio-button-qualifier-background",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: {
                        value: "individual",
                        row: "",
                        disabled: "",
                        dense: _vm.$vuetify.breakpoint.xsOnly
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$vuetify.breakpoint.xsOnly
                            ? _vm.$t("countsheet.type.individual-short")
                            : _vm.$t("countsheet.type.individual"),
                          value: "individual"
                        }
                      }),
                      _vm._v(" "),
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$vuetify.breakpoint.xsOnly
                            ? _vm.$t("countsheet.type.remove-all-short")
                            : _vm.$t("countsheet.type.remove-all"),
                          value: "selection"
                        }
                      }),
                      _vm._v(" "),
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$vuetify.breakpoint.xsOnly
                            ? _vm.$t("countsheet.type.not-applicable-short")
                            : _vm.$t("countsheet.type.not-applicable"),
                          value: "notapplicable"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              false
                ? _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "justify-end align-center",
                          staticStyle: { height: "100% !important" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "primary", outlined: "" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.save")) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-btn", { attrs: { color: "primary" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("common.submit")) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.showCraftOptions
            ? _c(
                "v-row",
                {
                  staticClass:
                    "justify-end ml-0 mr-0 fd-secondary-context-qualifier-background"
                },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.filterContext,
                        callback: function($$v) {
                          _vm.filterContext = $$v
                        },
                        expression: "filterContext"
                      }
                    },
                    [
                      _vm.showScaffoldColumn
                        ? _c("v-radio", {
                            attrs: {
                              label: _vm.$t(
                                "countsheetgroups.is-scaffold-group"
                              ),
                              value: "scaffold"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showMaintenanceColumn
                        ? _c("v-radio", {
                            attrs: {
                              label: _vm.$t(
                                "countsheetgroups.is-maintenance-group"
                              ),
                              value: "maintenance"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showPaintColumn
                        ? _c("v-radio", {
                            attrs: {
                              label: _vm.$t("countsheetgroups.is-paint-group"),
                              value: "paint"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showInsulationColumn
                        ? _c("v-radio", {
                            attrs: {
                              label: _vm.$t(
                                "countsheetgroups.is-insulation-group"
                              ),
                              value: "insulation"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                "max-height": "calc(100vh - 225px)",
                overflow: "auto"
              }
            },
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "fd-count-sheet-group-panel-summary" },
                    [
                      _c(
                        "v-expansion-panel-header",
                        { staticStyle: { "padding-left": "54px !important" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("countsheet.dialog.summary-panel-title")
                              )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c(
                    "draggable",
                    {
                      staticStyle: { "border-radius": "0" },
                      attrs: {
                        tag: "v-expansion-panels",
                        group: "groups",
                        "component-data": {
                          props: {
                            multiple: true,
                            accordion: true,
                            value: _vm.openPanels
                          }
                        },
                        handle: ".fd-group-icon",
                        "ghost-class": "ghost"
                      },
                      on: { end: _vm.groupDragEnded },
                      model: {
                        value: _vm.countSheetGroups,
                        callback: function($$v) {
                          _vm.countSheetGroups = $$v
                        },
                        expression: "countSheetGroups"
                      }
                    },
                    _vm._l(_vm.countSheetGroups, function(group, index) {
                      return _c(
                        "v-expansion-panel",
                        {
                          key: index,
                          staticClass:
                            "fd-count-sheet-group-panel fd-preview-count-sheet-group-panel"
                        },
                        [
                          _c(
                            "v-expansion-panel-header",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "pa-0 pr-6 ml-n2 fd-group-icon",
                                  attrs: { small: "" }
                                },
                                [_vm._v("far fa-grip-lines")]
                              ),
                              _vm._v(_vm._s(group.name))
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              !group.parts || group.parts.length == 0
                                ? _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticStyle: {
                                                "justify-content": "center"
                                              }
                                            },
                                            [
                                              _c("fd-alert", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "countsheet.dialog.group-has-no-parts-message"
                                                  ),
                                                  type: "hint"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    group: "parts",
                                    "ghost-class": "ghost",
                                    handle: ".fd-group-part-icon"
                                  },
                                  on: {
                                    remove: e => _vm.partRemoved(e, group),
                                    add: e => _vm.partAdded(e, group),
                                    update: e => _vm.partMovedInList(e, group)
                                  },
                                  model: {
                                    value: group.parts,
                                    callback: function($$v) {
                                      _vm.$set(group, "parts", $$v)
                                    },
                                    expression: "group.parts"
                                  }
                                },
                                _vm._l(group.parts, function(part) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: part.id,
                                      staticClass: "fd-part-row",
                                      attrs: { "no-gutters": "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "pa-0 pr-6 fd-group-part-icon",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v("far fa-grip-lines")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fd-group-part-content"
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-1",
                                                  attrs: { cols: "6", sm: "6" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "0.85rem"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            part.publicID
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "0.75rem",
                                                        color: "rgba(0,0,0,0.6)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            part.description
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pl-0 py-1",
                                                  attrs: { cols: "6", sm: "6" }
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pl-1 order-3 order-sm-1",
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "3"
                                                          }
                                                        },
                                                        [
                                                          _c("fd-text-field", {
                                                            staticClass:
                                                              "fd-count-sheet-text-field",
                                                            attrs: {
                                                              dense: "",
                                                              value: "0",
                                                              "hide-details":
                                                                "",
                                                              label: _vm.$t(
                                                                "countsheet.dialog.assigned"
                                                              ),
                                                              disabled: "",
                                                              readonly: true
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pl-1 order-1 order-sm-2",
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "3"
                                                          }
                                                        },
                                                        [
                                                          _c("fd-text-field", {
                                                            staticClass:
                                                              "fd-count-sheet-text-field",
                                                            attrs: {
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              disabled: "",
                                                              label: _vm.$t(
                                                                "countsheet.dialog.add"
                                                              ),
                                                              value: "0"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pl-1 order-2 order-sm-3",
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "3"
                                                          }
                                                        },
                                                        [
                                                          _c("fd-text-field", {
                                                            staticClass:
                                                              "fd-count-sheet-text-field",
                                                            attrs: {
                                                              dense: "",
                                                              value: "0",
                                                              "hide-details":
                                                                "",
                                                              disabled: "",
                                                              label: _vm.$t(
                                                                "countsheet.dialog.remove"
                                                              )
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pl-1 order-4 order-sm-4",
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "3"
                                                          }
                                                        },
                                                        [
                                                          _c("fd-text-field", {
                                                            staticClass:
                                                              "fd-count-sheet-text-field",
                                                            attrs: {
                                                              dense: "",
                                                              value: "0",
                                                              "hide-details":
                                                                "",
                                                              label: _vm.$t(
                                                                "countsheet.dialog.total"
                                                              ),
                                                              disabled: "",
                                                              readonly: true
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "save-draft",
                    text: "",
                    color: "primary",
                    small: _vm.$vuetify.breakpoint.xsOnly,
                    disabled: ""
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("common.save-draft")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-4 mr-3 fd-button-context-separator" },
                [_vm._v("|")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "close",
                    text: "",
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.close")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "submit",
                    color: "primary",
                    small: _vm.$vuetify.breakpoint.xsOnly,
                    disabled: ""
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.submit")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }