import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import ServiceError from "@fd/lib/client-util/serviceError";
import {
  CountSheetGroupWithParts,
  countSheetService,
  workOrderService,
  WorkOrderWithAllDetails,
  CountSheetWithParts,
  CountSheetReviewStatus,
  CountSheetType,
  ScaffoldRequestTypes,
  WorkOrderStatuses,
  Part,
  JobTypes
} from "../../../services";
import { SortCountSheetGroups, SortParts } from "../../../dataMixins/countSheetGroupSorting";
import { mapActions } from "vuex";
import {
  CountSheetGroupPartFromCountSheetPart,
  CountSheetGroupWithSortedParts,
  CountSheetPartFromGroupPart,
  PartWithCounts,
  SummarizeModifiedPartsInGroups
} from "../../../dataMixins/countSheet";

const CountSheetDialog = FDVue.extend({
  name: "fd-count-sheet-dialog",

  mixins: [dialogSupport, errorHandling, rules],

  components: {
    "fd-count-sheet-form": () => import("../forms/CountSheetForm.vue")
  },

  data: function() {
    return {
      countSheetGroups: [] as CountSheetGroupWithSortedParts[],
      workOrder: {} as WorkOrderWithAllDetails,
      scaffoldID: undefined as string | undefined,
      countSheet: undefined as CountSheetWithParts | undefined
    };
  },

  computed: {
    canPickIndividual(): boolean {
      return (
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Modify ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Erect ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Maintenance ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Paint ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Insulation
      );
    },
    canRemoveAll(): boolean {
      return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Dismantle;
    },
    canHaveNoMaterial(): boolean {
      return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Modify;
    },
    canSubmit(): boolean {
      return (
        this.workOrder.workOrderStatus == WorkOrderStatuses.Completed ||
        this.workOrder.workOrderStatus == WorkOrderStatuses.CompletionPendingAdministration
      );
    },
    unwatchedMethodNames(): string[] {
      return [
        "open",
        "fieldRefForPart",
        "focusFieldForVisibleItemAtIndex",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "clearPartCounts"
      ];
    },
    partsForSummary(): PartWithCounts[] {
      return SummarizeModifiedPartsInGroups(this.countSheetGroups);
    },
    countSheetIsEditable(): boolean {
      return (
        this.countSheet?.reviewStatusID == CountSheetReviewStatus.Draft ||
        this.countSheet?.reviewStatusID == CountSheetReviewStatus.Declined
      );
    },
    isScaffoldWorkOrder(): boolean {
      return (
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Erect ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Modify ||
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Dismantle
      );
    },
    isPaintWorkOrder(): boolean {
      return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Paint;
    },
    isInsulationWorkOrder(): boolean {
      return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Insulation;
    },
    isMaintenanceWorkOrder(): boolean {
      return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Maintenance;
    },
    isFireproofingWorkOrder(): boolean {
      return false;
      // return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Fireproofing;
    },
    isRefractoryWorkOrder(): boolean {
      return false;
      // return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Refractory;
    },
    isHeatTraceWorkOrder(): boolean {
      return false;
      // return this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.HeatTrace;
    },
    workOrderJobType(): JobTypes {
      if (this.isMaintenanceWorkOrder) return JobTypes.Maintenance;
      else if (this.isPaintWorkOrder) return JobTypes.Paint;
      else if (this.isInsulationWorkOrder) return JobTypes.Insulation;
      else if (this.isHeatTraceWorkOrder) return JobTypes.HeatTrace;
      else if (this.isRefractoryWorkOrder) return JobTypes.Refractory;
      else if (this.isFireproofingWorkOrder) return JobTypes.Fireproofing;
      else return JobTypes.Scaffold;
    }
  },

  methods: {
    async loadData() {
      this.processing = true;
      try {
        await this.loadCountSheetGroups({
          forcedArchivedState: false,
          archivedFromDate: null,
          archivedToDate: null
        });
        this.countSheet = await countSheetService.getByWorkOrderID(this.workOrder.id!);

        let countSheetGroups = SortCountSheetGroups(
          (this.$store.state.countSheetGroups.fullList as CountSheetGroupWithParts[])
            .filter(group => !!group.parts?.length)
            .map(
              group =>
                ({
                  ...group,
                  sortedParts: SortParts(
                    group.parts?.map(part =>
                      CountSheetGroupPartFromCountSheetPart(part, this.countSheet)
                    )
                  )
                } as CountSheetGroupWithSortedParts)
            )
        );

        let ungroupedPartsWithDetails = this.countSheet.parts?.filter(x => !x.countSheetGroupName);
        if (!!ungroupedPartsWithDetails?.length) {
          let ungroupedParts = ungroupedPartsWithDetails.map(
            x =>
              ({
                id: x.partID,
                name: x.name,
                description: x.description,
                publicID: x.publicID
              } as Part)
          ) as Part[];
          let ungroupedGroup = {
            name: `${this.$t("common.other")}`,
            order: 999,
            parts: ungroupedParts
          } as CountSheetGroupWithParts;

          let ungroupedGroupWithSortedParts = {
            ...ungroupedGroup,
            sortedParts: SortParts(
              ungroupedGroup.parts?.map(part =>
                CountSheetGroupPartFromCountSheetPart(part, this.countSheet)
              )
            )
          } as CountSheetGroupWithSortedParts;

          countSheetGroups.push(ungroupedGroupWithSortedParts);
        }

        console.log(`loadData`);
        console.log(`\t jobType: ${this.workOrderJobType}`);
        countSheetGroups = countSheetGroups.filter(x => {
          console.log(`\t group: ${x.name}`);
          console.log(
            `\t\t isInsulationWorkOrder: ${
              this.isInsulationWorkOrder
            } && isInsulationGroup: ${x.isInsulationGroup == true}`
          );
          console.log(
            `\t\t isMaintenanceWorkOrder: ${
              this.isMaintenanceWorkOrder
            } && isMaintenanceGroup: ${x.isMaintenanceGroup == true}`
          );
          console.log(
            `\t\t isPaintGroup: ${this.isPaintWorkOrder} && isInsulationGroup: ${x.isPaintGroup ==
              true}`
          );
          console.log(
            `\t\t isScaffoldWorkOrder: ${
              this.isScaffoldWorkOrder
            } && isScaffoldGroup: ${x.isScaffoldGroup == true}`
          );
          return (
            (this.isInsulationWorkOrder && x.isInsulationGroup == true) ||
            (this.isMaintenanceWorkOrder && x.isMaintenanceGroup == true) ||
            (this.isPaintWorkOrder && x.isPaintGroup == true) ||
            (this.isScaffoldWorkOrder && x.isScaffoldGroup == true)
          );
        });
        this.countSheetGroups = countSheetGroups;

        this.countSheetGroups.forEach(x => (x.parts = SortParts(x.parts)));
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
      }
    },

    async open(workOrder: WorkOrderWithAllDetails, scaffoldID: string) {
      this.workOrder = workOrder;
      this.scaffoldID = scaffoldID;

      this.loadData();

      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      // if (!(this.$refs.form as HTMLFormElement).validate()) {
      //   return;
      // }
      this.processing = true;
      try {
        await this.saveCountSheet();
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
      }
    },

    async submitDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      try {
        var saved = await this.saveCountSheet();
        if (saved) {
          var submitted = await this.submitCountSheet();
          if (submitted) this.closeDialog!(true);
        }
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
      }
    },

    async saveCountSheet(): Promise<boolean> {
      if (!this.countSheet) return false;

      var materialNotRequired = this.countSheet.countSheetTypeID == CountSheetType.NotApplicable;
      let countSheet = this.countSheet;
      if (materialNotRequired) {
        this.countSheet.parts = [];
      } else {
        this.countSheet.parts = this.partsForSummary.map(x =>
          CountSheetPartFromGroupPart(x, countSheet)
        );
      }
      await countSheetService.saveCountSheet(this.countSheet.id!, this.countSheet);
      return true;
    },

    async submitCountSheet(): Promise<boolean> {
      if (
        !this.countSheet ||
        (!this.partsForSummary?.length &&
          this.countSheet.countSheetTypeID == CountSheetType.Individual)
      ) {
        this.inlineMessage.message = this.$t(
          "countsheet.dialog.part-selection-required-to-submit-message"
        );
        this.inlineMessage.type = "error";
        return false;
      }

      // Parts for summary always includes rejected rows and rows with assigned totals
      // So we can't rely on that to always tell us if things have a count or not
      // Also, for a submit we can't have any overridden values, so we can ignore override related values
      var addedOrRemovedParts = this.partsForSummary.filter(
        x => x.addCount > 0 || x.removeCount > 0
      );

      if (
        !addedOrRemovedParts.length &&
        this.countSheet.countSheetTypeID == CountSheetType.Individual
      ) {
        this.inlineMessage.message = this.$t(
          "countsheet.dialog.part-selection-required-to-submit-message"
        );
        this.inlineMessage.type = "error";
        return false;
      }

      var updatedCountSheet = await countSheetService.submitCountSheet(this.countSheet.id!);
      this.countSheet.reviewStatusID = updatedCountSheet.reviewStatusID;
      this.countSheet.countSheetTypeID = updatedCountSheet.countSheetTypeID;
      this.workOrder.countSheet = this.countSheet;
      this.workOrder.workOrderStatus = updatedCountSheet.workOrder.workOrderStatus;
      this.workOrder.workOrderStatusName = updatedCountSheet.workOrder.workOrderStatusName;
      this.workOrder.isArchived = updatedCountSheet.workOrder.isArchived;
      this.workOrder.archivedDate = updatedCountSheet.workOrder.archivedDate;
      this.workOrder.completedDate = updatedCountSheet.workOrder.completedDate;
      this.workOrder.progress = updatedCountSheet.workOrder.progress;
      return true;
    },

    ...mapActions({
      loadCountSheetGroups: "LOAD_COUNT_SHEET_GROUPS"
    })
  },

  created: async function() {}
});

export default CountSheetDialog;

export async function showCountSheetDialog(
  workOrder: WorkOrderWithAllDetails,
  scaffoldID: string
): Promise<boolean> {
  let dialog = createDialog(CountSheetDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(workOrder, scaffoldID);
}

