import FDVue from "@fd/lib/vue";
import { mapMutations, mapState } from "vuex";
import { FormatAccessInformation } from "../dataMixins/userAccess";
import { AccessInformation } from "../services";
import { DrawerMenuItem } from "./components/layout/DrawerMenuItem.vue";

type MenuItem = DrawerMenuItem & {
  maxWidthVisible?: number;
};
export default FDVue.extend({
  name: "fd-mobile-more-menu",

  components: {
    "fd-privacy-dialog": () => import("./components/PrivacyDialog.vue"),
    "fd-terms-dialog": () => import("./components/TermsDialog.vue")
  },

  data: function() {
    return {
      sidebarmenuitems: [
        {
          icon: "fas fa-home",
          text: "menu.home",
          to: "/"
        },
        {
          icon: "fa-cog",
          text: "menu.setup.subgroup-title",
          accessCheck: "canConfigureSettings",
          model: false,
          children: [
            {
              text: "menu.setup.configuration",
              icon: "fas fa-map-marker-alt",
              to: "/configuration"
            },
            { text: "menu.setup.projects", icon: "fas fa-project-diagram", to: "/projects" },
            { text: "menu.setup.contractors", icon: "fas fa-people-arrows", to: "/contractors" },
            {
              text: "menu.setup.disciplines",
              icon: "fas fa-user-helmet-safety",
              to: "/disciplines"
            },
            {
              text: "menu.setup.classifications",
              icon: "fas fa-screen-users",
              to: "/classifications"
            },
            { text: "menu.setup.suppliers", icon: "fas fa-store", to: "/suppliers" },
            { text: "menu.setup.catalog", icon: "fas fa-book", to: "/parts" },
            { text: "menu.setup.countsheet", icon: "far fa-abacus", to: "/countsheetgroups" },
            { text: "menu.setup.tags", icon: "fa-search-plus", to: "/tags" },
            { text: "menu.setup.iwp", icon: "fas fa-boxes", to: "/iwps" },
            {
              text: "menu.setup.dropoff-locations",
              icon: "fas fa-location",
              to: "/dropofflocations"
            },
            { text: "menu.setup.yards", icon: "fa-pallet-boxes", to: "/yards" },
            { text: "menu.setup.worktypes", icon: "fa-person-digging", to: "/worktypes" },
            { text: "menu.setup.questions", icon: "fa-clipboard-question", to: "/questions" },
            {
              text: "menu.setup.systems",
              icon: this.$store.getters.icon("systems"),
              to: "/systems"
            },
            {
              text: "menu.setup.test-packages",
              icon: this.$store.getters.icon("test-packages"),
              to: "/testpackages"
            },
            {
              text: "menu.setup.isos",
              icon: this.$store.getters.icon("isos"),
              to: "/isos"
            },
            { text: "menu.setup.people", icon: "fas fa-users", to: "/people" }
          ]
        },
        {
          icon: "fas fa-pen-square",
          text: "menu.scaffold-requests.subgroup-title",
          accessCheck: "canViewRequestsGroup",
          model: false,
          children: [
            {
              text: "menu.scaffold-requests.scaffold-requests",
              icon: "fas fa-pen",
              to: "/scaffoldrequests",
              accessCheck: "canSubmitScaffoldRequests"
            },
            {
              text: "menu.scaffold-requests.scaffold-request-approvals",
              icon: "fas fa-check-circle",
              to: "/scaffoldrequestapprovals",
              accessCheck: "canViewScaffoldRequestApprovals"
            }
          ]
        },
        {
          icon: "fas fa-file-lines",
          text: "menu.work-orders.subgroup-title",
          accessCheck: "canViewWorkOrdersGroup",
          model: false,
          children: [
            {
              text: "menu.work-orders.estimates",
              icon: "fas fa-ruler-combined",
              to: "/estimatedworkorders",
              accessCheck: "canViewWorkOrderEstimates"
            },
            {
              text: "menu.scheduling.scheduler",
              icon: "fas fa-calendar-days",
              to: "/scheduler",
              accessCheck: "canViewWorkOrderSchedule"
            },
            {
              text: "menu.work-orders.to-do-list",
              icon: "fas fa-list-check",
              to: "/todolist",
              accessCheck: "canViewToDoList"
            },
            {
              text: "menu.work-orders.all",
              icon: "fas fa-list",
              to: "/allworkorders",
              accessCheck: "canViewToDoList"
            }
          ]
        },
        {
          icon: "fa-box",
          "sub-menu-icon": "mdi-chevron-up",
          "sub-menu-icon-alt": "mdi-chevron-down",
          text: "menu.material.subgroup-title",
          accessCheck: "canViewMaterialGroup",
          cy: "materialgroup",
          model: false,
          children: [
            {
              icon: "fas fa-memo-circle-check",
              text: "menu.material.admin",
              to: "/countsheetadministration",
              accessCheck: "canViewMaterialApproval"
            },
            {
              icon: "fas fa-box-check",
              text: "menu.material.countsheets",
              to: "/countsheets",
              accessCheck: "canViewMaterialApproval"
            },
            {
              icon: "fas fa-exchange",
              text: "menu.material.transfers",
              to: "/transfers",
              accessCheck: "canViewTransfers"
            },
            {
              icon: "fas fa-truck",
              text: "menu.material.material-orders",
              to: "/materialorders",
              accessCheck: "canViewMaterialOrders",
              moduleCheck: "enableMaterialManagement"
            },
            {
              icon: "fas fa-square-poll-vertical",
              text: "menu.material.yard-balances-more-menu",
              to: "/yardbalances",
              accessCheck: "canViewMaterialApproval"
            }
          ]
        },
        {
          icon: "fa-user-hard-hat",
          "sub-menu-icon": "mdi-chevron-up",
          "sub-menu-icon-alt": "mdi-chevron-down",
          text: "menu.labour.subgroup-title",
          accessCheck: "canViewLabourGroup",
          moduleCheck: "enableTimesheets",
          cy: "labourgroup",
          model: false,
          children: [
            {
              icon: "fas fa-clock",
              text: "menu.labour.timesheets",
              to: "/foremantimesheets",
              accessCheck: "canViewTimesheets"
            },
            {
              icon: "fas fa-calendar-check",
              text: "menu.labour.timesheet-approval",
              to: "/timesheetapproval",
              accessCheck: "canViewTimesheets"
            },
            {
              icon: "fas fa-highlighter-line",
              text: "menu.labour.timesheet-corrections",
              to: "/timesheetcorrections",
              accessCheck: "canViewTimesheetApprovals"
            },
            {
              icon: "fas fa-table",
              text: "menu.labour.lems-more-menu",
              to: "/lems",
              accessCheck: "canViewLEMs",
              cy: "lems"
            },
            {
              icon: "fas fa-sigma",
              text: "menu.labour.timesheet-summary",
              to: "/timesheetsummary",
              accessCheck: "canViewTimesheets"
            },
            {
              icon: "fas fa-table-rows",
              text: "menu.labour.timesheet-weekending-summary",
              to: "/timesheetweekendingsummary",
              accessCheck: "canViewTimesheets"
            },
            {
              icon: "fas fa-people-arrows",
              text: "menu.labour.contractors",
              to: "/contractors",
              accessCheck: "canConfigureContractorEmployees"
            }
          ]
        },
        {
          icon: this.$store.getters.icon("inspections"),
          text: "menu.inspections.inspections",
          to: "/inspections",
          accessCheck: "canViewScaffoldInspections",
          moduleCheck: "enableScaffoldInspection"
        },
        {
          icon: `${this.$store.getters.icon("scaffolds")}`,
          text: "menu.scaffolds.scaffolds",
          to: "/scaffolds",
          accessCheck: "canViewScaffolds"
        },
        {
          icon: "fa-screwdriver-wrench",
          "sub-menu-icon": "mdi-chevron-up",
          "sub-menu-icon-alt": "mdi-chevron-down",
          text: "menu.jobs.subgroup-title",
          model: false,
          children: [
            {
              icon: "fas fa-compass-drafting",
              text: "menu.scaffold-designs",
              to: "/scaffolddesigns",
              accessCheck: "canViewScaffoldDesigns",
              moduleCheck: "enableScaffoldDesign"
            },
            {
              icon: `${this.$store.getters.icon("scaffold-jobs")}`,
              text: "menu.jobs.scaffold",
              to: "/scaffold",
              accessCheck: "canViewScaffolds"
            },
            {
              icon: "fas fa-brush",
              text: "menu.jobs.paint",
              to: "/paint",
              moduleCheck: "enablePaintWork",
              accessCheck: "canViewPaintJobs"
            },
            {
              icon: "fas fa-circle-dot",
              text: "menu.jobs.insulation",
              to: "/insulation",
              moduleCheck: "enableInsulationWork",
              accessCheck: "canViewInsulationJobs"
            },
            {
              icon: "fas fa-toolbox",
              text: "menu.jobs.maintenance",
              to: "/maintenance",
              moduleCheck: "enableMaintenanceWork",
              accessCheck: "canViewMaintenanceJobs"
            }
          ]
        }
      ] as MenuItem[]
    };
  },

  computed: {
    ...mapState(["barColor", "barImage", "avatarInitials", "curUserID"]),

    curUserAccess(): AccessInformation {
      return FormatAccessInformation(this.$store.state.curUserAccess as AccessInformation);
    },

    accessibleSidebarMenuItems(): MenuItem[] {
      let userAccessibleItems = this.sidebarmenuitems.filter(
        x => !x.accessCheck?.length || (this.curUserAccess as any)[x.accessCheck] == true
      );

      let systemAccessibleItems = userAccessibleItems.filter(
        x => !x?.moduleCheck?.length || this.$store.state.curEnvironment[x.moduleCheck] == true
      );

      return systemAccessibleItems;
    },

    dialogPrivacy: {
      get(): boolean {
        return this.$store.state.dialogPrivacy;
      },
      set(val: boolean) {
        this.$store.commit("SET_PRIVACY_DIALOG", val);
      }
    },
    dialogTerms: {
      get(): boolean {
        return this.$store.state.dialogTerms;
      },
      set(val: boolean) {
        this.$store.commit("SET_TERMS_DIALOG", val);
      }
    }
  },

  methods: {
    ...mapMutations({
      setPrivacyDialog: "SET_PRIVACY_DIALOG",
      setTermsDialog: "SET_TERMS_DIALOG"
    }),
    menuItemVisible(item: MenuItem) {
      return (
        !item.maxWidthVisible ||
        item.maxWidthVisible == 0 ||
        item.maxWidthVisible > this.$vuetify.breakpoint.width
      );
    },

    accessibleChildrenForMenuItem(item: any) {
      let userAccessibleChildren = item?.children?.filter(
        (x: any) => !x?.accessCheck?.length || (this.curUserAccess as any)[x.accessCheck] == true
      );

      let systemAccessibleChildren = userAccessibleChildren?.filter(
        (x: any) =>
          !x?.moduleCheck?.length || this.$store.state.curEnvironment[x.moduleCheck] == true
      );

      let widthVisibleChildren = systemAccessibleChildren?.filter((x: any) =>
        this.menuItemVisible(x)
      );

      return widthVisibleChildren;
    }
  }
});

