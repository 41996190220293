var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "px-0 mx-0" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t("Work Category"),
                      items: _vm.workCategories,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.workCategoryID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:workCategoryID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("Work Category"),
                      value: _vm.$lookup.workCategory(_vm.workCategoryID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("fd-text-field", {
                staticClass: "mb-3",
                attrs: {
                  disabled: _vm.processing,
                  readonly: !_vm.canEditBuildSheet,
                  label: _vm.$t("Equipment Number"),
                  value: _vm.equipmentNumber,
                  "hide-details": ""
                },
                on: { input: v => _vm.$emit("update:equipmentNumber", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t("Paint Code"),
                      items: _vm.paintCodes,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.paintCodeID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:paintCodeID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("Paint Code"),
                      value: _vm.$lookup.paintCode(_vm.paintCodeID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                {},
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      _c("fd-text-field", {
                        staticClass: "mb-3",
                        attrs: {
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog-paint.diameter"
                          ),
                          value: _vm.diameter,
                          "hide-details": ""
                        },
                        on: { input: v => _vm.$emit("update:diameter", v) }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      _c("fd-text-field", {
                        staticClass: "mb-3",
                        attrs: {
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog-paint.sf2"
                          ),
                          value: _vm.squareFeet,
                          "hide-details": ""
                        },
                        on: { input: v => _vm.$emit("update:squareFeet", v) }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      _c("fd-text-field", {
                        staticClass: "mb-3",
                        attrs: {
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog-paint.linear-feet"
                          ),
                          value: _vm.linearFeet,
                          "hide-details": ""
                        },
                        on: { input: v => _vm.$emit("update:linearFeet", v) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                {},
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      !!_vm.canEditBuildSheet
                        ? _c("v-select", {
                            attrs: {
                              outlined: "",
                              disabled: _vm.processing,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog-paint.set-up-type"
                              ),
                              items: _vm.setUpTypes,
                              "item-text": "name",
                              "item-value": "id",
                              value: _vm.setUpTypeID,
                              "hide-details": ""
                            },
                            on: {
                              input: v => _vm.$emit("update:setUpTypeID", v)
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog-paint.set-up-type"
                              ),
                              value: _vm.$lookup.setUpType(_vm.setUpTypeID),
                              "hide-details": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      !!_vm.canEditBuildSheet
                        ? _c("v-select", {
                            attrs: {
                              outlined: "",
                              disabled: _vm.processing,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog-paint.application-type"
                              ),
                              items: _vm.applicationTypes,
                              "item-text": "name",
                              "item-value": "id",
                              value: _vm.applicationTypeID,
                              "hide-details": ""
                            },
                            on: {
                              input: v =>
                                _vm.$emit("update:applicationTypeID", v)
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog-paint.application-type"
                              ),
                              value: _vm.$lookup.applicationType(
                                _vm.applicationTypeID
                              ),
                              "hide-details": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "pa-0 fd-build-sheet-padding-margin-removal-switch"
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Prep", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.prep,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "prep", $$v)
                          },
                          expression: "paintreason.prep"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Touch Up", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.touchup,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "touchup", $$v)
                          },
                          expression: "paintreason.touchup"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Prime", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.prime,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "prime", $$v)
                          },
                          expression: "paintreason.prime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Damage", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.damage,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "damage", $$v)
                          },
                          expression: "paintreason.damage"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Paint", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.paint,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "paint", $$v)
                          },
                          expression: "paintreason.paint"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Rework", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.rework,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "rework", $$v)
                          },
                          expression: "paintreason.rework"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t("norms.work-order-entry-dialog-paint.tsa"),
                      items: _vm.tsas,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.tsaID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:tsaID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("norms.work-order-entry-dialog-paint.tsa"),
                      value: _vm.$lookup.tsa(_vm.tsaID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "fd-build-sheet-margin-removal-radio-group",
                  attrs: { dense: "" }
                },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "", "hide-details": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c("div", { staticClass: "ml-2" }, [
                                _c("strong", [_vm._v("Select Coat #")])
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.coatNumberValue,
                        callback: function($$v) {
                          _vm.coatNumberValue = $$v
                        },
                        expression: "coatNumberValue"
                      }
                    },
                    [
                      _vm._v(" "),
                      _c("v-radio", {
                        staticClass: "pl-5",
                        attrs: { label: "1", value: 1 }
                      }),
                      _vm._v(" "),
                      _c("v-radio", {
                        staticClass: "pl-5",
                        attrs: { label: "2", value: 2 }
                      }),
                      _vm._v(" "),
                      _c("v-radio", {
                        staticClass: "pl-5",
                        attrs: { label: "3", value: 3 }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c("fd-text-field", {
                staticClass: "mb-3",
                attrs: {
                  disabled: _vm.processing,
                  readonly: !_vm.canEditBuildSheet,
                  label: _vm.$t("norms.work-order-entry-dialog.notes"),
                  value: _vm.notes,
                  "hide-details": ""
                },
                on: { input: v => _vm.$emit("update:notes", v) }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("sp-build-sheet-norm-form", {
        attrs: {
          processing: _vm.processing,
          normGroupsWithResponses: _vm.normGroupsWithResponses,
          canEditBuildSheetResponses: _vm.canEditBuildSheetResponses
        },
        on: {
          "update:normGroupsWithResponses": function($event) {
            _vm.normGroupsWithResponses = $event
          },
          "update:norm-groups-with-responses": function($event) {
            _vm.normGroupsWithResponses = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }