import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import { TranslateResult } from "vue-i18n";
import ServiceError from "@fd/lib/client-util/serviceError";
import {
  ParseWorkOrderWithAllDetails,
  WorkForScaffoldDetailsFromWorkOrder,
  WorkOrderWithExtraDetails
} from "../../dataMixins/workOrderList";
import { ParseScaffoldRequestWithExtraDetails } from "../../dataMixins/scaffoldRequest";
import {
  contractorService,
  ContractorWithTags,
  scaffoldRequestService,
  ScaffoldRequestTypes,
  SummarizedScaffoldRequestPermissions,
  SummarizedWorkOrderPermissions,
  WalkdownStatuses,
  WalkdownWithRequestDetails,
  workOrderService,
  WorkOrderStatuses,
  workOrderStatusHelper,
  WorkOrderStatusDetails,
  WorkOrderWithAllDetails
} from "../../services";
import { WorkOrderDetails } from "./WorkOrderDetailsForm.vue";
import rules from "@fd/lib/vue/rules";
import { showAdditionalDetailsBottomDialog } from "../../../../common/client/views/components/AdditionalDetailsBottomDialog.vue";
import { WorkForScaffoldDetailsFromScaffoldRequest } from "../../dataMixins/scaffoldRequestList";
import { showRequestApprovalBottomDialog } from "./dialogs/SP.RequestApprovalBottomDialog.vue";

export type ScaffoldWorkType = "request" | "workorder";
export type WorkForScaffoldDetails = WorkOrderDetails & {
  id: string | null | undefined;

  workType: ScaffoldWorkType;
  formattedRequiredDate: string | null | undefined;
  walkdown: WalkdownWithRequestDetails;
  requestType: ScaffoldRequestTypes | undefined;
  workOrderId: string | null | undefined;
  scaffoldRequestId: string | null | undefined;
  displayIdentifier: string | null | undefined;

  assignedContractorName: string | null | undefined;
  coordinatorName: string | null | undefined;
  generalForemanName: string | null | undefined;
  foremanName: string | null | undefined;
  workOrderStatusName: string | null | undefined;
  requestTypeName: string | null | undefined;
  internalNumber: number | undefined;
  workOrderStatus: WorkOrderStatuses | undefined;
  requiredDate: Date | null | undefined;
  priority: number | undefined;
  isUrgent: boolean | undefined;

  archivedDate: Date | null | undefined;
  isArchived: boolean | undefined;

  currentUserRequestPermissions: SummarizedScaffoldRequestPermissions | undefined;
  currentUserWorkOrderPermissions: SummarizedWorkOrderPermissions | undefined;
};

const ActiveWorkForScaffoldDialog = FDVue.extend({
  name: "fd-active-work-for-scaffold-dialog",

  mixins: [dialogSupport, rules],

  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-work-order-details": () => import("./WorkOrderDetailsForm.vue")
  },

  props: {
    cy: { type: String, default: "fd-active-work-order-dialog" }
  },

  data: function() {
    return {
      tablesearch: "",
      expanded: [] as any[],

      cancelling: false,
      approving: false,
      declining: false,
      didMakeChange: false,

      startingDismantleWorkOrder: false,
      approvingDismantleRequest: false,
      scaffoldNumber: undefined as number | null | undefined,
      scaffoldWorkList: [] as WorkForScaffoldDetails[],
      currentWorkItemId: undefined as string | null | undefined,

      // Loads as needed when approving/declining/cancelling a request or WO.
      allContractors: [] as ContractorWithTags[],
      allWorkOrderStatuses: [] as WorkOrderStatusDetails[]
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return [
        "itemRowClass",
        "itemIsDismantle",
        "itemIsCurrent",
        "customSort",
        "itemCanBeCancelled",
        "requestCanBeApproved",
        "requestCanBeDeclined",
        "requestIsDeclined",
        "workOrderIsOnHold",
        "workOrderIsCancelled",
        "workOrderIsInScheduling",
        "workOrderIsStarted"
      ];
    },
    expanderColSpan(): number {
      let colCount = 11;
      if (this.$vuetify.breakpoint.lgAndUp) {
        colCount = 11;
      } else if (this.$vuetify.breakpoint.mdOnly) {
        colCount = 10;
      } else if (this.$vuetify.breakpoint.smOnly) {
        colCount = 7;
      } else if (this.$vuetify.breakpoint.xsOnly) {
        colCount = 3;
      } else {
        colCount = 11;
      }
      // Add one to the number of columns to include the expander column
      return colCount + 1;
    },
    hideTableFooter(): boolean {
      return this.scaffoldWorkList.length <= 10;
    },
    dismantleItems(): WorkForScaffoldDetails[] {
      var dismantleItems = this.scaffoldWorkList.filter(
        x => x.requestType == ScaffoldRequestTypes.Dismantle
      );
      return dismantleItems;
    },
    hasMultipleDismantleWorkItems(): boolean {
      return this.dismantleItems.length > 1;
    },
    dismantleRequests(): WorkForScaffoldDetails[] {
      return this.dismantleItems.filter(x => x.workType == "request");
    },
    dismantleWorkOrders(): WorkForScaffoldDetails[] {
      return this.dismantleItems.filter(x => x.workType == "workorder");
    },

    nonDismantleItems(): WorkForScaffoldDetails[] {
      var dismantleItems = this.scaffoldWorkList.filter(
        x => x.requestType != ScaffoldRequestTypes.Dismantle
      );
      return dismantleItems;
    },
    nonDismantleRequests(): WorkForScaffoldDetails[] {
      return this.nonDismantleItems.filter(x => x.workType == "request");
    },
    nonDismantleWorkOrders(): WorkForScaffoldDetails[] {
      return this.nonDismantleItems.filter(x => x.workType == "workorder");
    },

    // Alert Messages & Conflict Logic
    approveRequestWithOtherDismantleItemsMessage(): string | TranslateResult {
      var requests = this.dismantleRequests;
      var workOrders = this.dismantleWorkOrders;

      if (requests.length > 0 && workOrders.length > 0) {
        return this.$t(
          "scaffolds.work-summary.approve-dismantle-request-existing-requests-and-work-orders-explanation"
        );
      } else if (requests.length > 0) {
        return this.$t(
          "scaffolds.work-summary.approve-dismantle-request-existing-requests-explanation"
        );
      } else if (workOrders.length > 0) {
        return this.$t(
          "scaffolds.work-summary.approve-dismantle-request-existing-work-orders-explanation"
        );
      }

      return "";
    },
    startDismantleWorkOrderWithOtherActiveItemsMessage(): string | TranslateResult {
      var requests = this.nonDismantleRequests;
      var workOrders = this.nonDismantleWorkOrders;

      if (requests.length > 0 && workOrders.length > 0) {
        return this.$t(
          "scaffolds.work-summary.start-dismantle-work-order-existing-requests-and-work-orders-explanation"
        );
      } else if (requests.length > 0) {
        return this.$t(
          "scaffolds.work-summary.start-dismantle-work-order-existing-requests-explanation"
        );
      } else if (workOrders.length > 0) {
        return this.$t(
          "scaffolds.work-summary.start-dismantle-work-order-existing-work-orders-explanation"
        );
      }

      return "";
    },
    multipleDismantleWorkItemsMessage(): string | TranslateResult {
      var requests = this.dismantleRequests;
      var workOrders = this.dismantleWorkOrders;

      if (requests.length > 0 && workOrders.length > 0) {
        return this.$t(
          "scaffolds.work-summary.conflict-multiple-dismantle-requests-and-work-orders"
        );
      } else if (requests.length > 0) {
        return this.$t("scaffolds.work-summary.conflict-multiple-dismantle-requests");
      } else if (workOrders.length > 0) {
        return this.$t("scaffolds.work-summary.conflict-multiple-dismantle-work-orders");
      }

      return "";
    },
    hasActiveDismantleWithOtherWork(): boolean {
      var otherItems = this.scaffoldWorkList.filter(
        x => x.requestType != ScaffoldRequestTypes.Dismantle
      );
      return this.dismantleItems.length > 0 && otherItems.length > 0;
    },
    hasConflict(): boolean {
      return this.hasMultipleDismantleWorkItems || this.hasActiveDismantleWithOtherWork;
    },
    isConfirmingAction(): boolean {
      return this.startingDismantleWorkOrder || this.approvingDismantleRequest;
    }
  },

  methods: {
    async openWithData(
      scaffoldNumber: number | null | undefined,
      preLoadedWorkList: WorkForScaffoldDetails[],
      currentWorkItemId: string | null | undefined,
      startingDismantleWorkOrder: boolean,
      approvingDismantleRequest: boolean,
      skipDataLoad: boolean
    ) {
      this.startingDismantleWorkOrder = startingDismantleWorkOrder;
      this.approvingDismantleRequest = approvingDismantleRequest;
      this.scaffoldNumber = scaffoldNumber;
      this.scaffoldWorkList = preLoadedWorkList;
      this.currentWorkItemId = currentWorkItemId;

      this.optOutOfErrorHandling();

      // We specifically don't wait for this load to finish so that the dialog opens
      // Since processing is true anyway, it has no impact on the usability of the dialog to open while loading
      if (!skipDataLoad) this.loadScaffoldWork();

      return await this.showDialog!();
    },

    async loadScaffoldWork() {
      if (!this.scaffoldNumber) return;
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        var allRequests = (
          await scaffoldRequestService.getSubmittedRequestsForScaffoldTag(this.scaffoldNumber)
        ).map(x => ParseScaffoldRequestWithExtraDetails(x));
        var convertedRequests = allRequests.map(x => WorkForScaffoldDetailsFromScaffoldRequest(x));

        var allWorkOrders = (
          await workOrderService.getActiveWorkOrdersForScaffoldTag(null, this.scaffoldNumber)
        ).map(x => ParseWorkOrderWithAllDetails(x));
        let convertedWorkOrders = allWorkOrders.map(x => WorkForScaffoldDetailsFromWorkOrder(x));

        var allScaffoldWork: WorkForScaffoldDetails[] = convertedRequests.concat(
          convertedWorkOrders
        );
        this.scaffoldWorkList = allScaffoldWork;
      } catch (error) {
      } finally {
        this.processing = currentProcessing;
      }
    },

    // Called when cancelling the dialog WHEN CONFIRMING AN ACTION
    declineDialog() {
      this.closeDialog!(false);
    },

    // Called when accepting the dialog WHEN CONFIRMING AN ACTION
    acceptDialog() {
      this.closeDialog!(true);
    },

    // Called when closing the dialog when NOT CONFIRMING an action
    completeDialog() {
      this.closeDialog!(this.didMakeChange);
    },

    // *** UI/Style Checks ***
    itemRowClass(item: WorkForScaffoldDetails): string[] {
      var classes = [] as string[];
      if (this.itemIsCurrent(item)) classes.push("fd-selected-table-row-background");

      if (item.workType == "request") classes.push("fd-scaffold-request-table-row-background");
      else if (item.workType == "workorder") classes.push("fd-work-order-table-row-background");

      return classes;
    },

    customSort(
      items: WorkForScaffoldDetails[],
      sortBy: string[],
      sortDesc: boolean[],
      locale: string
    ) {
      let desc = sortDesc[0] == true;
      let propName = sortBy[0];
      // Examine the property trying to be sorted, and switch it to the ACTUAL property name of the object
      // This prop name is the name of the item in the table, and therefore sometimes won't match the object property.
      if (propName === "formattedRequiredDate") {
        propName = "requiredDate";
      } else if (propName === "generalForeman") {
        propName = "generalForemanName";
      } else if (propName === "foreman") {
        propName = "foremanName";
      } else if (propName === "workOrderStatusName") {
        propName = "workOrderStatus";
      }
      items.sort((a: WorkForScaffoldDetails, b: WorkForScaffoldDetails) => {
        // No matter how we sort, we want the current WO at the top
        if (this.itemIsCurrent(a)) return -1;
        else if (this.itemIsCurrent(b)) return 1;

        let val1 = (a as any)[propName];
        let val2 = (b as any)[propName];
        if (propName == "priority") {
          val1 = a.isUrgent ? 0 : val1;
          val2 = b.isUrgent ? 0 : val2;
        }
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });

      return items;
    },

    // *** STATUS & TYPE COMPARISONS ***
    itemIsDismantle(item: WorkForScaffoldDetails): boolean {
      console.log(`itemIsDismantle ${item.internalNumber} requestType: ${item.requestType}`);
      return item.requestType == ScaffoldRequestTypes.Dismantle;
    },
    itemIsCurrent(item: WorkForScaffoldDetails): boolean {
      let currentID = this.currentWorkItemId ?? "";
      return item.workOrderId == currentID || item.scaffoldRequestId == currentID;
    },
    requestIsDeclined(request: WorkForScaffoldDetails): boolean {
      return (
        request.workOrderStatus == WorkOrderStatuses.Declined ||
        (!!request.walkdown && request.walkdown.walkdownStatus == WalkdownStatuses.Declined)
      );
    },
    workOrderIsOnHold(workOrder: WorkForScaffoldDetails): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.OnHold;
    },
    workOrderIsCancelled(workOrder: WorkForScaffoldDetails): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.Cancelled;
    },
    workOrderIsInScheduling(workOrder: WorkForScaffoldDetails): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.InScheduling;
    },
    workOrderIsStarted(workOrder: WorkForScaffoldDetails): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.Started;
    },

    // *** PERMISSION CHECKS ***
    itemCanBeCancelled(item: WorkForScaffoldDetails): boolean {
      return (
        (item.workType == "request" && !!item.currentUserRequestPermissions?.canCancel) ||
        (item.workType == "workorder" && !!item.currentUserWorkOrderPermissions?.canCancel)
      );
    },
    requestCanBeApproved(item: WorkForScaffoldDetails): boolean {
      return (
        item.workOrderStatus == WorkOrderStatuses.Submitted &&
        item.workType == "request" &&
        !!item.currentUserRequestPermissions?.canUpdateApproval
      );
    },
    requestCanBeDeclined(item: WorkForScaffoldDetails): boolean {
      return (
        item.workOrderStatus == WorkOrderStatuses.Submitted &&
        item.workType == "request" &&
        !!item.currentUserRequestPermissions?.canUpdateApproval
      );
    },

    /*** ACTIONS ***/
    // Approve
    async approveRequest(request: WorkForScaffoldDetails) {
      if (!request.currentUserRequestPermissions?.canUpdateApproval) {
        console.log(`user doesn't have permission to update approval`);
        return;
      }

      this.processing = true;
      this.approving = true;
      try {
        if (!this.allContractors?.length) {
          this.allContractors = await contractorService.getAll(false, null, null);
        }
        var assignableContractors = [] as ContractorWithTags[];
        if (
          request.requestType == ScaffoldRequestTypes.Erect ||
          request.requestType == ScaffoldRequestTypes.Dismantle ||
          request.requestType == ScaffoldRequestTypes.Modify
        ) {
          assignableContractors = this.allContractors.filter(x => !!x.isScaffoldCompany);
        } else if (request.requestType == ScaffoldRequestTypes.Paint) {
          assignableContractors = this.allContractors.filter(x => !!x.isPaintCompany);
        } else if (request.requestType == ScaffoldRequestTypes.Insulation) {
          assignableContractors = this.allContractors.filter(x => !!x.isInsulationCompany);
        } else if (request.requestType == ScaffoldRequestTypes.Maintenance) {
          assignableContractors = this.allContractors.filter(x => !!x.isMaintenanceCompany);
        }

        this.expanded = [];
        var requestApprovalDetails = await showRequestApprovalBottomDialog(
          request.requestNumber,
          assignableContractors,
          undefined,
          this.$refs.content as Vue
        );

        // If details is undefined the dialog was cancelled
        if (!requestApprovalDetails?.contractorID?.length) {
          // Change the value to something else and then back to its current to force a rebind
          this.approving = false;
          this.processing = false;
          return false;
        }

        let newWorkOrderID = await scaffoldRequestService.updateScaffoldRequestApproval(
          request.scaffoldRequestId!,
          true,
          requestApprovalDetails.contractorID,
          ""
        );

        if (!!newWorkOrderID && !!requestApprovalDetails?.isUrgent) {
          await workOrderService.updateItem(
            newWorkOrderID,
            {
              id: newWorkOrderID,
              isUrgent: true,
              isUrgentDetail: requestApprovalDetails.isUrgentDetail
            } as WorkOrderWithAllDetails,
            "ActiveWorkForScaffoldDialog.approveRequest - Setting isUrgent"
          );
        }

        this.didMakeChange = true;

        // The request has been approved and has "changed" to a work order
        // Load that work order's info and replace the request in the list
        let loadedWorkOrder = await workOrderService.getWorkOrderByID(newWorkOrderID!);
        let workOrderWithExtraDetails = ParseWorkOrderWithAllDetails(loadedWorkOrder);
        let loadedWorkItem = WorkForScaffoldDetailsFromWorkOrder(workOrderWithExtraDetails);
        this.scaffoldWorkList.splice(this.scaffoldWorkList.indexOf(request), 1);
        this.scaffoldWorkList.push(loadedWorkItem);

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.approve-success", [
            request.requestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },

    // Decline
    async declineRequest(request: WorkForScaffoldDetails) {
      if (!request.currentUserRequestPermissions?.canUpdateApproval) {
        console.log(`user doesn't have permission to update approval`);
        return;
      }

      this.processing = true;
      this.declining = true;
      try {
        // get reason
        this.expanded = [];
        let reason = await showAdditionalDetailsBottomDialog(
          {
            title: this.$t("scaffold-request-approvals.decline-reason"),
            label: this.$t("common.reason"),
            rules: [this.rules.required]
          },
          this.$refs.content as Vue
        );

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.declining = false;
          this.processing = false;
          return false;
        }

        await scaffoldRequestService.updateScaffoldRequestApproval(
          request.scaffoldRequestId!,
          false,
          null,
          reason
        );
        this.didMakeChange = true;

        let loadedItem = await scaffoldRequestService.getByID(request.scaffoldRequestId!);

        request.workOrderStatus = loadedItem.workOrderStatus;
        request.workOrderStatusDetail = loadedItem.scaffoldRequestStatusDetail;

        if (!this.allWorkOrderStatuses?.length) {
          this.allWorkOrderStatuses = await workOrderStatusHelper.getAllWorkOrderStatuses();
        }
        var status = this.allWorkOrderStatuses.find(x => x.value == request.workOrderStatus);
        if (!!status) {
          request.workOrderStatusName = status.displayName;
        } else {
          request.workOrderStatusName = "";
        }

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.decline-success", [
            request.requestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    },

    // Cancel
    async cancelWork(item: WorkForScaffoldDetails) {
      if (item.workType == "request") return await this.cancelRequest(item);
      else if (item.workType == "workorder") return await this.cancelWorkOrder(item);
    },
    async cancelRequest(request: WorkForScaffoldDetails) {
      console.log(`cancelRequest`);
      if (!request.currentUserRequestPermissions?.canCancel) {
        console.log(`user doesn't have permission to cancel`);
        return;
      }

      this.processing = true;
      this.cancelling = true;
      try {
        // get reason
        this.expanded = [];
        let reason = await showAdditionalDetailsBottomDialog(
          {
            title: this.$t("scheduler.cancellation-reason"),
            label: this.$t("common.reason"),
            rules: [this.rules.required]
          },
          this.$refs.content as Vue
        );

        // If details is undefined the dialog was cancelled
        if (!reason?.length) {
          // Change the value to something else and then back to its current to force a rebind
          this.cancelling = false;
          this.processing = false;
          return false;
        }

        await scaffoldRequestService.cancelScaffoldRequest(request.scaffoldRequestId!, reason);
        this.didMakeChange = true;
        let loadedItem = await scaffoldRequestService.getByID(request.scaffoldRequestId!);

        request.workOrderStatus = loadedItem.workOrderStatus;
        request.workOrderStatusDetail = loadedItem.scaffoldRequestStatusDetail;

        if (!this.allWorkOrderStatuses?.length) {
          this.allWorkOrderStatuses = await workOrderStatusHelper.getAllWorkOrderStatuses();
        }
        var status = this.allWorkOrderStatuses.find(x => x.value == request.workOrderStatus);
        if (!!status) {
          request.workOrderStatusName = status.displayName;
        } else {
          request.workOrderStatusName = "";
        }

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.cancel-success", [
            request.requestNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.cancelling = false;
        this.processing = false;
      }
    },
    async cancelWorkOrder(item: WorkForScaffoldDetails) {
      console.log(`cancelWorkOrder`);
      if (!item.currentUserWorkOrderPermissions?.canCancel) {
        console.log(`user doesn't have permission to cancel`);
        return;
      }

      this.cancelling = true;

      let originalValue = item.workOrderStatus;
      this.processing = true;
      this.inlineMessage.message = null;
      try {
        let updatedItem = {
          id: item.workOrderId,
          workOrderStatus: WorkOrderStatuses.Cancelled,
          archivedDate: item.archivedDate
        } as WorkOrderWithExtraDetails;

        this.expanded = [];
        let reason = await showAdditionalDetailsBottomDialog(
          {
            title: this.$t("scheduler.cancellation-reason"),
            label: this.$t("common.reason"),
            rules: [this.rules.required]
          },
          this.$refs.content as Vue
        );
        // If reason is undefined the dialog was cancelled
        if (!reason?.length) {
          this.cancelling = false;
          this.processing = false;
          return false;
        }
        updatedItem.workOrderStatusDetail = reason;

        item.workOrderStatus = WorkOrderStatuses.Cancelled;
        var loadedItem = await workOrderService.updateItem(
          item.workOrderId!,
          updatedItem,
          "ActiveWorkForScaffoldDialog.cancelWorkOrder"
        );
        this.didMakeChange = true;

        // The following fields might have been updated via business logic when doing the update
        // They could be updated via various fields and don't hurt anything to set again based on the loadedItem details
        item.workOrderStatus = loadedItem.workOrderStatus;
        item.workOrderStatusDetail = loadedItem.workOrderStatusDetail;
        item.archivedDate = loadedItem.archivedDate;
        item.progress = loadedItem.progress;
        var utcNow = new Date(
          new Date().toUTCString().substring(0, new Date().toUTCString().length - 4)
        );
        item.isArchived =
          loadedItem.archivedDate != null &&
          loadedItem.archivedDate.getTime() / 60000 <= utcNow.getTime() / 60000;
        if (!this.allWorkOrderStatuses?.length) {
          this.allWorkOrderStatuses = await workOrderStatusHelper.getAllWorkOrderStatuses();
        }
        var status = this.allWorkOrderStatuses.find(x => x.value == item.workOrderStatus);
        if (!!status) {
          item.workOrderStatusName = status.displayName;
        } else {
          item.workOrderStatusName = "";
        }
        let snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.internalNumber]),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        // Change the value to something else and then back to its original value to force a rebind to undo the change the user made
        item.workOrderStatus = undefined;
        this.$nextTick(() => {
          item.workOrderStatus = originalValue;
        });

        // If the error is a 422 (meaning server validation failed) show a snackbar with the appropriate error message
        // If it's anything, handle as normal
        if ((error as any).statusCode == 422) {
          let snackbarPayload = {
            text: this.$t(`error-messages.${(error as ServiceError).message}`, [
              item.internalNumber
            ]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
      } finally {
        this.cancelling = false;
        this.processing = false;
      }
    }
  }
});

export default ActiveWorkForScaffoldDialog;

export async function openActiveWorkForScaffoldDialog(
  scaffoldNumber: number | null | undefined,
  preLoadedWorkList: WorkForScaffoldDetails[],
  currentWorkItemId: string | null | undefined,
  startingDismantleWorkOrder: boolean,
  approvingDismantleRequest: boolean,
  // The new and existing request screens only open the dialog when there's conflicting data, so they pre-load all the work items
  skipDataLoad: boolean
): Promise<boolean> {
  let dialog = createDialog(ActiveWorkForScaffoldDialog);
  return await dialog.openWithData(
    scaffoldNumber,
    preLoadedWorkList,
    currentWorkItemId,
    startingDismantleWorkOrder,
    approvingDismantleRequest,
    skipDataLoad
  );
}

