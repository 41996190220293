var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-card",
            { attrs: { "data-cy": "requestapprovaldialog" } },
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("scaffold-request-approvals.assign-contractor")
                        ) + "\n          "
                      ),
                      !!_vm.internalRequestNumber
                        ? _c("span", [
                            _vm._v("| R-" + _vm._s(_vm.internalRequestNumber))
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-0 pt-1" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pb-1 px-3",
                          attrs: { align: "center", cols: "12" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              "data-cy": "itemselect",
                              label: _vm.$t(
                                "scaffold-request-approvals.contractor-label"
                              ),
                              items: _vm.contractors,
                              "item-text": "name",
                              "item-value": "id",
                              rules: _vm.dialogRules.contractorID,
                              disabled: _vm.processing
                            },
                            model: {
                              value: _vm.contractorID,
                              callback: function($$v) {
                                _vm.contractorID = $$v
                              },
                              expression: "contractorID"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pb-1 mx-0 pt-0",
                          class: {
                            "px-3": !_vm.isUrgent,
                            "px-0": _vm.isUrgent,
                            "fd-alert-container-urgent": _vm.isUrgent,
                            "fd-alert-container": _vm.isUrgent
                          },
                          attrs: { align: "center", cols: "12" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 pt-2 pb-2",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "align-start pb-3",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-slide-x-transition",
                                    [
                                      _vm.isUrgent
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "fd-alert-container-icon pt-3",
                                              staticStyle: {
                                                color: "rgb(0,0,0,0.6)"
                                              }
                                            },
                                            [_vm._v("fa-fire-alt")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-switch", {
                                    class: {
                                      "px-3": _vm.isUrgent,
                                      "pt-1": _vm.isUrgent
                                    },
                                    attrs: {
                                      label: _vm.$t("scheduler.is-urgent"),
                                      "persistent-hint": "",
                                      hint: _vm.$t("scheduler.is-urgent-hint"),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value: _vm.isUrgent,
                                      callback: function($$v) {
                                        _vm.isUrgent = $$v
                                      },
                                      expression: "isUrgent"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expand-transition",
                            [
                              _vm.isUrgent
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "justify-space-between pt-0 px-0 mx-0",
                                      attrs: { align: "center", cols: "12" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        staticClass: "px-3",
                                        staticStyle: { "margin-left": "45px" },
                                        attrs: {
                                          label: _vm.$t(
                                            "scheduler.urgent-reason"
                                          ),
                                          disabled: _vm.processing,
                                          rules: _vm.dialogRules.isUrgentDetail,
                                          rows: "1",
                                          "auto-grow": ""
                                        },
                                        model: {
                                          value: _vm.isUrgentDetail,
                                          callback: function($$v) {
                                            _vm.isUrgentDetail = $$v
                                          },
                                          expression: "isUrgentDetail"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "px-3 pb-3" },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "close",
                        text: "",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "accept",
                        color: "primary",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.acceptDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.save")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }