var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0 fp-map-container", attrs: { fluid: "" } },
    [
      _c(
        "div",
        {
          ref: "mapContainer",
          staticClass: "fd-position-relative",
          style: `height: ${_vm.height};`
        },
        [
          _c(
            "div",
            { staticClass: "fd-map-layer-controls" },
            [
              _c("v-col", { staticClass: "pa-0 ma-0" }, [
                _c(
                  "div",
                  { staticClass: "fd-map-layer-controls-satellite" },
                  [
                    _c(
                      "v-btn",
                      {
                        class: {
                          "v-btn--active": _vm.computedLayerType == "satellite"
                        },
                        attrs: { icon: "", ripple: false },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.computedLayerType = "satellite"
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("mdi-satellite")])],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "fd-map-layer-controls-road" },
                  [
                    _c(
                      "v-btn",
                      {
                        class: {
                          "v-btn--active": _vm.computedLayerType == "roads"
                        },
                        attrs: { icon: "", ripple: false },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.computedLayerType = "roads"
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("mdi-road-variant")])],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }