import FDVue from "@fd/lib/vue";
import {
  FDColumnDirective,
  FDHiddenArgumentName,
  FDRowNavigateDirective
} from "@fd/lib/vue/utility/dataTable";
import { mapActions, mapMutations } from "vuex";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";
import userAccess from "../dataMixins/userAccess";
import {
  contractorService,
  ContractorWithTags,
  jobService,
  JobStatuses,
  JobTypes,
  JobWithData,
  ScaffoldRequestTypes,
  Tag,
  WorkOrder,
  WorkOrderStatuses
} from "../services";
import { filterByTags } from "../services/taggableItems";
import archivedDataList from "../dataMixins/archivedDataList";
import { createNewJob } from "./components/dialogs/SP.JobNewDialog.vue";
import VueI18n, { TranslateResult } from "vue-i18n";
import { contractorDataStore, isoDataStore, projectLocationDataStore } from "../store";
import { showItemSelectionDialog } from "./components/ItemSelectionDialog.vue";
import rules from "@fd/lib/vue/rules";
import { formatWorkOrderNumber } from "../utils/workorder";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import { valueInArray } from "@fd/lib/client-util/array";

export type FormattedJob = JobWithData & {
  formattedInternalNumber: string;
  formattedWorkOrderNumber: string;
  contractorName: string | null | undefined;
  isoName: string | null | undefined;
  areaName: string | null | undefined;
  subAreaName: string | null | undefined;
  latestWorkOrder: WorkOrder | undefined;
  latestWorkOrderStatus: WorkOrderStatuses | undefined;
  latestWorkOrderStatusName: string | TranslateResult | undefined;
  latestWorkOrderStarted: Date | null | undefined;
  latestWorkOrderCompleted: Date | null | undefined;
  latestWorkOrderAssignedContractorID: string | null | undefined;
  latestWorkOrderAssignedContractorName: string | null | undefined;
  archived: boolean;
};
export function FormatJobData(job: JobWithData, i18n: VueI18n): FormattedJob {
  let latestWorkOrder = GetLatestWorkOrderInList(job.relatedWorkOrders);

  return {
    ...job,
    contractorName: contractorDataStore.lookupCaption(job.contractorID ?? null),
    isoName: isoDataStore.lookupCaption(job.isoID ?? null),
    formattedInternalNumber: `00000${job.internalNumber}`.slice(-5),
    formattedWorkOrderNumber: formatWorkOrderNumber(latestWorkOrder?.internalNumber),
    areaName: projectLocationDataStore.lookupCaption(job.areaID ?? null),
    subAreaName: projectLocationDataStore.lookupCaption(job.subAreaID ?? null),
    latestWorkOrderStatus: latestWorkOrder?.workOrderStatus,
    latestWorkOrderStatusName: !!latestWorkOrder?.workOrderStatus
      ? i18n.t(`workorders.status.${latestWorkOrder.workOrderStatus}`)
      : "",
    latestWorkOrder: latestWorkOrder,
    latestWorkOrderStarted: latestWorkOrder?.startDate,
    latestWorkOrderCompleted: latestWorkOrder?.completedDate,
    latestWorkOrderAssignedContractorID: latestWorkOrder?.assignedContractorID,
    latestWorkOrderAssignedContractorName: contractorDataStore.lookupCaption(
      latestWorkOrder?.assignedContractorID ?? null
    ),
    archived: !!job.archivedDate
  };
}
function GetLatestWorkOrderInList(list: WorkOrder[] | undefined): WorkOrder | undefined {
  if (!list?.length) return undefined;
  return list.sort((a, b) => {
    let aCompleted = a.completedDate ?? new Date(2999, 12, 31);
    let bCompleted = b.completedDate ?? new Date(2999, 12, 31);
    if (aCompleted.getTime() != bCompleted.getTime())
      return bCompleted.getTime() - aCompleted.getTime();

    let aStarted = a.startDate ?? new Date(2999, 12, 31);
    let bStarted = b.startDate ?? new Date(2999, 12, 31);
    if (aStarted.getTime() != bStarted.getTime()) return bStarted.getTime() - aStarted.getTime();

    return 0;
  })[0];
}

export default FDVue.extend({
  name: "sp-jobs-list",
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  mixins: [archivedDataList, userAccess, rules],
  data: function() {
    return {
      routeName: "",
      holding: false,
      resuming: false,
      readying: false,
      cancelling: false,
      releasing: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      tablesearch: "",
      allJobs: [] as FormattedJob[],

      allJobStatuses: (Object.keys(JobStatuses)
        .filter(x => !isNaN(Number(x)))
        .map(x => Number(x)) as number[]).map(x => {
        return {
          value: x,
          text: this.$t(`job.statuses.${x}`)
        };
      })
    };
  },
  computed: {
    unwatchedMethodNames(): string[] {
      return ["canReleaseJob"];
    },
    canCreateNewJob(): boolean {
      let jobType = this.jobType;
      let canCreateNewJob = false;
      if (jobType == JobTypes.Scaffold) {
        canCreateNewJob = this.currentUserCanCreateScaffoldJobs;
      } else if (jobType == JobTypes.Paint) {
        canCreateNewJob = this.currentUserCanCreatePaintJobs;
      } else if (jobType == JobTypes.Maintenance) {
        canCreateNewJob = this.currentUserCanCreateMaintenanceJobs;
      } else if (jobType == JobTypes.Insulation) {
        canCreateNewJob = this.currentUserCanCreateInsulationJobs;
      } else if (jobType == JobTypes.HeatTrace) {
        return false;
        canCreateNewJob = this.currentUserCanCreateHeatTraceJobs;
      } else if (jobType == JobTypes.Refractory) {
        return false;
        canCreateNewJob = this.currentUserCanCreateRefractoryJobs;
      } else if (jobType == JobTypes.Fireproofing) {
        return false;
        canCreateNewJob = this.currentUserCanCreateFireproofingJobs;
      }
      return canCreateNewJob;
    },
    anyOnHoldJobs(): boolean {
      let anyOnHoldJobs = this.jobs.findIndex(x => x.jobStatusID == JobStatuses.OnHold) !== -1;
      console.log(
        `anyOnHoldJobs ${anyOnHoldJobs} (${this.jobs.filter(
          x => x.jobStatusID == JobStatuses.OnHold
        )})`
      );
      return anyOnHoldJobs;
    },
    iconColumnArgument(): string {
      return this.anyOnHoldJobs ? "icon" : FDHiddenArgumentName;
    },
    jobType(): JobTypes {
      let jobType = JobTypes.Scaffold;
      if (this.$route.name == "PaintJobs") {
        jobType = JobTypes.Paint;
      } else if (this.$route.name == "MaintenanceJobs") {
        jobType = JobTypes.Maintenance;
      } else if (this.$route.name == "InsulationJobs") {
        jobType = JobTypes.Insulation;
      } else if (this.$route.name == "HeatTraceJobs") {
        jobType = JobTypes.HeatTrace;
      } else if (this.$route.name == "RefractoryJobs") {
        jobType = JobTypes.Refractory;
      } else if (this.$route.name == "FireproofingJobs") {
        jobType = JobTypes.Fireproofing;
      }
      return jobType;
    },
    jobTypeName(): string {
      let jobType = this.jobType;
      let jobTypeName = "scaffold";
      if (jobType == JobTypes.Paint) {
        jobTypeName = "paint";
      } else if (jobType == JobTypes.Maintenance) {
        jobTypeName = "maintenance";
      } else if (jobType == JobTypes.Insulation) {
        jobTypeName = "insulation";
      } else if (jobType == JobTypes.HeatTrace) {
        jobTypeName = "heattrace";
      } else if (jobType == JobTypes.Refractory) {
        jobTypeName = "refractory";
      } else if (jobType == JobTypes.Fireproofing) {
        jobTypeName = "fireproofing";
      }
      return jobTypeName;
    },
    title(): TranslateResult | string {
      return this.$t(`job.${this.jobTypeName}.list.title`);
    },
    jobs(): FormattedJob[] {
      let jobs = filterByTags(this.tagsSelectedForFiltering, this.allJobs);
      if (this.selectedStatuses.length) {
        jobs = jobs.filter(x => valueInArray(x.jobStatusID, this.selectedStatuses));
      }
      return jobs;
    },
    tagsInUse(): Tag[] {
      return this.$store.getters.getSortedInUseTags(this.allJobs);
    },

    tagsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    },

    selectableFilterStatuses(): any[] {
      return this.allJobStatuses.map(x => {
        return {
          text: x.text,
          value: x.value,
          count: this.allJobs.filter(wo => wo.jobStatusID == x.value).length
        };
      });
    },

    selectedStatuses: {
      get(): number[] {
        var selectedStatuses = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.statusesForFiltering as number[];
        return selectedStatuses;
      },
      set(val: number[]) {
        this.$store.commit("SET_STATUSES_FOR_FILTERING", val);
      }
    }
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    navigate(item: FormattedJob) {
      this.$router.push(`/${this.jobTypeName}/${item.id}`);
    },
    async openNewJobDialog() {
      if (await createNewJob(this.jobType)) {
        this.reloadTableData();
      }
    },
    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }
      this.allJobs = (
        await jobService.getJobListForType(
          this.jobType,
          false,
          this.showArchived,
          this.showArchivedFromDate,
          this.showArchivedToDate
        )
      ).map(x => FormatJobData(x, this.$i18n));
      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    jobIsOnHold(job: { id: string | null | undefined; jobStatusID: JobStatuses | undefined }) {
      return job.jobStatusID == JobStatuses.OnHold;
    },
    jobStatusCanBeSetToDraft(job: FormattedJob) {
      return job.jobStatusID == JobStatuses.OnHold;
    },
    jobStatusCanBeSetToOnHold(job: FormattedJob) {
      return !job.jobStatusID;
    },
    jobStatusCanBeSetToReady(job: FormattedJob) {
      return !job.jobStatusID;
    },
    jobStatusCanBeSetToReleased(job: FormattedJob) {
      return !job.jobStatusID || job.jobStatusID == JobStatuses.Ready;
    },
    canReleaseJob(item: JobWithData): boolean {
      let jobType = item.jobTypeID;
      let canReleaseJob = false;
      if (jobType == JobTypes.Scaffold) {
        canReleaseJob = this.currentUserCanReleaseScaffoldJobs;
      } else if (jobType == JobTypes.Paint) {
        canReleaseJob = this.currentUserCanReleasePaintJobs;
      } else if (jobType == JobTypes.Maintenance) {
        canReleaseJob = this.currentUserCanReleaseMaintenanceJobs;
      } else if (jobType == JobTypes.Insulation) {
        canReleaseJob = this.currentUserCanReleaseInsulationJobs;
      } else if (jobType == JobTypes.HeatTrace) {
        return false;
        canReleaseJob = this.currentUserCanReleaseHeatTraceJobs;
      } else if (jobType == JobTypes.Refractory) {
        return false;
        canReleaseJob = this.currentUserCanReleaseRefractoryJobs;
      } else if (jobType == JobTypes.Fireproofing) {
        return false;
        canReleaseJob = this.currentUserCanReleaseFireproofingJobs;
      }
      return canReleaseJob;
    },
    async resumeJob(job: FormattedJob) {
      if (!this.jobStatusCanBeSetToDraft(job)) return;
      this.inlineMessage.message = null;
      this.processing = true;
      this.resuming = true;
      try {
        await jobService.resumeJobByID(job.id!);
        job.jobStatusID = JobStatuses.Draft;
        var snackbarPayload = {
          text: this.$t(`job.existing.resume-success`, [job.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.resuming = false;
      }
    },
    async putJobOnHold(job: FormattedJob) {
      if (!this.jobStatusCanBeSetToOnHold(job)) return;
      this.inlineMessage.message = null;
      this.processing = true;
      this.holding = true;
      try {
        var title = this.$t("scheduler.on-hold-reason");

        let details = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);
        if (!details) {
          this.holding = false;
          this.processing = false;
          return false;
        }
        await jobService.putJobOnHoldByID(job.id!, details);
        job.jobStatusID = JobStatuses.OnHold;
        job.jobStatusDetail = details;

        var snackbarPayload = {
          text: this.$t(`job.existing.on-hold-success`, [job.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.holding = false;
      }
    },
    async markJobAsReady(job: FormattedJob) {
      if (!this.jobStatusCanBeSetToReady(job)) return;
      this.inlineMessage.message = null;
      this.processing = true;
      this.readying = true;
      try {
        await jobService.markJobAsReadyByID(job.id!);
        job.jobStatusID = JobStatuses.Ready;
        var snackbarPayload = {
          text: this.$t(`job.existing.ready-success`, [job.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.readying = false;
      }
    },
    async releaseJob(job: FormattedJob) {
      if (!this.jobStatusCanBeSetToReleased(job)) return;
      this.inlineMessage.message = null;
      this.processing = true;
      this.releasing = true;
      try {
        var allContractors = await contractorService.getAll(false, null, null);
        var assignableContractors = [] as ContractorWithTags[];
        if (
          job.requestType == ScaffoldRequestTypes.Erect ||
          job.requestType == ScaffoldRequestTypes.Dismantle ||
          job.requestType == ScaffoldRequestTypes.Modify
        ) {
          assignableContractors = allContractors.filter(x => !!x.isScaffoldCompany);
        } else if (job.requestType == ScaffoldRequestTypes.Paint) {
          assignableContractors = allContractors.filter(x => !!x.isPaintCompany);
        } else if (job.requestType == ScaffoldRequestTypes.Insulation) {
          assignableContractors = allContractors.filter(x => !!x.isInsulationCompany);
        } else if (job.requestType == ScaffoldRequestTypes.Maintenance) {
          assignableContractors = allContractors.filter(x => !!x.isMaintenanceCompany);
        }

        var title = this.$t("scaffold-request-approvals.assign-contractor");
        title += ` | Job #${job.internalNumber}`;
        var contractorID = await showItemSelectionDialog(
          title,
          this.$t("scaffold-request-approvals.contractor-label"),
          [this.rules.required],
          assignableContractors,
          "name",
          "id",
          job.contractorID ?? ""
        );

        // If details is undefined the dialog was cancelled
        if (!contractorID) {
          // Change the value to something else and then back to its current to force a rebind
          this.releasing = false;
          this.processing = false;
          return false;
        }
        await jobService.releaseJobByID(job.id!, contractorID);
        job.jobStatusID = JobStatuses.Released;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.releasing = false;
        this.processing = false;
      }
    },
    async reloadTableData() {
      this.inlineMessage.message = null;

      this.processing = true;
      try {
        await this.loadData();
        this.inlineMessage.message = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async _initialize() {
      this.routeName = this.$route.name ?? "";
      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      var toDate = addDaysToDate(null, 0);
      this.setFilteringContext({
        context: `${this.jobTypeName}jobs`,
        parentalContext: null,
        showArchivedForFiltering: false,
        showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
        showArchivedForFilteringToDate: toDate,
        searchStringForFiltering: "",
        tagsForFiltering: [],
        statusesForFiltering: [],
        contractorsForFiltering: [],
        areasForFiltering: [],
        subAreasForFiltering: []
      });

      this.notifyNewBreadcrumb({
        text: this.$t(`job.${this.jobTypeName}.list.title`),
        to: `/${this.jobTypeName}`,
        resetHistory: true
      });

      this.processing = true;
      try {
        await this.$store.dispatch("LOAD_TAGS");
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },
  created: async function() {
    await this._initialize();
  },
  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  beforeUpdate: async function() {
    // Because this same list is used through different menu options to show different job types
    // The user could "navigate" to this same component again using a different route
    // By default nothing happens, so we need to check if the component is being accessed by a different route than before
    if (this.routeName != this.$route.name) {
      await this._initialize();
    }
  }
});

