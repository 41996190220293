var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      staticStyle: { "max-height": "100%", "z-index": "20" },
      attrs: {
        bottom: "",
        "mobile-breakpoint": "99999",
        absolute: "",
        height: "auto"
      },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-sheet",
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: "",
                tile: "",
                "data-cy": "crewdetailsbottomdialog"
              }
            },
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          !_vm.crew.id
                            ? _vm.$t(
                                "contractors.crews.new-crew.new-dialog-title"
                              )
                            : _vm.$t(
                                "contractors.crews.new-crew.edit-dialog-title",
                                [_vm.crew.name]
                              )
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "detailsform", on: { submit: _vm.preventSubmit } },
                [
                  _c(
                    "v-row",
                    { staticClass: "pl-3 pr-3" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-0",
                          attrs: { cols: "12", sm: "12", md: "12" }
                        },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t("common.name"),
                              disabled: _vm.processing,
                              rules: [_vm.rules.required],
                              dense: "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.crew.name,
                              callback: function($$v) {
                                _vm.$set(_vm.crew, "name", $$v)
                              },
                              expression: "crew.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-expand-transition",
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "new-employee",
                          attrs: { flat: "", tile: "" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "ml-0 mr-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "pl-3 pr-0 py-0" },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "contractors.crews.employees.only-included-employees-label"
                                              ),
                                              "hide-details": ""
                                            },
                                            model: {
                                              value:
                                                _vm.showOnlyIncludedEmployees,
                                              callback: function($$v) {
                                                _vm.showOnlyIncludedEmployees = $$v
                                              },
                                              expression:
                                                "showOnlyIncludedEmployees"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-4",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.crews.employees.employees-selection-sub-menu-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          "append-icon": "search",
                                          label: _vm.$t("common.search"),
                                          "single-line": "",
                                          "hide-details": "",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.tablesearchemployees,
                                          callback: function($$v) {
                                            _vm.tablesearchemployees = $$v
                                          },
                                          expression: "tablesearchemployees"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-data-table", {
                            directives: [
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:name.hidden",
                                value: "",
                                expression: "''",
                                arg: "name",
                                modifiers: { hidden: true }
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:order.hidden",
                                arg: "order",
                                modifiers: { hidden: true }
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:firstName",
                                value: _vm.$t("common.firstname"),
                                expression: "$t('common.firstname')",
                                arg: "firstName"
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:lastName",
                                value: _vm.$t("common.lastname"),
                                expression: "$t('common.lastname')",
                                arg: "lastName"
                              },
                              {
                                name: "fd-column",
                                rawName:
                                  "v-fd-column:employeeCode.hide-when-small",
                                value: _vm.$t("users.employee-code"),
                                expression: "$t('users.employee-code')",
                                arg: "employeeCode",
                                modifiers: { "hide-when-small": true }
                              },
                              {
                                name: "fd-column",
                                rawName:
                                  "v-fd-column:include.class_fd-include-cell.no-sort",
                                value: _vm.$t("common.include"),
                                expression: "$t('common.include')",
                                arg: "include",
                                modifiers: {
                                  "class_fd-include-cell": true,
                                  "no-sort": true
                                }
                              }
                            ],
                            ref: "employeesDataTable",
                            staticClass: "fixed-header v-table__overflow",
                            style: `max-height: ${
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? 190
                                : _vm.$vuetify.breakpoint.smAndUp
                                ? 160
                                : 150
                            }px; backface-visibility: hidden;`,
                            attrs: {
                              dense: "",
                              "custom-filter": _vm.splitFilter,
                              "items-per-page": -1,
                              "footer-props": {
                                "items-per-page-options": [-1]
                              },
                              "hide-default-footer": "",
                              items: _vm.employees,
                              search: _vm.tablesearchemployees,
                              loading: _vm.processing,
                              "loading-text": _vm.$t(
                                "common.table-loading-message"
                              ),
                              "sort-by": ["lastName"],
                              "mobile-breakpoint": "0"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "header.include",
                                fn: function({ header }) {
                                  return [
                                    _c("v-checkbox", {
                                      staticClass: "ma-0",
                                      attrs: {
                                        value: _vm.allSearchedEmployeesSelected,
                                        indeterminate:
                                          _vm.someSearchedEmployeesSelected,
                                        label: _vm.$t("common." + header.value),
                                        "hide-details": ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.flipSearchedEmployeeselected()
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.include",
                                fn: function({ item }) {
                                  return [
                                    _c("v-simple-checkbox", {
                                      attrs: {
                                        value: _vm.isEmployeeSelected(item),
                                        disabled: _vm.processing
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.flipEmployeeSelected(item)
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessage.message
                ? _c(
                    "v-row",
                    { staticClass: "mx-0", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                { attrs: { type: _vm.inlineMessage.type } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.close")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "save",
                        color: "primary",
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.saveDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.save")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }