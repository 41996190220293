import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { mapActions, mapMutations } from "vuex";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import {
  BlobFile,
  blobFileService,
  ContractorPurchaseOrder,
  contractorService,
  ContractorWithTags,
  Discipline,
  disciplineService,
  ExternalLink,
  externalLinkService,
  IsoSearchResult,
  isoService,
  JobEstimateSnapshotWithDetails,
  jobService,
  JobStatuses,
  JobTypes,
  JobWorkingComponent,
  messageService,
  noteService,
  Person,
  personService,
  ProjectLocation,
  projectLocationService,
  scaffoldDesignService,
  ScaffoldDesignWithDetails,
  scaffoldRequestSubTypeHelper,
  ScaffoldRequestSubTypeWithParent,
  ScaffoldRequestTypeDetails,
  scaffoldRequestTypeHelper,
  ScaffoldSearchResult,
  scaffoldService,
  ScaffoldTypeModifier,
  ScaffoldType,
  ScaffoldSubType,
  Tag,
  walkdownReferenceDataService,
  workPackageService,
  jobEstimateSnapshotService,
  jobWorkingEstimateService
} from "../services";
import { FormatJobData } from "./SP.JobsList.vue";
import { FDColumnDirective, FDHiddenArgumentName } from "@fd/lib/vue/utility/dataTable";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { BasicSelectItem } from "@fd/lib/vue/utility/select";
import {
  GetScaffoldDescription,
  GetScaffoldDetails,
  WorkPackageWithNameCode
} from "../dataMixins/scaffoldRequest";
import { GetPersonName, HasName, SortItemsWithName } from "../utils/person";
import fileHandling, {
  FileData,
  canOpenFileInNewWindow,
  componentsFromFileName,
  confirmUniqueName,
  isFilePhoto,
  isFilePreviewable
} from "@fd/lib/vue/mixins/fileHandling";
import messaging, {
  ParseMessageWithSenderDetails,
  SortMessagesArray
} from "../dataMixins/messaging";
import notes, { ParseNoteWithSenderDetails, SortNotesArray } from "../dataMixins/notes";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import { valueInArray } from "@fd/lib/client-util/array";
import { filterByContractors } from "../services/taggableItems";
import { Attachment } from "../dataMixins/attachment";
import { openExternalLinkDetails } from "./components/ExternalLinkDialog.vue";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import { job } from "../dataMixins/job";
import { showItemSelectionDialog } from "./components/ItemSelectionDialog.vue";
import { formatJobNumber } from "../utils/job";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import { TranslateResult } from "vue-i18n";
import estimates from "../dataMixins/estimates";
import {
  createEstimateComponentModifyDialogForJob,
  createEstimateComponentNewDialogForJob
} from "./components/dialogs/SP.JobEstimateComponentNewDialog.vue";
import { createWorkingEstimateTakeoffDialogForJob } from "./components/dialogs/SP.WorkingEstimateTakeoffDialog.vue";

type FormattedScaffoldDesign = ScaffoldDesignWithDetails & {
  formattedScaffoldNumber: string;
};
type BlobFileData = BlobFile &
  FileData & {
    isIsoFile: boolean;
  };
type AttachmentWithBlobFile = Attachment & {
  id: string | null | undefined;
  blobFile?: BlobFileData | undefined;
};

export function GetIsoDetails(iso: IsoSearchResult): string {
  let values = [];
  if (iso.systemName && iso.systemName.trim().length > 0) {
    values.push(`${iso.systemName.trim()}`);
  }

  if (iso.testPackageName && iso.testPackageName.trim().length > 0) {
    values.push(`${iso.testPackageName.trim()}`);
  }
  return values.join(", ");
}

type Keyword = Tag;
export default FDVue.extend({
  name: "sp-job-existing",

  mixins: [serviceErrorHandling, tabbedView, job, fileHandling, notes, messaging, estimates],

  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-work-order-details": () => import("./components/WorkOrderDetailsForm.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue"),
    "fd-work-package-selector": () => import("./components/WorkPackageSelectionDialog.vue"),
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "sp-work-order-scope-details-form": () =>
      import("./components/forms/SP.WorkOrderScopeDetailsForm.vue"),
    "sp-estimate-summary-table": () => import("./components/estimates/SP.EstimateSummaryTable.vue"),
    "sp-estimates-list": () => import("./components/estimates/SP.EstimatesList.vue")
  },

  data: function() {
    return {
      detailsReadonly: false,
      slidein: false,
      screenLoaded: false,
      hideActionButtons: false,

      saving: false,
      resuming: false,
      holding: false,
      readying: false,
      releasing: false,
      cancelling: false,

      // Form data errors
      detailsTabError: false,
      notesTabError: false,
      photosTabError: false,
      messagingTabError: false,

      firstTabKey: `1`,
      detailsTab: new PageTab({
        nameKey: "job.existing.tabs.details",
        key: `1`,
        visible: true
      }),
      scopeTab: new PageTab({
        nameKey: "job.existing.tabs.scope-change",
        key: `2`,
        visible: true
      }),
      photosTab: new PageTab({
        nameKey: "job.existing.tabs.photos",
        key: `3`,
        visible: false
      }),
      notesTab: new PageTab({
        nameKey: "job.existing.tabs.notes",
        key: `4`,
        visible: false
      }),
      messagingTab: new PageTab({
        nameKey: "job.existing.tabs.messaging",
        key: `5`,
        visible: false
      }),
      attachmentsTab: new PageTab({
        nameKey: "common.attachments",
        key: "6",
        visible: false
      }),
      optionsTab: new PageTab({
        nameKey: "job.existing.tabs.options",
        key: `10`,
        visible: false
      }),
      estimatesTab: new PageTab({
        nameKey: "job.existing.tabs.estimate",
        key: "7",
        visible: false,
        error: false
      }),
      workOrdersTab: new PageTab({
        nameKey: "job.existing.tabs.work-orders",
        key: `8`,
        visible: false
      }),
      takeoffTab: new PageTab({
        nameKey: "job.existing.tabs.takeoff",
        key: "9",
        visible: false
      }),

      /*** DATA ***/
      allRequestTypes: [] as ScaffoldRequestTypeDetails[],
      allRequestSubTypes: [] as ScaffoldRequestSubTypeWithParent[],
      contractors: [] as ContractorWithTags[],
      allContractors: [] as ContractorWithTags[],
      allRequestors: [] as (Person & HasName)[],
      allDisciplines: [] as Discipline[],
      allVisibleAreas: [] as ProjectLocation[],
      allVisibleSubAreas: [] as ProjectLocation[],

      workOrderTableSearch: "",
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],

      expanderColSpan: 10,
      /*** TAG NUMBER ***/
      availableScaffolds: [] as ScaffoldSearchResult[],
      selectedScaffold: null as ScaffoldSearchResult | null | undefined,
      // Determines whether the area/subarea/etc. have been set via scaffold selection
      dataSetByScaffold: false,

      /*** ISO ***/
      availableIsos: [] as IsoSearchResult[],
      selectedIso: null as IsoSearchResult | null | undefined,

      /*** Design NUMBER ***/
      availableDesigns: [] as FormattedScaffoldDesign[],
      selectedDesign: null as FormattedScaffoldDesign | null | undefined,
      // Determines whether the area/subarea/etc. have been set via ScaffoldDesign selection
      dataSetByDesign: false,

      /*** KEYWORDS ***/
      selectedKeywords: [] as Keyword[],

      /*** IWPs ***/
      availableIWPs: [] as WorkPackageWithNameCode[],
      selectedIWPs: [] as WorkPackageWithNameCode[],

      // *** ATTACHMENTS ***
      touchedFileName: "",
      showPhotoTabAttachmentAlert: false,
      showAttachmentTabPhotoAlert: false,
      tablesearchfiles: "",
      allFiles: [] as BlobFileData[],
      externalLinks: [] as ExternalLink[],
      isoFiles: [] as BlobFileData[],

      /*** ESTIMATES ****/
      estimatePanel: [0, 1],
      tablesearchestimates: "",
      downloadFileWhenGenerated: false,
      generatingSnapshot: false,
      estimatesList: [] as JobEstimateSnapshotWithDetails[],
      summaryPanelTimeUnitDivider: 60, // Display all times in the summary table as hours (initial value)
      workingComponents: [] as JobWorkingComponent[],
      allScaffoldTypeModifiers: [] as ScaffoldTypeModifier[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as BlobFileData | undefined,

      /*** TAKEOFF ***/
      partstablesearch: "",
      partsItemsPerPage: 25,
      partsItemsPerPageOptions: [5, 10, 25, 50, 100, -1]
    };
  },

  computed: {
    // *** GLOBAL ***
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      let tabs = [
        this.notesTab,
        this.scopeTab,
        this.photosTab,
        this.messagingTab,
        this.attachmentsTab
      ] as Tab[];

      if (this.isScaffoldJob) {
        tabs.push(this.estimatesTab);
      }

      tabs.push(this.workOrdersTab);

      if (!this.isScaffoldJob) {
        tabs.push(this.takeoffTab);
      }
      return tabs;
    },
    currentUserCanAddMessage(): boolean {
      return true;
    },
    currentUserCanAddNote(): boolean {
      return true;
    },
    currentUserCanMarkAsReady(): boolean {
      return this.currentUserCanReleaseJob;
    },
    currentUserCanCancelJob(): boolean {
      return this.currentUserCanReleaseJob;
    },
    currentUserCanReleaseJob(): boolean {
      let jobType = this.job.jobTypeID;
      let canReleaseJob = false;
      if (jobType == JobTypes.Scaffold) {
        canReleaseJob = this.currentUserCanReleaseScaffoldJobs;
      } else if (jobType == JobTypes.Paint) {
        canReleaseJob = this.currentUserCanReleasePaintJobs;
      } else if (jobType == JobTypes.Maintenance) {
        canReleaseJob = this.currentUserCanReleaseMaintenanceJobs;
      } else if (jobType == JobTypes.Insulation) {
        canReleaseJob = this.currentUserCanReleaseInsulationJobs;
      } else if (jobType == JobTypes.HeatTrace) {
        return false;
        canReleaseJob = this.currentUserCanReleaseHeatTraceJobs;
      } else if (jobType == JobTypes.Refractory) {
        return false;
        canReleaseJob = this.currentUserCanReleaseRefractoryJobs;
      } else if (jobType == JobTypes.Fireproofing) {
        return false;
        canReleaseJob = this.currentUserCanReleaseFireproofingJobs;
      }
      return canReleaseJob;
    },
    jobIsOnHold(): boolean {
      return this.job.jobStatusID == JobStatuses.OnHold;
    },
    jobIsCancelled(): boolean {
      return this.job.jobStatusID == JobStatuses.Cancelled;
    },
    jobIsReleased(): boolean {
      return this.job.jobStatusID == JobStatuses.Released;
    },
    jobIsCompleted(): boolean {
      return this.job.jobStatusID == JobStatuses.Completed;
    },
    jobCanBeSaved(): boolean {
      // Request can be saved if:
      // Current user has permission to submit requests, and the request status is either New, Draft or Declined
      // If the current status is "Walkdown" and the current user is assigned to perform said walkdown
      if (this.job.jobStatusID != undefined && this.job.jobStatusID == JobStatuses.Draft)
        return true;

      return false;
    },

    jobStatusCanBeSetToDraft() {
      return this.job.jobStatusID == JobStatuses.OnHold;
    },
    jobStatusCanBeSetToOnHold() {
      return !this.job.jobStatusID;
    },
    jobStatusCanBeSetToReady() {
      return !this.job.jobStatusID;
    },
    jobStatusCanBeSetToReleased() {
      return !this.job.jobStatusID || this.job.jobStatusID == JobStatuses.Ready;
    },
    jobStatusCanBeSetToCancelled(): boolean {
      return (
        !this.job.jobStatusID ||
        this.job.jobStatusID == JobStatuses.OnHold ||
        this.job.jobStatusID == JobStatuses.Ready
      );
    },

    jobCanHaveExistingTagNumber(): boolean {
      return this.job.jobTypeID == JobTypes.Scaffold;
    },
    // *** DETAILS ***
    /*
     Logic related to the formatting of the 3 grid blocks in #4, 5, and 6.
     These are normally:
     #4 - Required Until date, Tag # Or Empty Space
     #5 - Requesting Contractor or Empty Space
     #6 - Designed Tag # or Empty Space
     Layout-wise, we initially have the following data:
     SCAFFOLD - Erect    : | Required Until | Contractor | Designed Number |
              - Dismantle: | Tag Number     | Contractor |                 |
              - Modify   : | Tag Number     | Contractor |                 |
     PAINT               : |                | Contractor |                 |
     INSULATION          : |                | Contractor |                 |
     MAINTENANCE         : |                | Contractor |                 |

     Since soft craft jobs (Paint/Insulation/Maintenance) have 2 empty spaces in blocks #4 and #6,
      we can move the contractor up a spot from #5 to #4, removing the 5/6 row entirely.
     */
    showBlock4(): boolean {
      return this.isScaffoldJob;
    },
    showBlock5(): boolean {
      return (
        this.canSelectContractor ||
        (this.$vuetify.breakpoint.smAndUp && this.canSelectScaffoldDesign) ||
        (this.$vuetify.breakpoint.smAndUp && !this.isScaffoldJob)
      );
    },
    showBlock6(): boolean {
      return (
        this.canSelectScaffoldDesign ||
        (this.$vuetify.breakpoint.smAndUp && this.isScaffoldJob && this.canSelectContractor)
      );
    },
    canSelectContractor(): boolean {
      return !!this.contractors && this.contractors.length > 1;
    },
    canSelectScaffoldDesign(): boolean {
      // return false;
      return (
        this.isScaffoldJob &&
        this.isScaffoldErectJob &&
        this.$store.state.curEnvironment.enableScaffoldDesign
      );
    },
    canEnterRequiredUntilDate(): boolean {
      return this.isScaffoldErectJob;
    },
    canSelectScaffold(): boolean {
      return this.isScaffoldJob && (this.isScaffoldModifyJob || this.isScaffoldDismantleJob);
    },
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },
    allKeywords(): Keyword[] {
      return this.$store.state.tags.fullList as Keyword[];
    },
    requestTypes(): BasicSelectItem[] {
      return this.allRequestTypes.map(x => ({ text: x.displayName, value: x.value }));
    },
    requestSubTypes(): BasicSelectItem[] {
      return this.allRequestSubTypes
        .filter(x => x.parentRequestType == this.job.requestType)
        .map(x => ({ text: this.$t(`scaffold-requests.sub-types.${x.value}`), value: x.value }));
    },
    areas(): ProjectLocation[] {
      let contractorID = this.job.contractorID;
      if (!contractorID) return [];

      let selectedContractor = this.contractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      var areas = this.allVisibleAreas.filter(
        x =>
          !!selectedContractor?.includesAllAreas || valueInArray(x.id, selectedContractor?.areaIDs)
      );
      if (!this.job.areaID && !this.detailsReadonly && areas.length == 1) {
        this.$nextTick(() => {
          this.job.areaID = areas[0].id;
        });
      }
      return areas;
    },
    subAreas(): ProjectLocation[] {
      let areaID = this.job.areaID;
      if (!areaID) return [];

      let subAreas = this.allVisibleSubAreas.filter(x => {
        return x.parentLocationID == areaID;
      });
      if (!this.job.subAreaID && !this.detailsReadonly && subAreas.length == 1) {
        this.$nextTick(() => {
          this.job.subAreaID = subAreas[0].id;
        });
      }
      return subAreas;
    },
    disciplines(): Discipline[] {
      let contractorID = this.job.contractorID;
      if (!contractorID) return [];

      let selectedContractor = this.contractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      var disciplines = this.allDisciplines.filter(
        x =>
          !!selectedContractor?.includesAllDisciplines ||
          valueInArray(x.id, selectedContractor?.disciplineIDs)
      );

      if (!this.job.disciplineID && !this.detailsReadonly && disciplines.length == 1) {
        this.$nextTick(() => {
          this.job.disciplineID = disciplines[0].id;
        });
      }

      return disciplines;
    },
    requestors(): Person[] {
      let contractorID = this.job.contractorID;
      if (!contractorID) return [];

      let selectedContractor = this.contractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      let disciplineID = this.job.disciplineID;
      if (!disciplineID) return [];

      let selectedDiscipline = this.disciplines.find(x => x.id == disciplineID);
      if (!selectedDiscipline) return [];

      var requestors = this.allRequestors;
      // .filter(x => {
      //   return (
      //     !!selectedContractor?.employeeIDs?.length &&
      //     selectedContractor!.employeeIDs.includes(x.id!) &&
      //     !!selectedDiscipline?.employeeIDs?.length &&
      //     selectedDiscipline!.employeeIDs.includes(x.id!)
      //   );
      // });
      if (!this.job.requestingEmployeeID && !this.detailsReadonly && requestors.length == 1) {
        this.$nextTick(() => {
          this.job.requestingEmployeeID = requestors[0].id;
        });
      }
      return requestors;
    },
    missingRequiredClientWorkOrderData(): boolean {
      return !!this.job.isClientWorkOrder && !this.job.clientWorkOrderReferenceNumber?.length;
    },
    missingRequiredChangeOrderData(): boolean {
      return !!this.job.isChangeOrder && !this.job.changeOrderReferenceNumber?.length;
    },
    missingRequiredReworkData(): boolean {
      return !!this.job.isRework && !this.job.reworkReferenceNumber?.length;
    },
    missingRequiredServiceOrderData(): boolean {
      return !!this.job.isServiceOrder && !this.job.serviceOrderReferenceNumber?.length;
    },
    missingRequiredPurchaseOrderData(): boolean {
      let enablePurchaseOrders = this.$store.state.curEnvironment.enablePurchaseOrders;
      if (!enablePurchaseOrders) {
        this.job.purchaseOrderID = null;
        return false;
      }

      if (!!this.job.purchaseOrderID?.length) {
        // Verify the selected purchase order is still in the selectable list, since the contractor may have changed.
        this.job.purchaseOrderID = this.selectablePurchaseOrders.find(
          x => x.id == this.job.purchaseOrderID
        )?.id;
      }

      return !this.job.purchaseOrderID?.length;
    },
    scopeTabError(): boolean {
      return (
        this.missingRequiredClientWorkOrderData ||
        this.missingRequiredChangeOrderData ||
        this.missingRequiredReworkData ||
        this.missingRequiredServiceeOrderData ||
        this.missingRequiredPurchaseOrderData
      );
    },
    allPurchaseOrders(): ContractorPurchaseOrder[] {
      let allPurchaseOrders = this.$store.state.contractorPurchaseOrders
        .fullList as ContractorPurchaseOrder[];
      let sortedPurchaseOrders = allPurchaseOrders.sort(
        (a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0)
      );
      return sortedPurchaseOrders;
    },
    selectablePurchaseOrders(): ContractorPurchaseOrder[] {
      let contractorID = this.allContractors.find(x => x.id == this.job.contractorID)?.id;
      if (!contractorID?.length) return [];

      return filterByContractors([contractorID], this.allPurchaseOrders);
    },

    // *** ESTIMATE ***
    scaffoldTypes(): (BasicSelectItem & { subTypes: BasicSelectItem[] })[] {
      var usedScaffoldTypeIDs = this.allScaffoldTypeModifiers.map(x => x.scaffoldTypeID ?? -1);
      var filteredScaffoldTypes = this.allScaffoldTypes.filter(x =>
        usedScaffoldTypeIDs.includes(x.value)
      );

      var visibleScaffoldTypes = [] as (BasicSelectItem & { subTypes: BasicSelectItem[] })[];
      for (let scaffoldType of filteredScaffoldTypes) {
        let subTypes = this.activeScaffoldSubTypesForType(scaffoldType.value);
        if (!subTypes.length) continue;

        visibleScaffoldTypes.push({
          ...scaffoldType,
          subTypes: subTypes
        });
      }

      return visibleScaffoldTypes;
    },
    canGenerateSnapshot(): boolean {
      return (
        (this.workingComponents as any[]).findIndex(
          x => !!x.isNew || !!x.isModified || !!x.isRemoved
        ) !== -1
      );
    },

    hasAnyIsoNonPhotoAttachments(): boolean {
      return this.nonPhotoAttachments.findIndex(x => !!x.blobFile?.isIsoFile) !== -1;
    },
    nonPhotosIconColumnArgument(): string {
      return this.hasAnyIsoNonPhotoAttachments ? "isoIcon" : FDHiddenArgumentName;
    },
    nonPhotoAttachments(): AttachmentWithBlobFile[] {
      let attachments = [] as AttachmentWithBlobFile[];

      this.allFiles.forEach(file => {
        attachments.push({
          id: null,
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          blobFile: file
        });
      });

      this.externalLinks.forEach(link => {
        attachments.push({
          id: null,
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      this.isoFiles.forEach(file => {
        attachments.push({
          id: file.id,
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          blobFile: file
        });
      });

      return attachments.filter(x => !x.isPhoto);
    },
    hasAnyIsoPhotoFiles(): boolean {
      return this.photoFiles.findIndex(x => !!x.isIsoFile) !== -1;
    },
    photosIconColumnArgument(): string {
      return this.hasAnyIsoPhotoFiles ? "icon" : FDHiddenArgumentName;
    },
    photoFiles(): BlobFileData[] {
      let photosFiles = this.allFiles.filter(x => x.isPhoto);
      this.isoFiles
        .filter(x => !!x.isPhoto)
        .forEach(file => {
          photosFiles.push(file);
        });
      return photosFiles;
    }
  },

  watch: {
    job() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != `/${this.jobTypeName}`) {
        this.notifyNewBreadcrumb({
          text: this.$t(`job.${this.jobTypeName}.list.title`),
          to: `/${this.jobTypeName}`,
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: formatJobNumber(this.job.internalNumber),
        to: `/${this.jobTypeName}/${this.$route.params.id}`
      });
    },
    selectedScaffold: async function(newValue, oldValue) {
      if (!!oldValue && !newValue) {
        this.job.scaffoldID = undefined;
        this.job.areaID = undefined;
        this.job.subAreaID = undefined;
        this.job.specificWorkLocation = undefined;
        this.dataSetByScaffold = false;
      } else if (!!newValue) {
        let scaffold: ScaffoldSearchResult = newValue;
        this.job.scaffoldID = scaffold.id;
        this.job.scaffoldNumber = scaffold.internalNumber;

        this.job.areaID = scaffold.areaID;
        this.job.subAreaID = scaffold.subAreaID;
        this.job.specificWorkLocation = scaffold.specificWorkLocation;
        this.dataSetByScaffold = true;
        this.dataSetByDesign = false;
      }
    },
    selectedIso: async function(newValue, oldValue) {
      if (!!oldValue && !newValue) {
        this.job.isoID = undefined;
        this.isoFiles = [];
      } else if (!!newValue) {
        let iso: IsoSearchResult = newValue;
        this.job.isoID = iso.id;
        this.loadIsoFiles();
      }
    },
    selectedDesign: async function(newValue, oldValue) {
      if (!!oldValue && !newValue) {
        this.job.scaffoldID = undefined;
        this.job.scaffoldDesignID = undefined;
        this.job.areaID = undefined;
        this.job.subAreaID = undefined;
        this.job.specificWorkLocation = undefined;
        this.dataSetByDesign = false;
      } else if (!!newValue) {
        let design: ScaffoldDesignWithDetails = newValue;
        this.job.scaffoldID = design.scaffoldID;
        this.job.scaffoldDesignID = design.id;

        this.job.areaID = this.allVisibleAreas.find(x => x.id == design.areaID)?.id;
        this.job.subAreaID = this.allVisibleSubAreas.find(x => x.id == design.subAreaID)?.id;
        this.job.specificWorkLocation = design.specificWorkLocation;
        this.dataSetByDesign = true;
        this.dataSetByScaffold = false;
      }
    },
    "job.requestType": function(newValue, oldValue) {
      if (!!this.detailsReadonly) return;

      if (this.requestSubTypes.length == 1) {
        this.job.requestSubType = this.requestSubTypes[0].value;
      }
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (newValue == 1) this.selectedScaffold = null;
    }
  },

  methods: {
    ...mapActions({
      loadPurchaseOrders: "LOAD_CONTRACTOR_PURCHASE_ORDERS"
    }),
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    backButtonClicked(item: any) {
      this.$router.push(this.$store.getters.backBreadcrumb.to || `/${this.jobTypeName}/`);
    },
    onSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.push(`/${this.jobTypeName}`);
    },
    async resumeJob() {
      if (!this.jobStatusCanBeSetToDraft) return;
      this.inlineMessage.message = null;
      this.processing = true;
      this.resuming = true;
      try {
        await jobService.resumeJobByID(this.$route.params.id);

        var snackbarPayload = {
          text: this.$t(`job.existing.resume-success`, [this.job.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.$router.push(`/${this.jobTypeName}`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.resuming = false;
      }
    },
    async putJobOnHold() {
      if (!this.jobStatusCanBeSetToOnHold) return;
      this.inlineMessage.message = null;
      this.processing = true;
      this.holding = true;
      try {
        var title = this.$t("scheduler.on-hold-reason");

        let details = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);
        if (!details) {
          this.holding = false;
          this.processing = false;
          return false;
        }
        await jobService.putJobOnHoldByID(this.$route.params.id, details);

        var snackbarPayload = {
          text: this.$t(`job.existing.on-hold-success`, [this.job.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.$router.push(`/${this.jobTypeName}`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.holding = false;
      }
    },
    async markJobAsReady() {
      if (!this.jobStatusCanBeSetToReady) return;
      this.inlineMessage.message = null;
      this.processing = true;
      this.readying = true;
      try {
        if (!(await this.saveJobData(false))) {
          this.processing = false;
          this.readying = false;
          return;
        }
        await jobService.markJobAsReadyByID(this.$route.params.id);

        var snackbarPayload = {
          text: this.$t(`job.existing.ready-success`, [this.job.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.$router.push(`/${this.jobTypeName}`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.readying = false;
      }
    },
    async releaseJob() {
      this.inlineMessage.message = null;
      if (!this.currentUserCanReleaseJob) return;
      if (!this.jobStatusCanBeSetToReleased) return;
      this.processing = true;
      this.releasing = true;
      try {
        if (!(await this.saveJobData(false))) {
          this.processing = false;
          this.releasing = false;
          return;
        }

        var assignableContractors = [] as ContractorWithTags[];
        if (this.job.jobTypeID == JobTypes.Scaffold) {
          assignableContractors = this.allContractors.filter(x => !!x.isScaffoldCompany);
        } else if (this.job.jobTypeID == JobTypes.Paint) {
          assignableContractors = this.allContractors.filter(x => !!x.isPaintCompany);
        } else if (this.job.jobTypeID == JobTypes.Insulation) {
          assignableContractors = this.allContractors.filter(x => !!x.isInsulationCompany);
        } else if (this.job.jobTypeID == JobTypes.Maintenance) {
          assignableContractors = this.allContractors.filter(x => !!x.isMaintenanceCompany);
        } else if (this.job.jobTypeID == JobTypes.HeatTrace) {
          // assignableContractors = this.allContractors.filter(x => !!x.isHeatTraceCompany);
        } else if (this.job.jobTypeID == JobTypes.Refractory) {
          // assignableContractors = this.allContractors.filter(x => !!x.isRefractoryCompany);
        } else if (this.job.jobTypeID == JobTypes.Fireproofing) {
          // assignableContractors = this.allContractors.filter(x => !!x.isFireproofingCompany);
        }

        var title = this.$t("scaffold-request-approvals.assign-contractor");
        var contractorID = await showItemSelectionDialog(
          title,
          this.$t("scaffold-request-approvals.contractor-label"),
          [this.rules.required],
          assignableContractors,
          "name",
          "id",
          this.job.contractorID ?? ""
        );
        if (!contractorID) {
          this.releasing = false;
          this.processing = false;
          return false;
        }

        await jobService.releaseJobByID(this.$route.params.id, contractorID);

        var snackbarPayload = {
          text: this.$t(`job.existing.release-success`, [this.job.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.$router.push(`/${this.jobTypeName}`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.releasing = false;
      }
    },
    async cancelJob() {
      if (!this.jobStatusCanBeSetToCancelled) return;
      this.inlineMessage.message = null;
      this.processing = true;
      this.cancelling = true;
      try {
        let title = this.$t("scheduler.cancellation-reason");

        let details = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);
        if (!details) {
          this.cancelling = false;
          this.processing = false;
          return false;
        }
        await jobService.cancelJobByID(this.$route.params.id, details);

        var snackbarPayload = {
          text: this.$t(`job.existing.cancel-success`, [this.job.internalNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.$router.push(`/${this.jobTypeName}`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.cancelling = false;
      }
    },
    async save(closeOnComplete: boolean) {
      this.inlineMessage.message = null;
      this.processing = true;
      this.saving = true;
      try {
        if (!(await this.saveJobData(true))) {
          this.processing = false;
          this.saving = false;
          return;
        }

        if (closeOnComplete) this.$router.push(`/${this.jobTypeName}`);
        else {
          Promise.all([this.loadJobDetails(), this.loadWorkingData(), this.loadEstimateHistory()]);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    validateScopeForm(): boolean {
      let scopeForm = this.$refs.scopechangeform as any;
      if (!scopeForm) {
        return !this.scopeTabError;
      }
      let formValid = scopeForm.validate();
      let scopeValid = !this.scopeTabError;
      return formValid && scopeValid;
    },
    validate(): boolean {
      console.log(`Validating request details forms.`);
      this.detailsTabError = !((this.$refs.detailsform as HTMLFormElement)?.validate() ?? false);
      this.notesTabError = !((this.$refs.notesform as HTMLFormElement)?.validate() ?? true);
      this.photosTabError = !((this.$refs.filesform as HTMLFormElement)?.validate() ?? true);
      return (
        this.validateScopeForm() &&
        !(this.detailsTabError || this.notesTabError || this.photosTabError)
      );
    },
    async saveJobData(isDraft: boolean): Promise<boolean> {
      if (this.detailsReadonly) return false;
      console.log(`Saving job details...`);

      await this.updateIsDraft(isDraft);
      if (!this.validate()) {
        console.log(`validation failed`);
        var message = this.$t("job.existing.error-message");
        if (this.detailsTabError) message += "\n\t- " + this.$t("job.existing.tabs.details");
        if (this.notesTabError) message += "\n\t- " + this.$t("job.existing.tabs.notes");
        if (this.scopeTabError) message += "\n\t- " + this.$t("job.existing.tabs.scope-change");
        if (this.photosTabError) message += "\n\t- " + this.$t("job.existing.tabs.photos");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return false;
      }

      this.job.archivedDate = undefined;

      // Get the list of selected IWP IDs
      this.job.workPackageIDs = this.selectedIWPs.map(iwp => iwp.id!);
      this.job.tagIDs =
        this.selectedKeywords.length > 0 ? this.selectedKeywords.map(x => x.id!) : undefined;

      if (!!this.selectedScaffold) {
        this.job.scaffoldID = this.selectedScaffold.id;
        this.job.scaffoldNumber = this.selectedScaffold.internalNumber;
      } else {
        this.job.scaffoldID = undefined;
      }

      if (!!this.selectedIso) {
        this.job.isoID = this.selectedIso.id;
      } else {
        this.job.isoID = undefined;
      }

      await jobService.updateItem(this.job.id!, this.job);
      return true;
    },
    async loadJobDetails() {
      let job = FormatJobData(await jobService.getByID(this.$route.params.id), this.$i18n);
      this.job = {
        ...job,
        relatedWorkOrders: job.relatedWorkOrders.map(x => ({
          ...x,
          formattedRequestDate: DateUtil.stripTimeFromLocalizedDateTime(x.created),
          formattedCompletedDate: DateUtil.stripTimeFromLocalizedDateTime(x.completedDate)
        }))
      };
      this.selectedKeywords = !!this.job.tagIDs
        ? (this.job.tagIDs
            .map(x => this.allKeywords.find(y => y.id == x))
            .filter(x => !!x) as Keyword[])
        : [];
      var isLocked =
        job.jobStatusID != undefined &&
        (job.jobStatusID == JobStatuses.Cancelled ||
          job.jobStatusID == JobStatuses.Released ||
          job.jobStatusID == JobStatuses.Completed);
      this.detailsReadonly = isLocked;
    },

    //#region "Loading"
    // DOES NOT manage processing or error message logic
    async loadRequestTypes(): Promise<void> {
      if (this.job.jobTypeID == JobTypes.Maintenance) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllMaintenanceRequestTypeDetails();
      } else if (this.job.jobTypeID == JobTypes.Paint) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllPaintRequestTypeDetails();
      } else if (this.job.jobTypeID == JobTypes.Insulation) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllInsulationRequestTypeDetails();
      } else if (this.job.jobTypeID == JobTypes.Scaffold) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllScaffoldRequestTypeDetails();
      } else if (this.job.jobTypeID == JobTypes.HeatTrace) {
        // this.allRequestTypes = await scaffoldRequestTypeHelper.getAllHeatTraceRequestTypeDetails();
      } else if (this.job.jobTypeID == JobTypes.Refractory) {
        // this.allRequestTypes = await scaffoldRequestTypeHelper.getAllRefractoryRequestTypeDetails();
      } else if (this.job.jobTypeID == JobTypes.Fireproofing) {
        // this.allRequestTypes = await scaffoldRequestTypeHelper.getAllFireproofingRequestTypeDetails();
      } else {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllRequestTypeDetails();
      }
    },
    // DOES NOT manage processing or error message logic
    async loadRequestSubTypes(): Promise<void> {
      this.allRequestSubTypes = await scaffoldRequestSubTypeHelper.getAllRequestSubTypesWithParent();
    },
    // DOES NOT manage processing or error message logic
    async loadVisibleAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allVisibleAreas = SortItemsWithName(areas);
    },
    // DOES NOT manage processing or error message logic
    async loadVisibleSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allVisibleSubAreas = SortItemsWithName(subAreas);
    },
    // DOES NOT manage processing or error message logic
    async loadContractors(): Promise<void> {
      let contractors = await await contractorService.getScaffoldRequestingContractors();
      this.contractors = SortItemsWithName(contractors);
    },
    async loadAllContractors() {
      let allContractors = await contractorService.getAll(false, null, null);
      this.allContractors = SortItemsWithName(allContractors);
    },
    // DOES NOT manage processing or error message logic
    async loadDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getAll(false, null, null);
      this.allDisciplines = SortItemsWithName(disciplines);
    },

    /*** ESTIMATES ***/
    async loadEstimateHistory() {
      var estimatesList = await jobEstimateSnapshotService.getEstimateSnapshotsForJobWithID(
        this.job.id!
      );
      this.estimatesList = estimatesList
        .sort((a, b) => {
          // Sort with newest at top
          let aCreated = new Date(a.created!);
          let bCreated = new Date(b.created!);
          return bCreated.getTime() - aCreated.getTime();
        })
        .map(e => ({
          ...e,
          dateTimeString: DateUtil.localizedDateTimeString(
            new Date(DateUtil.isoDateTimeString(e.created))
          ),
          dateString: DateUtil.stripTimeFromLocalizedDateTime(
            new Date(DateUtil.isoDateTimeString(e.created))
          ),

          estimatedTotalTime:
            (e.estimatedTotalDemobilizationMinutes ?? 0.0) +
            (e.estimatedTotalDismantleMinutes ?? 0.0) +
            (e.estimatedTotalErectMinutes ?? 0.0) +
            (e.estimatedTotalHoardingMinutes ?? 0.0) +
            (e.estimatedTotalMobilizationMinutes ?? 0.0) +
            (e.estimatedTotalModifyMinutes ?? 0.0),
          estimatedErectMPP:
            (e.estimatedTotalErectMinutes ?? 0.0) / (e.estimatedTotalPartCount ?? 1),
          estimatedDismantleMPP:
            (e.estimatedTotalDismantleMinutes ?? 0.0) / (e.estimatedTotalPartCount ?? 1)
        }));
    },
    // DOES NOT manage processing or error message logic
    async loadScaffoldTypeModifiers(): Promise<void> {
      this.allScaffoldTypeModifiers = SortItemsWithName(
        await walkdownReferenceDataService.getAllScaffoldTypeModifiers()
      );
    },
    async loadWorkingData() {
      this.processing = true;
      try {
        await Promise.all([this.loadWorkingComponents()]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadWorkingComponents() {
      this.workingComponents = await jobWorkingEstimateService.getWorkingComponentsForJob(
        this.job.id!
      );
    },
    activeScaffoldSubTypesForType(
      scaffoldTypeID: ScaffoldType
    ): { text: string | TranslateResult; value: number }[] {
      var usedScaffoldSubTypeIDs = this.allScaffoldTypeModifiers
        .filter(
          x => !!x.isActive && x.scaffoldSubTypeID != null && x.scaffoldSubTypeID != undefined
        )
        .map(x => x.scaffoldSubTypeID!);
      if (!usedScaffoldSubTypeIDs.length) return [];

      var allScaffoldSubTypesForType = this.allScaffoldSubTypesForType(scaffoldTypeID);
      return allScaffoldSubTypesForType
        .filter(x => usedScaffoldSubTypeIDs.includes(x.value))
        .map(st => {
          let existingTypeModifier = this.allScaffoldTypeModifiers.find(
            mod => mod.scaffoldSubTypeID != null && mod.scaffoldSubTypeID == st.value
          );
          return {
            text: existingTypeModifier?.name ?? st.text,
            value: st.value
          };
        });
    },
    async downloadEstimateExcelFile(estimateID: string | null | undefined) {
      if (!estimateID?.length) estimateID = this.currentEstimate.id!;
      this.processing = true;
      try {
        var blob = await jobEstimateSnapshotService.downloadJobEstimateGeneratedExcelFile(
          estimateID!
        );
        let tag = formatJobNumber(this.job.internalNumber);
        if (!!this.job.scaffoldNumber) {
          tag = `T${`00000${this.job.scaffoldNumber}`.slice(-5)}`;
        }
        downloadBlob(blob, `job-estimate-${tag}.xlsx`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async openNewEstimateDialog(scaffoldTypeID: ScaffoldType, scaffoldSubTypeID: ScaffoldSubType) {
      let existingTypeModifier = this.allScaffoldTypeModifiers.find(
        x =>
          x.scaffoldTypeID == scaffoldTypeID &&
          x.scaffoldSubTypeID != null &&
          x.scaffoldSubTypeID == scaffoldSubTypeID
      );
      console.log(
        `JobEstimateComponents.openNewDialog scaffoldTypeID: ${scaffoldTypeID}, scaffoldSubTypeID: ${scaffoldSubTypeID}, existingTypeModifier: ${existingTypeModifier}`
      );
      if (!existingTypeModifier?.id?.length) return;

      if (
        await createEstimateComponentNewDialogForJob(
          this.$route.params.id,
          existingTypeModifier.id,
          scaffoldTypeID,
          scaffoldSubTypeID
        )
      ) {
        this.loadWorkingData();
      }
    },

    async editItem(item: JobWorkingComponent) {
      console.log(`JobEstimateComponents.editItem`);
      // Spread the item into a new obj so the dialog doesn't edit the actual item, which allows the user to cancel their changes
      if (
        await createEstimateComponentModifyDialogForJob(this.$route.params.id, {
          ...item
        } as JobWorkingComponent)
      ) {
        this.loadWorkingData();
      }
    },
    async reAddItem(item: JobWorkingComponent) {
      console.log(`JobEstimateComponents.reAddItem`);
      // Spread the item into a new obj so the dialog doesn't edit the actual item, which allows the user to cancel their changes
      if (
        await createEstimateComponentModifyDialogForJob(this.$route.params.id, {
          ...item
        } as JobWorkingComponent)
      ) {
        this.loadWorkingData();
      }
    },
    async deleteItem(item: JobWorkingComponent) {
      console.log(`JobEstimateComponents.deleteItem`);

      if (await jobWorkingEstimateService.removeWorkingComponent(item.id!)) {
        this.loadWorkingData();
      }
    },
    async openWorkingTakeoffDialog() {
      await createWorkingEstimateTakeoffDialogForJob(this.$route.params.id);
    },
    async generateSnapshot() {
      this.processing = true;
      this.generatingSnapshot = true;
      try {
        var blob = await jobEstimateSnapshotService.generateAndUploadNewEstimateSnapshotForJobWithID(
          this.$route.params.id,
          this.downloadFileWhenGenerated
        );
        if (this.downloadFileWhenGenerated && !!blob) {
          let tag = formatJobNumber(this.job.internalNumber);
          if (!!this.job.scaffoldNumber) {
            tag = `T${`00000${this.job.scaffoldNumber}`.slice(-5)}`;
          }
          downloadBlob(blob, `job-estimate-${tag}.xlsx`);
        }
        await Promise.all([this.loadWorkingData(), this.loadEstimateHistory()]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.generatingSnapshot = false;
      }
    },
    // DOES NOT manage processing or error message logic
    async loadRequestors(): Promise<void> {
      let requestors = await personService.getAllActiveRequestors();
      this.allRequestors = SortItemsWithName(
        requestors.map(x => {
          return {
            ...x,
            name: GetPersonName(x)
          };
        })
      );
    },
    async loadScaffolds(searchString: string) {
      if (!searchString?.length) this.availableScaffolds = [];
      else {
        let scaffolds = await scaffoldService.searchAll(searchString, false);
        this.availableScaffolds = scaffolds.map(x => {
          return {
            ...x,
            description: GetScaffoldDescription(x),
            details: GetScaffoldDetails(x),
            search: `${x.internalNumber} ${x.existingRequestNumber} ${
              x.externalReferenceNumber
            } ${x.subAreaName ?? x.areaName}`
          } as ScaffoldSearchResult;
        });
      }
    },
    async loadIsos(searchString: string) {
      if (!searchString?.length) this.availableIsos = [];
      else {
        let isos = await isoService.searchAll(searchString);
        this.availableIsos = isos.map(x => {
          return {
            ...x,
            description: x.name,
            details: GetIsoDetails(x),
            search: `${x.name} ${x.testPackageName} ${x.systemName}`
          } as IsoSearchResult;
        });
      }
    },
    async loadScaffoldDesigns() {
      if (!!this.availableDesigns?.length) return;

      let designs = await scaffoldDesignService.getAllReleased();
      this.availableDesigns = designs.map(
        d =>
          ({
            ...d,
            formattedScaffoldNumber: `T-${`00000${d.scaffoldNumber}`.slice(-5)}`
          } as FormattedScaffoldDesign)
      );
    },

    // *** IWPs ***
    async loadWorkPackages(searchString: string) {
      if (!searchString?.length) this.availableIWPs = [];
      else {
        let allIWPs = await workPackageService.searchAll(searchString);
        this.availableIWPs = allIWPs.map(x => {
          return {
            ...x,
            nameCode: `${x.name} | ${x.activityID}`
          };
        });
      }
    },
    //#endregion

    /*** NOTES ***/
    async addNewNote() {
      if (!this.newNoteText.length || !this.job?.id?.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newNote = await noteService.addNewNoteForJob(this.newNoteText, this.job.id);
        this.inlineMessage.message = "";
        let noteToAdd = ParseNoteWithSenderDetails(newNote);
        noteToAdd.isNew = true;
        this.notes.push(noteToAdd);
        this.notes = SortNotesArray(this.notes);
        this.newNoteText = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    /*** MESSAGES ***/
    async addNewMessage() {
      if (!this.newMessageText.length || !this.job?.id?.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newMessage = await messageService.addNewMessageForJob(this.newMessageText, this.job.id);
        this.inlineMessage.message = "";
        this.messages.push(ParseMessageWithSenderDetails(newMessage, this.curUserID));
        this.messages = SortMessagesArray(this.messages);
        this.newMessageText = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // *** ATTACHMENTS ***
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: AttachmentWithBlobFile) {
      if (!item.canOpenInNew) return;

      if (!!item.blobFile && item.canOpenInNew) {
        await this.openFileInNewWindow(item.blobFile);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async editAttachment(item: AttachmentWithBlobFile) {
      console.log(`editAttachment item: ${item}, blobFile: ${item.blobFile}`);
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.blobFile && item.blobFile.isPreviewable) {
        await this.editFile(item.blobFile);
      } else if (!!item.blobFile) {
        await this.editNameForFile(item.blobFile);
      }
    },
    async deleteAttachment(item: AttachmentWithBlobFile) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.blobFile) {
        await this.deleteFile(item.blobFile);
      }
    },
    async downloadAttachment(item: AttachmentWithBlobFile) {
      if (!!item.blobFile) {
        await this.downloadFile(item.blobFile);
      }
    },

    // Links
    async loadLinks() {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        var links = await externalLinkService.getByJobID(this.job.id!);
        this.externalLinks = links;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        newLink.jobID = this.job.id;
        await externalLinkService.addItem(newLink);
        this.externalLinks.push(newLink);

        this.showAttachmentTabPhotoAlert = false;
        this.showPhotoTabAttachmentAlert = false;

        var snackbarPayload = {
          text: this.$t("job.existing.save-link-success", [newLink.name]),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = newLink.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        let currentProcessing = this.processing;
        this.processing = true;
        try {
          await externalLinkService.updateItem(link.id!, {
            ...link,
            name: editedLink.name,
            address: editedLink.address
          });
          link.name = editedLink.name;
          link.address = editedLink.address;

          var snackbarPayload = {
            text: this.$t("job.existing.update-link-success", [link.name]),
            type: "success"
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          this.touchedFileName = link.name ?? "";
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = currentProcessing;
        }
      }
    },
    async deleteLink(link: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        await externalLinkService.deleteItem(link.id!);
        this.externalLinks.splice(this.externalLinks.indexOf(link), 1);

        var snackbarPayload = {
          text: this.$t("job.existing.delete-link-success", [link.name]),
          type: "info",
          undoCallback: async () => {
            await this.saveNewExternalLink(link);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = link.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },

    // #region Files & Photos
    fileRowClassName(item: any): string {
      return item.name == this.touchedFileName ? "fd-selected-table-row-background" : "";
    },
    photosRowClicked(e: Event, data: any) {
      this.viewPhoto(data.item);
    },
    async loadIsoFiles() {
      if (this.job.isoID != null) {
        var files = await blobFileService.getByIsoID(this.job.isoID!);
        this.isoFiles = files.map(file => {
          var fileName = file.name ?? "";
          return {
            ...file,
            isIsoFile: true,
            isPreviewable: isFilePreviewable(fileName),
            isPhoto: isFilePhoto(fileName)
          } as BlobFileData;
        });
      }
    },
    async loadFiles() {
      var fileNames = await blobFileService.getByJobID(this.job.id!);
      this.allFiles = fileNames.map(file => {
        var fileName = file.name ?? "";
        return {
          ...file,
          isIsoFile: false,
          isPreviewable: isFilePreviewable(fileName),
          isPhoto: isFilePhoto(fileName)
        } as BlobFileData;
      });
      await this.loadIsoFiles();
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async fileInputChanged(v: any) {
      if (!v.target?.files.length) return;
      await this.selectNewFile(v.target.files[0]);
    },
    async selectNewFile(originalFile: File) {
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      if (!fileData) return;

      // GIF files with animations will lose their animation during this process
      // Both due to the quality compression done above (resizing the dimensions of an animated GIF does nothing), and also going through the edit image process
      // This is OK as we shouldn't need animations for any reason
      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        await this.saveNewFileData(fileData);
      }
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        await this.saveNewFileData(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: BlobFileData, originalFileName: string) {
      if (!fileData) return;
      if (fileData.isIsoFile) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // If we're only renaming the file, the data may not be downloaded yet
          let fileNameToDownload = originalFileName ?? fileData.name;
          fileData.file = await blobFileService.downloadFile(fileData.id!);
        }
        await blobFileService.uploadJobFile(this.job.id!, fileData.name, fileData.file as Blob);

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await blobFileService.deleteJobFile(fileData.id!);
        }

        let snackbarText = fileData.isPhoto
          ? this.$t("job.existing.update-photo-success", [fileData.name])
          : this.$t("job.existing.update-file-success", [fileData.name]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData | undefined) {
      await this.saveNewFile(fileData?.name, fileData?.file);
    },
    async saveNewFile(name: string | null | undefined, file?: Blob | undefined) {
      if (!name || !file) return;

      this.processing = true;
      try {
        var newID = await blobFileService.uploadJobFile(this.job.id!, name, file as Blob);

        var blobFile = {
          id: newID,
          jobID: this.job.id!,
          name: name
        } as BlobFile;
        var fileData = {
          ...blobFile,
          isIsoFile: false,
          name: name,
          file: file,
          isPreviewable: isFilePreviewable(name),
          isPhoto: isFilePhoto(name)
        };
        this.allFiles.push(fileData);

        let snackbarText = fileData.isPhoto
          ? this.$t("job.existing.save-photo-success", [fileData.name])
          : this.$t("job.existing.save-file-success", [fileData.name]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.touchedFileName = fileData.name;
        this.showPhotoTabAttachmentAlert = this.selectedTab == this.photosTab && !fileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.selectedTab == this.attachmentsTab && fileData.isPhoto == true;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async editNameForFile(fileData: BlobFileData) {
      console.log(`editNameForFile fileData: ${fileData}`);
      if (fileData.isIsoFile) return;

      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.saveEditedFileData(fileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    editFile(fileData: BlobFileData) {
      if (fileData.isIsoFile) return;
      if (!fileData.isPhoto) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      if (!!fileData.file) {
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.editImageSource = `/services/FormidableDesigns.Services.V1.BlobFileService.DownloadFile?blobID=${fileData.id}`;
      }
    },
    async downloadFile(fileData: BlobFileData) {
      if (!!fileData.file) {
        downloadBlob(fileData.file, fileData.name);
        return;
      }

      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await blobFileService.downloadFile(fileData.id!);
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openFileInNewWindow(fileData: BlobFileData) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        if (!fileData.file) {
          // the data probably hasn't been downloaded yet
          fileData.file = await blobFileService.downloadFile(fileData.id!);
          if (!fileData.file) {
            this.inlineMessage.type = "error";
            this.inlineMessage.message = this.$t("job.existing.cant-find-file");
            return;
          }
        }
        let url = URL.createObjectURL(fileData.file);
        window.open(url, "_blank");
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    async viewPhoto(fileData: BlobFileData) {
      if (!fileData.isPreviewable) return;

      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        var file = await blobFileService.downloadFile(fileData.id!);
        fileData.file = file;
      }
      if (!!fileData.file) {
        this.imageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.imageSource = `/services/FormidableDesigns.Services.V1.BlobFileService.DownloadFile?blobID=${fileData.id}`;
      }
    },
    async deleteFile(fileData: BlobFileData) {
      if (fileData.isIsoFile) return;
      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await blobFileService.downloadFile(fileData.id!);
        }
        await blobFileService.deleteJobFile(fileData.id!);

        this.allFiles.splice(this.allFiles.indexOf(fileData), 1);

        let snackbarText = fileData.isPhoto
          ? this.$t("job.existing.delete-photo-success", [fileData.name])
          : this.$t("job.existing.delete-file-success", [fileData.name]);
        var snackbarPayload = {
          text: snackbarText,
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    attachmentRowClicked(e: Event, data: any) {
      this.openAttachment(data.item);
    }
    // #endregion
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "job-existing",
      parentalContext: `${this.jobTypeName}jobs`,
      searchStringForFiltering: "",
      selectedTab: this.firstTabKey
    });

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != `/${this.jobTypeName}`) {
      this.notifyNewBreadcrumb({
        text: this.$t(`job.${this.jobTypeName}.list.title`),
        to: `/${this.jobTypeName}`,
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }
    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true
    });

    this.processing = true;

    try {
      await Promise.all([
        this.loadVisibleAreas(),
        this.loadVisibleSubAreas(),
        this.loadContractors(),
        this.loadAllContractors(),
        this.loadDisciplines(),
        this.loadRequestors(),
        this.$store.dispatch("LOAD_TAGS"),
        this.loadRequestTypes(),
        this.loadRequestSubTypes(),
        this.loadPurchaseOrders({
          forcedArchivedState: false,
          archivedFromDate: null,
          archivedToDate: null
        }),
        this.loadScaffoldTypeModifiers()
      ]);
      // processing has been set to false after the reference data loaded.
      this.processing = true;
      await this.loadJobDetails();
      // Types did an initial load so the data is available for the lists when the request loads
      // But once we know what kind of request this is, we reload them to restrict the types based on the request type (scaff/maint/ins/paint)
      await this.loadRequestTypes();
      // Processing has automatically been set to false after this load happens
      this.processing = true;

      await Promise.all([this.loadWorkingData(), this.loadEstimateHistory()]);

      // Requested By ID is required (which in turn requires a discipline and contractor) so this should never happen
      // However, contractor is required and can be hidden and therefore if empty the user cannot fix it themselves.
      if (!this.job.contractorID && !this.detailsReadonly && !!this.curUserAccess.homeContractorID)
        this.job.contractorID = this.curUserAccess.homeContractorID;

      if (!!this.job.scaffoldID) {
        await this.loadScaffolds(`${this.job.scaffoldID}`);
        this.selectedScaffold = this.availableScaffolds.find(x => x.id == this.job.scaffoldID);
        this.dataSetByScaffold = true;
      }

      if (!!this.job.isoID) {
        await this.loadIsos(`${this.job.isoID}`);
        this.selectedIso = this.availableIsos.find(x => x.id == this.job.isoID);
      }

      if (!!this.job.scaffoldDesignID) {
        await this.loadScaffoldDesigns();
        this.selectedDesign = this.availableDesigns.find(x => x.id == this.job.scaffoldDesignID);
        this.dataSetByDesign = true;
      }

      this.selectedKeywords = this.job.tagIDs
        ? (this.job.tagIDs
            .map(x => this.allKeywords.find(y => y.id == x))
            .filter(x => !!x) as Keyword[])
        : [];

      // The control displaying Work Packages NEEDS data to exist in its source in order to be displayed in the control
      // Therefore, we start by loading all referenced IWPs from the request into the source data
      if (this.job.workPackageIDs?.length) {
        let iwps = [] as WorkPackageWithNameCode[];
        for (const iwpID of this.job.workPackageIDs) {
          let iwpsForID = await workPackageService.searchAll(iwpID);
          iwps = iwps.concat(
            iwpsForID.map(x => {
              return { ...x, nameCode: `${x.name} | ${x.activityID}` };
            })
          );
        }
        this.availableIWPs = iwps;

        this.selectedIWPs = this.availableIWPs.filter(iwp => {
          return this.job.workPackageIDs && this.job.workPackageIDs.includes(iwp.id!);
        }, this);
      }

      this.processing = true;
      let messages = await messageService.getForJob(this.job.id!);
      this.messages = SortMessagesArray(messages).map(x =>
        ParseMessageWithSenderDetails(x, this.curUserID)
      );
      let notes = await noteService.getForJob(this.job.id!);
      this.notes = notes.map(x => ParseNoteWithSenderDetails(x));
      if (!!this.job.notes?.length) {
        this.notes.push({
          isPinned: true,
          isNew: false,
          initials: "",
          name: `${this.$t("scaffold-requests.notes")}`,
          role: "",
          date: "",
          time: "",
          text: this.job.notes,
          sender: undefined,
          id: undefined,
          noteThreadID: undefined,
          personID: undefined,
          sentTime: new Date(0),
          archivedDate: undefined,
          created: undefined,
          createdBy: undefined,
          updated: undefined,
          updatedBy: undefined
        });
      }
      this.notes = SortNotesArray(this.notes);

      await this.loadFiles();
      await this.loadLinks();

      this.screenLoaded = true;
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

