var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "px-0 mx-0" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-radio-group",
                {
                  attrs: { row: "", "hide-details": "" },
                  model: {
                    value: _vm.insulationTargetValue,
                    callback: function($$v) {
                      _vm.insulationTargetValue = $$v
                    },
                    expression: "insulationTargetValue"
                  }
                },
                [
                  _c("v-radio", { attrs: { label: "Pipe", value: "pipe" } }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: { label: "Equipment", value: "equipment" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-1", attrs: { cols: "8" } },
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { row: "", "hide-details": "" },
                          model: {
                            value: _vm.tempTypeValue,
                            callback: function($$v) {
                              _vm.tempTypeValue = $$v
                            },
                            expression: "tempTypeValue"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: { label: "Hot", value: "hot" }
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: { label: "Cold", value: "cold" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pl-3", attrs: { cols: "4" } },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog-insulation.temperature"
                          ),
                          value: _vm.temperature,
                          "hide-details": ""
                        },
                        on: { input: v => _vm.$emit("update:temperature", v) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t(
                        "norms.work-order-entry-dialog-insulation.service-type"
                      ),
                      items: _vm.serviceTypes,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.serviceTypeID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:serviceTypeID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("Service Type"),
                      value: _vm.$lookup.serviceType(_vm.serviceTypeID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-1", attrs: { cols: "4" } },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog-insulation.thickness"
                          ),
                          value: _vm.thickness,
                          "hide-details": ""
                        },
                        on: { input: v => _vm.$emit("update:thickness", v) }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pl-3", attrs: { cols: "4" } },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog-insulation.layers"
                          ),
                          value: _vm.layers,
                          "hide-details": ""
                        },
                        on: { input: v => _vm.$emit("update:layers", v) }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pl-3", attrs: { cols: "4" } },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog-insulation.linear-feet"
                          ),
                          value: _vm.linearFeet,
                          "hide-details": ""
                        },
                        on: { input: v => _vm.$emit("update:linearFeet", v) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t(
                        "norms.work-order-entry-dialog-insulation.insulation-type"
                      ),
                      items: _vm.insulationTypes,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.insulationTypeID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:insulationTypeID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t(
                        "norms.work-order-entry-dialog-insulation.insulation-type"
                      ),
                      value: _vm.$lookup.insulationType(_vm.insulationTypeID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-radio-group",
                {
                  attrs: { row: "", "hide-details": "" },
                  model: {
                    value: _vm.blanketsOrTestPlugValue,
                    callback: function($$v) {
                      _vm.blanketsOrTestPlugValue = $$v
                    },
                    expression: "blanketsOrTestPlugValue"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: { label: "Blankets", value: "blankets" }
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: { label: "Test Plug", value: "testplug" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t("Finish Type"),
                      items: _vm.finishTypes,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.finishTypeID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:finishTypeID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("Finish Type"),
                      value: _vm.$lookup.finishType(_vm.finishTypeID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t("Tank Type"),
                      items: _vm.tankTypes,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.tankTypeID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:tankTypeID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("Tank Type"),
                      value: _vm.$lookup.tankType(_vm.tankTypeID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t("Metal Type"),
                      items: _vm.metalTypes,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.metalTypeID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:metalTypeID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("Metal Type"),
                      value: _vm.$lookup.metalType(_vm.metalTypeID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t("Exchanger Type"),
                      items: _vm.exchangerTypes,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.exchangerTypeID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:exchangerTypeID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("Exchanger Type"),
                      value: _vm.$lookup.exchangerType(_vm.exchangerTypeID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t("Metal Finish Size"),
                      items: _vm.metalFinishSizes,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.metalFinishSizeID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:metalFinishSizeID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("MetalFinish Size"),
                      value: _vm.$lookup.metalFinishSize(_vm.metalFinishSizeID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("fd-text-field", {
                attrs: {
                  disabled: _vm.processing,
                  readonly: !_vm.canEditBuildSheet,
                  label: _vm.$t("Equipment Number"),
                  value: _vm.equipmentNumber,
                  "hide-details": ""
                },
                on: { input: v => _vm.$emit("update:equipmentNumber", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c("fd-text-field", {
                staticClass: "mb-3",
                attrs: {
                  disabled: _vm.processing,
                  readonly: !_vm.canEditBuildSheet,
                  label: _vm.$t("norms.work-order-entry-dialog.notes"),
                  value: _vm.notes,
                  "hide-details": ""
                },
                on: { input: v => _vm.$emit("update:notes", v) }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("sp-build-sheet-norm-form", {
        attrs: {
          processing: _vm.processing,
          normGroupsWithResponses: _vm.normGroupsWithResponses,
          canEditBuildSheetResponses: _vm.canEditBuildSheetResponses
        },
        on: {
          "update:normGroupsWithResponses": function($event) {
            _vm.normGroupsWithResponses = $event
          },
          "update:norm-groups-with-responses": function($event) {
            _vm.normGroupsWithResponses = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }