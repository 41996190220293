var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-data-table", {
    directives: [
      {
        name: "fd-column",
        rawName:
          "v-fd-column:[iconColumnArgument].no-sort.class_fd-estimate-component-status-cell.hide-when-small",
        value: "",
        expression: "\n    ''\n  ",
        arg: _vm.iconColumnArgument,
        modifiers: {
          "no-sort": true,
          "class_fd-estimate-component-status-cell": true,
          "hide-when-small": true
        }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:scaffoldTypeID.hide-when-medium",
        value: _vm.$t(
          "scheduler.estimates.components-panel.scaffold-type-label"
        ),
        expression:
          "\n    $t('scheduler.estimates.components-panel.scaffold-type-label')\n  ",
        arg: "scaffoldTypeID",
        modifiers: { "hide-when-medium": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:scaffoldSubTypeID",
        value: _vm.$t(
          "scheduler.estimates.components-panel.scaffold-sub-type-label"
        ),
        expression:
          "\n    $t('scheduler.estimates.components-panel.scaffold-sub-type-label')\n  ",
        arg: "scaffoldSubTypeID"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:length",
        value: _vm.$t("scheduler.estimates.components-panel.length-label"),
        expression: "$t('scheduler.estimates.components-panel.length-label')",
        arg: "length"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:width",
        value: _vm.$t("scheduler.estimates.components-panel.width-label"),
        expression: "$t('scheduler.estimates.components-panel.width-label')",
        arg: "width"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:height",
        value: _vm.$t("scheduler.estimates.components-panel.height-label"),
        expression: "$t('scheduler.estimates.components-panel.height-label')",
        arg: "height"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:factor1.hide-when-large",
        value: _vm.$t("scheduler.estimates.components-panel.supervision-label"),
        expression:
          "\n    $t('scheduler.estimates.components-panel.supervision-label')\n  ",
        arg: "factor1",
        modifiers: { "hide-when-large": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:factor2.hide-when-large",
        value: _vm.$t("scheduler.estimates.components-panel.yard-label"),
        expression: "$t('scheduler.estimates.components-panel.yard-label')",
        arg: "factor2",
        modifiers: { "hide-when-large": true }
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:estimatedTotalMobilizationMinutes.hide-when-small",
        value: _vm.$t(
          "scheduler.estimates.components-panel.mobilization-label"
        ),
        expression:
          "\n    $t('scheduler.estimates.components-panel.mobilization-label')\n  ",
        arg: "estimatedTotalMobilizationMinutes",
        modifiers: { "hide-when-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalErectMinutes.hide-when-extra-small",
        value: _vm.$t("scheduler.estimates.components-panel.erect-label"),
        expression:
          "\n    $t('scheduler.estimates.components-panel.erect-label')\n  ",
        arg: "estimatedTotalErectMinutes",
        modifiers: { "hide-when-extra-small": true }
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:estimatedTotalDismantleMinutes.hide-when-extra-small",
        value: _vm.$t("scheduler.estimates.components-panel.dismantle-label"),
        expression:
          "\n    $t('scheduler.estimates.components-panel.dismantle-label')\n  ",
        arg: "estimatedTotalDismantleMinutes",
        modifiers: { "hide-when-extra-small": true }
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:estimatedTotalDemobilizationMinutes.hide-when-small",
        value: _vm.$t(
          "scheduler.estimates.components-panel.demobilization-label"
        ),
        expression:
          "\n    $t('scheduler.estimates.components-panel.demobilization-label')\n  ",
        arg: "estimatedTotalDemobilizationMinutes",
        modifiers: { "hide-when-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalHoardingMinutes.hide-when-small",
        value: _vm.$t("scheduler.estimates.components-panel.hoarding-label"),
        expression:
          "\n    $t('scheduler.estimates.components-panel.hoarding-label')\n  ",
        arg: "estimatedTotalHoardingMinutes",
        modifiers: { "hide-when-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalModifyMinutes.hide-when-large",
        value: _vm.$t("scheduler.estimates.components-panel.modify-label"),
        expression:
          "\n    $t('scheduler.estimates.components-panel.modify-label')\n  ",
        arg: "estimatedTotalModifyMinutes",
        modifiers: { "hide-when-large": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalTime",
        value: _vm.$t("scheduler.estimates.components-panel.total-label"),
        expression: "$t('scheduler.estimates.components-panel.total-label')",
        arg: "estimatedTotalTime"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalWeight.hide-when-large",
        value: _vm.$t("scheduler.estimates.components-panel.weight-label"),
        expression:
          "\n    $t('scheduler.estimates.components-panel.weight-label')\n  ",
        arg: "estimatedTotalWeight",
        modifiers: { "hide-when-large": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalPartCount.hide-when-large",
        value: _vm.$t("scheduler.estimates.components-panel.pieces-label"),
        expression:
          "\n    $t('scheduler.estimates.components-panel.pieces-label')\n  ",
        arg: "estimatedTotalPartCount",
        modifiers: { "hide-when-large": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedErectMPP.hide-when-large",
        value: _vm.$t("scheduler.estimates.components-panel.erect-mpp-label"),
        expression:
          "\n    $t('scheduler.estimates.components-panel.erect-mpp-label')\n  ",
        arg: "estimatedErectMPP",
        modifiers: { "hide-when-large": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedDismantleMPP.hide-when-large",
        value: _vm.$t(
          "scheduler.estimates.components-panel.dismantle-mpp-label"
        ),
        expression:
          "\n    $t('scheduler.estimates.components-panel.dismantle-mpp-label')\n  ",
        arg: "estimatedDismantleMPP",
        modifiers: { "hide-when-large": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:actions.no-sort.hide-when-header-text-empty",
        value: _vm.hideActions ? "" : _vm.$t("common.actions"),
        expression: "\n    hideActions ? '' : $t('common.actions')\n  ",
        arg: "actions",
        modifiers: { "no-sort": true, "hide-when-header-text-empty": true }
      }
    ],
    ref: "estimatecomponentstable",
    staticClass: "fd-estimate-components-list",
    attrs: {
      items: _vm.items,
      "items-per-page": -1,
      "footer-props": { "items-per-page-options": [-1] },
      "hide-default-footer": "",
      loading: _vm.loading,
      "loading-text": _vm.$t("common.table-loading-message"),
      "item-class": _vm.itemClassName,
      "mobile-breakpoint": "0",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([
      {
        key: "item.hasStatusChange",
        fn: function({ item }) {
          return [
            !!item.isRemoved
              ? _c(
                  "v-chip",
                  { staticClass: "px-2", attrs: { small: "", color: "error" } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("work-order-estimates.component-status.removed")
                      )
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !!item.isNew
              ? _c(
                  "v-chip",
                  {
                    staticClass: "px-2",
                    attrs: { small: "", color: "primary" }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("work-order-estimates.component-status.added")
                      )
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !!item.isModified
              ? _c("v-chip", { staticClass: "px-2", attrs: { small: "" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("work-order-estimates.component-status.modified")
                    )
                  )
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "item.scaffoldTypeID",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.$t(
                  `work-order-estimates.scaffold-types.${item.scaffoldTypeID}`
                )
              }
            })
          ]
        }
      },
      {
        key: "item.scaffoldSubTypeID",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.$t(
                  `work-order-estimates.scaffold-sub-types.${item.scaffoldSubTypeID}`
                )
              }
            })
          ]
        }
      },
      {
        key: "item.length",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.$format.number(item.length, 0)
              }
            })
          ]
        }
      },
      {
        key: "item.width",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.$format.number(item.width, 0)
              }
            })
          ]
        }
      },
      {
        key: "item.height",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.$format.number(item.height, 0)
              }
            })
          ]
        }
      },
      {
        key: "item.factor1",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.$format.percentage(item.factor1),
                "no-value-text": "0%"
              }
            })
          ]
        }
      },
      {
        key: "item.factor2",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.$format.percentage(item.factor2),
                "no-value-text": "0%"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalMobilizationMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.getTimeDisplay(
                  item.estimatedTotalMobilizationMinutes
                ),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalErectMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.getTimeDisplay(item.estimatedTotalErectMinutes),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalDismantleMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.getTimeDisplay(item.estimatedTotalDismantleMinutes),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalDemobilizationMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.getTimeDisplay(
                  item.estimatedTotalDemobilizationMinutes
                ),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalHoardingMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.getTimeDisplay(item.estimatedTotalHoardingMinutes),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalModifyMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.getTimeDisplay(item.estimatedTotalModifyMinutes),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalTime",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.getTimeDisplay(item.estimatedTotalTime),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalWeight",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.$format.number(item.estimatedTotalWeight, 0),
                "no-value-text": "0"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalPartCount",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.$format.number(item.estimatedTotalPartCount, 0),
                "no-value-text": "0"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedErectMPP",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.$format.number(item.estimatedErectMPP),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedDismantleMPP",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                loading: _vm.loading,
                value: _vm.$format.number(item.estimatedDismantleMPP),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.actions",
        fn: function({ item }) {
          return [
            _c(
              "div",
              { staticClass: "table-actions-column-min-width" },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      disabled: !_vm.$vuetify.breakpoint.lgAndUp || _vm.loading,
                      left: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    class: { "d-none": !!item.isExcluded },
                                    attrs: { icon: "", disabled: _vm.loading }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        disabled: _vm.loading
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.editTableItem(item)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              mdi-pencil\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      disabled: !_vm.$vuetify.breakpoint.lgAndUp || _vm.loading,
                      left: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    class: { "d-none": !item.isExcluded },
                                    attrs: { icon: "", disabled: _vm.loading }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        disabled: _vm.loading
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.undeleteTableItem(item)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              mdi-undo-variant\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("common.re-add")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      disabled: !_vm.$vuetify.breakpoint.lgAndUp || _vm.loading,
                      top: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: { icon: "", disabled: _vm.loading }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        disabled:
                                          _vm.loading ||
                                          !!item.isRemoved ||
                                          !!item.isExcluded
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteTableItem(item)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              mdi-delete\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("common.remove")))])
                  ]
                )
              ],
              1
            )
          ]
        }
      },
      {
        key: "body.append",
        fn: function({ items }) {
          return [
            !!items.length && !_vm.hideTotalRow
              ? _c("tr", { staticClass: "fd-table-summary-row" }, [
                  _c(
                    "td",
                    {
                      staticClass: "text-start",
                      attrs: { colspan: _vm.numberOfPreTimeTableColumns }
                    },
                    [
                      _c("fd-alert", {
                        staticClass: "pa-0 mt-0",
                        attrs: {
                          dense: true,
                          label: _vm.$t(
                            "timesheets.existing.group-totals-label"
                          ),
                          type: "hint",
                          white: ""
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "td",
                        [
                          _c("fd-value-display", {
                            staticStyle: { display: "inline" },
                            attrs: {
                              value: _vm.sumMinutes(
                                _vm.includedItems,
                                "estimatedTotalMobilizationMinutes"
                              ),
                              "no-value-text": "0.00",
                              "no-value-class": "white--text"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "td",
                        [
                          _c("fd-value-display", {
                            staticStyle: { display: "inline" },
                            attrs: {
                              value: _vm.sumMinutes(
                                _vm.includedItems,
                                "estimatedTotalErectMinutes"
                              ),
                              "no-value-text": "0.00",
                              "no-value-class": "white--text"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "td",
                        [
                          _c("fd-value-display", {
                            staticStyle: { display: "inline" },
                            attrs: {
                              value: _vm.sumMinutes(
                                _vm.includedItems,
                                "estimatedTotalDismantleMinutes"
                              ),
                              "no-value-text": "0.00",
                              "no-value-class": "white--text"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "td",
                        [
                          _c("fd-value-display", {
                            staticStyle: { display: "inline" },
                            attrs: {
                              value: _vm.sumMinutes(
                                _vm.includedItems,
                                "estimatedTotalDemobilizationMinutes"
                              ),
                              "no-value-text": "0.00",
                              "no-value-class": "white--text"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "td",
                        [
                          _c("fd-value-display", {
                            staticStyle: { display: "inline" },
                            attrs: {
                              value: _vm.sumMinutes(
                                _vm.includedItems,
                                "estimatedTotalHoardingMinutes"
                              ),
                              "no-value-text": "0.00",
                              "no-value-class": "white--text"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.xl
                    ? _c(
                        "td",
                        [
                          _c("fd-value-display", {
                            staticStyle: { display: "inline" },
                            attrs: {
                              value: _vm.sumMinutes(
                                _vm.includedItems,
                                "estimatedTotalModifyMinutes"
                              ),
                              "no-value-text": "0.00",
                              "no-value-class": "white--text"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("fd-value-display", {
                        staticStyle: { display: "inline" },
                        attrs: {
                          value: _vm.sumMinutes(
                            _vm.includedItems,
                            "estimatedTotalTime"
                          ),
                          "no-value-text": "0.00",
                          "no-value-class": "white--text"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.xl
                    ? _c(
                        "td",
                        [
                          _c("fd-value-display", {
                            staticStyle: { display: "inline" },
                            attrs: {
                              value: _vm.sum(
                                _vm.includedItems,
                                "estimatedTotalWeight",
                                0
                              ),
                              "no-value-text": "0",
                              "no-value-class": "white--text"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.xl
                    ? _c(
                        "td",
                        [
                          _c("fd-value-display", {
                            staticStyle: { display: "inline" },
                            attrs: {
                              value: _vm.sum(
                                _vm.includedItems,
                                "estimatedTotalPartCount",
                                0
                              ),
                              "no-value-text": "0",
                              "no-value-class": "white--text"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.xl
                    ? _c(
                        "td",
                        [
                          _c("fd-value-display", {
                            staticStyle: { display: "inline" },
                            attrs: {
                              value: _vm.totalErectMpp,
                              "no-value-text": "0.00",
                              "no-value-class": "white--text"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.xl
                    ? _c(
                        "td",
                        [
                          _c("fd-value-display", {
                            staticStyle: { display: "inline" },
                            attrs: {
                              value: _vm.totalDismantleMpp,
                              "no-value-text": "0.00",
                              "no-value-class": "white--text"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hideActions
                    ? _c("td", {
                        staticClass: "text-start",
                        attrs: { colspan: "1" }
                      })
                    : _vm._e()
                ])
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }