import { contractorNormGroupService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: contractorNormGroupService,
  singularStoreName: "CONTRACTOR_NORM_GROUP",
  pluralStoreName: "CONTRACTOR_NORM_GROUPS",
  localizationPrefix: "contractors.norm-groups",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "contractorNormGroupID",
    type: "string"
  }
});

export default storeModule;

