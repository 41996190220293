import FDVue from "@fd/lib/vue";
import { workOrderService } from "../../services";
import { openLabourEntryDialog } from "../components/dialogs/LabourEntryDialog.vue";
import { openForemanTimesheetDialog } from "../components/dialogs/ForemanTimesheetDialog.vue";
import {
  FormattedWorkOrder,
  FormattedWorkOrderFromWorkOrderWithAllDetails
} from "../components/KanbanItem.vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { openWorkOrderNormsEntryDialog } from "../components/dialogs/WorkOrderNormsEntryDialog.vue";

export default FDVue.extend({
  name: "fd-labour-entry-test",
  mixins: [serviceErrorHandling],
  data: function() {
    return {
      // workOrderID: "020cb9d2-42b1-ed11-a8e0-6045bd5c4277", // WO 42495 (MODIFY)
      // workOrderID: "9f1b707d-c4e1-44d5-a68f-f25e21abb240", // WO 45663 (ERECT)
      // workOrderID: "dc3bfd33-5d1e-427f-9040-2bed9393d5bc", // WO 45663 (MAINT)
      // workOrderID: "c95d8588-4f4b-49a7-867f-a1ee0fd5db04", // WO 45609 (ERECT)
      // workOrderID: "54e90106-c721-48ae-92f1-61c53a58e1cb", // WO 53201 (PAINT)
      workOrderID: "ecde2b5e-96ce-4c23-b5e5-40f77d87fa8b", // WO 53202 (INSULATION)
      workOrder: undefined as FormattedWorkOrder | undefined,
      isShowingDialog: false
    };
  },
  methods: {
    async showLabourEntryDialog() {
      if (this.isShowingDialog) return;
      await this.loadWorkOrder();
      if (!this.workOrder) return;

      this.isShowingDialog = true;
      await openLabourEntryDialog(this.workOrder);
      this.isShowingDialog = false;
    },
    async loadWorkOrder() {
      if (this.workOrder?.id == this.workOrderID) return;

      this.processing = true;
      try {
        let workOrder = await workOrderService.getWorkOrderByID(this.workOrderID);
        this.workOrder = FormattedWorkOrderFromWorkOrderWithAllDetails(workOrder);
        if (!this.isShowingDialog) this.showNormsEntryDialog();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async showForemanTimesheetDialog() {
      if (this.isShowingDialog) return;

      this.isShowingDialog = true;
      await openForemanTimesheetDialog(
        "673cf66b-6c25-ed11-9440-000d3ae989de",
        new Date(2022, 7, 30),
        undefined
      );
      this.isShowingDialog = false;
    },
    async showNormsEntryDialog() {
      if (this.isShowingDialog) return;
      await this.loadWorkOrder();
      if (!this.workOrder) return;

      this.isShowingDialog = true;
      await openWorkOrderNormsEntryDialog(this.workOrder);
      this.isShowingDialog = false;
    }
  },
  mounted: async function() {},
  created: async function() {
    this.loadWorkOrder();
  }
});

