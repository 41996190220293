var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-card",
            { attrs: { "data-cy": "workorderfastforwarddialog" } },
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("scheduler.printing.three-week-look-ahead")
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "pa-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-1 mx-0 mt-3",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("fd-date-picker", {
                            attrs: {
                              "data-cy": "date",
                              label: _vm.$t("common.date"),
                              disabled: _vm.processing,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.date,
                              callback: function($$v) {
                                _vm.date = $$v
                              },
                              expression: "date"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { align: "center", cols: "12" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              label: _vm.$t(
                                "configuration.labour.daily-work-hours"
                              ),
                              disabled: _vm.processing,
                              items: _vm.dayHourValues,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.dailyWorkHours,
                              callback: function($$v) {
                                _vm.dailyWorkHours = $$v
                              },
                              expression: "dailyWorkHours"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 pb-0 mb-0 pt-3",
                          attrs: { cols: "12" }
                        },
                        [_c("v-subheader", [_vm._v("Work Days")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { align: "center", cols: "6" } },
                        [
                          _c("v-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              label: _vm.$t(
                                "configuration.labour.monday-is-work-day"
                              ),
                              disabled: _vm.processing,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.mondayIsWorkDay,
                              callback: function($$v) {
                                _vm.mondayIsWorkDay = $$v
                              },
                              expression: "mondayIsWorkDay"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { align: "center", cols: "6" } },
                        [
                          _c("v-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              label: _vm.$t(
                                "configuration.labour.tuesday-is-work-day"
                              ),
                              disabled: _vm.processing,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.tuesdayIsWorkDay,
                              callback: function($$v) {
                                _vm.tuesdayIsWorkDay = $$v
                              },
                              expression: "tuesdayIsWorkDay"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { align: "center", cols: "6" } },
                        [
                          _c("v-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              label: _vm.$t(
                                "configuration.labour.wednesday-is-work-day"
                              ),
                              disabled: _vm.processing,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.wednesdayIsWorkDay,
                              callback: function($$v) {
                                _vm.wednesdayIsWorkDay = $$v
                              },
                              expression: "wednesdayIsWorkDay"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { align: "center", cols: "6" } },
                        [
                          _c("v-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              label: _vm.$t(
                                "configuration.labour.thursday-is-work-day"
                              ),
                              disabled: _vm.processing,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.thursdayIsWorkDay,
                              callback: function($$v) {
                                _vm.thursdayIsWorkDay = $$v
                              },
                              expression: "thursdayIsWorkDay"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { align: "center", cols: "6" } },
                        [
                          _c("v-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              label: _vm.$t(
                                "configuration.labour.friday-is-work-day"
                              ),
                              disabled: _vm.processing,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.fridayIsWorkDay,
                              callback: function($$v) {
                                _vm.fridayIsWorkDay = $$v
                              },
                              expression: "fridayIsWorkDay"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", { attrs: { align: "center", cols: "6" } }),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { align: "center", cols: "6" } },
                        [
                          _c("v-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              label: _vm.$t(
                                "configuration.labour.saturday-is-work-day"
                              ),
                              disabled: _vm.processing,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.saturdayIsWorkDay,
                              callback: function($$v) {
                                _vm.saturdayIsWorkDay = $$v
                              },
                              expression: "saturdayIsWorkDay"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { align: "center", cols: "6" } },
                        [
                          _c("v-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              label: _vm.$t(
                                "configuration.labour.sunday-is-work-day"
                              ),
                              disabled: _vm.processing,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.sundayIsWorkDay,
                              callback: function($$v) {
                                _vm.sundayIsWorkDay = $$v
                              },
                              expression: "sundayIsWorkDay"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-3", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "align-center justify-space-between pb-1 px-0 mx-0 mt-3",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("v-alert", { attrs: { type: "info" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "scheduler.printing.three-week-look-ahead-sunday-date-info-message",
                                [_vm.$format.date(_vm.calculatedSundayDate)]
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessage.message
                ? _c(
                    "v-row",
                    { staticClass: "mx-3", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "pb-3 px-3" },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "accept",
                        color: "primary",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.acceptDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.done")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }