import { PropType } from "vue";
import FDVue from "../../../../../lib/vue";
import {
  ScaffoldElevationModifier,
  ScaffoldRequestTypes,
  ScaffoldTypeModifier
} from "../../../services";
import { NormGroupWithNorms, NormWithResponse } from "./SP.BuildSheetNormForm.vue";

export default FDVue.extend({
  name: "sp-build-sheet-form",

  components: {
    "fp-add-remove-field": () => import("@fd/lib/vue/components/FP.AddRemoveField.vue"),
    "sp-build-sheet-norm-form": () => import("./SP.BuildSheetNormForm.vue")
  },
  props: {
    scaffoldTypeModifiers: { type: Array as PropType<Array<ScaffoldTypeModifier>> },
    scaffoldElevationModifiers: { type: Array as PropType<Array<ScaffoldElevationModifier>> },
    totalTimesheetHours: { type: Number },
    processing: { type: Boolean },

    normGroupsWithResponses: { type: Array as PropType<Array<NormGroupWithNorms>> },
    length: { type: Number },
    width: { type: Number },
    height: { type: Number },
    vlfChangeAmount: { type: Number },
    scaffoldTypeModifierID: { type: String },
    scaffoldElevationModifierID: { type: String },
    notes: { type: String },

    hazardStatusResult: { type: String },
    confinedSpace: { type: Boolean },
    ppeRequired: { type: Boolean },
    deckLevels: { type: Number },

    currentScaffoldVlf: { type: Number },
    scaffoldTotalVLF: { type: Number },
    scaffoldRequestType: { type: Number },
    canEditBuildSheetResponses: { type: Boolean }
  },
  data: function() {
    return {
      openPanels: [] as number[]
    };
  },
  computed: {
    managedNormGroupsWithResponses: {
      get(): Array<NormGroupWithNorms> {
        return this.normGroupsWithResponses;
      },
      set(val: Array<NormGroupWithNorms>) {
        this.$emit("update:normGroupsWithResponses", val);
      }
    },
    allNormsWithResponses(): Array<NormWithResponse> {
      return this.normGroupsWithResponses.reduce((responses, group) => {
        return responses.concat(group.normsWithResponses);
      }, [] as Array<NormWithResponse>);
    },
    vlfBeforeValue(): number {
      return this.currentScaffoldVlf ?? this.scaffoldTotalVLF ?? 0;
    },
    allowVLFAdd(): boolean {
      return (
        this.scaffoldRequestType == ScaffoldRequestTypes.Erect ||
        this.scaffoldRequestType == ScaffoldRequestTypes.Modify
      );
    },
    allowVLFRemove(): boolean {
      return (
        this.scaffoldRequestType == ScaffoldRequestTypes.Modify ||
        this.scaffoldRequestType == ScaffoldRequestTypes.Dismantle
      );
    },
    canEditBuildSheet(): boolean {
      return this.canEditBuildSheetResponses ?? false;
    },

    totalNormHours(): number {
      let total = this.allNormsWithResponses.reduce((total, norm) => {
        return total + (norm.hours ?? 0);
      }, 0);
      return total;
    }
  },
  methods: {}
});

