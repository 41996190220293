import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { ContractorWithTags, contractorService } from "../../../services";
import rules from "@fd/lib/vue/rules";

type RequestApprovalBottomDialogResult =
  | {
      contractorID: string;
      isUrgent: boolean;
      isUrgentDetail: string | undefined;
    }
  | undefined;

const RequestApprovalBottomDialog = FDVue.extend({
  name: "sp-request-approval-bottom-dialog",

  mixins: [dialogSupport, rules],

  components: {},

  data() {
    return {
      internalRequestNumber: null as string | number | null | undefined,

      contractors: null as ContractorWithTags[] | null,

      contractorID: "" as string,
      isUrgent: false as boolean,
      isUrgentDetail: undefined as string | undefined
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["open", "loadContractors"];
    },
    dialogRules(): any {
      return {
        contractorID: [this.rules.required],
        isUrgentDetail: this.isUrgent ? [this.rules.required] : []
      };
    }
  },

  mounted: async function() {},

  methods: {
    async loadContractors() {
      this.processing = true;
      try {
        this.contractors = await contractorService.getAll(false, null, null);
        if (
          !this.contractorID?.length &&
          this.contractors?.length == 1 &&
          !!(this.contractors[0] as any).id
        ) {
          this.contractorID = (this.contractors[0] as any).id;
        }
      } catch (error) {
      } finally {
        this.processing = false;
      }
    },
    async open(
      requestNumber: string | number | null | undefined,
      items?: ContractorWithTags[],
      selectedValue?: string,
      mountPoint?: Element | undefined
    ): Promise<RequestApprovalBottomDialogResult> {
      this.internalRequestNumber = requestNumber;
      if (!!selectedValue) this.contractorID = selectedValue;

      if (!items?.length) {
        this.loadContractors();
      } else {
        this.contractors = items;
        if (
          !this.contractorID?.length &&
          this.contractors?.length == 1 &&
          !!(this.contractors[0] as any).id
        ) {
          this.contractorID = (this.contractors[0] as any).id;
        }
      }

      if (await this.showDialog(mountPoint))
        return {
          contractorID: this.contractorID,
          isUrgent: this.isUrgent,
          isUrgentDetail: this.isUrgentDetail
        };
      else return undefined;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    acceptDialog() {
      if (!(this.$refs.form as HTMLFormElement).validate()) return;

      this.closeDialog(true);
    }
  }
});

export default RequestApprovalBottomDialog;

export async function showRequestApprovalBottomDialog(
  requestNumber: string | number | null | undefined,
  items?: ContractorWithTags[],
  selectedValue?: string,
  parent?: Vue | Element | null | undefined
): Promise<RequestApprovalBottomDialogResult> {
  let dialog = createDialog(RequestApprovalBottomDialog);
  dialog.optOutOfErrorHandling();
  let mountPoint = undefined;
  if (!!parent) {
    mountPoint = document.createElement("div");
    mountPoint.id = "mountPoint";
    if (!!(parent as Vue)) (parent as Vue).$el.appendChild(mountPoint);
    else if (!!(parent as Element)) (parent as Element).appendChild(mountPoint);
  }
  let result = await dialog.open(requestNumber, items, selectedValue, mountPoint);
  console.log("showRequestApprovalBottomDialog.result: " + JSON.stringify(result));
  return result;
}

