import FDVue from "@fd/lib/vue";
import {
  canOpenFileInNewWindow,
  FileData,
  isFilePhoto,
  isFilePreviewable
} from "@fd/lib/vue/mixins/fileHandling";
import { Attachment } from "../../dataMixins/attachment";
import {
  CountSheetReviewStatus,
  WalkdownStatuses,
  WorkOrderStatuses,
  ScaffoldRequestTypes,
  WorkOrderWithAllDetails,
  BlobFile
} from "../../services";
import { JoinNameValues } from "../../utils/person";
import { PropType } from "vue";

export type ScreenContext = "workOrder" | "walkdown";
export type ToDoListItem = {
  // Computed properties unique to this screen
  initialProgress: number; // Will be used to limit available Progress % options for a work order, allowing the user to change the percentage and then change it back in the same session
  order: number; // Allows the vertical re-ordering of cards within their same column
  type: ScreenContext; // Liste item type: Either work order or walkdown
  photos: BlobFileData[] | undefined;
  nonPhotoFiles: BlobFileData[] | undefined;
  attachments: AttachmentWithBlobFile[] | undefined;
  fullAreaName: string | undefined;
};
type BlobFileData = BlobFile &
  FileData & {
    isIsoFile: boolean;
  };
type AttachmentWithBlobFile = Attachment & {
  id: string | null | undefined;
  blobFile?: BlobFileData | undefined;
};
export type FormattedWorkOrder = WorkOrderWithAllDetails &
  ToDoListItem & { workPackageNames: string[] | undefined };

function compareFormattedWorkOrders(a: FormattedWorkOrder, b: FormattedWorkOrder): number {
  if (a.order != b.order) return a.order - b.order;

  var aPriority = !!a.priority && a.priority > 0 ? a.priority : 99;
  if (a.isUrgent) aPriority = 0;
  var bPriority = !!b.priority && b.priority > 0 ? b.priority : 99;
  if (b.isUrgent) bPriority = 0;
  if (aPriority != bPriority) return aPriority - bPriority;

  var aScaffold = Number(a.scaffoldNumber) ?? 0;
  var bScaffold = Number(b.scaffoldNumber) ?? 0;
  if (aScaffold != bScaffold) return aScaffold - bScaffold;

  return 0;
}
export function sortFormattedWorkOrderArray<T extends FormattedWorkOrder>(workOrders: T[]): T[] {
  return workOrders.sort(compareFormattedWorkOrders);
}
export function addFileDataToWorkOrder(workOrder: FormattedWorkOrder, fileData: FileData) {
  if (fileData.isPhoto || isFilePhoto(fileData.name)) {
    workOrder.photos = (workOrder.photos ?? []).concat([{ ...fileData } as BlobFileData]);
  } else {
    workOrder.nonPhotoFiles = (workOrder.nonPhotoFiles ?? []).concat([
      { ...fileData } as BlobFileData
    ]);
    let attachment = {
      type: "file",
      name: fileData.name,
      isPhoto: fileData.isPreviewable ?? false,
      isPreviewable: fileData.isPreviewable ?? false,
      canOpenInNew: canOpenFileInNewWindow(fileData.name),
      file: fileData
    } as Attachment;
    workOrder.attachments = (workOrder.attachments ?? []).concat([
      { ...attachment } as AttachmentWithBlobFile
    ]);
  }
}
export function FormattedWorkOrderFromWorkOrderWithAllDetails(
  workOrder: WorkOrderWithAllDetails
): FormattedWorkOrder {
  let allFiles =
    workOrder.fileNames?.map(
      fileName =>
        ({
          name: fileName,
          isPreviewable: isFilePreviewable(fileName),
          isPhoto: isFilePhoto(fileName)
        } as BlobFileData)
    ) ?? [];
  let isoFiles =
    workOrder.blobFiles?.map(file => {
      var fileName = file.name ?? "";
      return {
        ...file,
        isIsoFile: true,
        isPreviewable: isFilePreviewable(fileName),
        isPhoto: isFilePhoto(fileName)
      } as BlobFileData;
    }) ?? [];

  let photos = allFiles.filter(x => x.isPhoto == true);
  isoFiles
    .filter(x => !!x.isPhoto)
    .forEach(file => {
      photos.push(file);
    });
  let nonPhotoFiles = allFiles.filter(x => !x.isPhoto);

  // Attachments
  let nonPhotoAttachments =
    nonPhotoFiles?.map(
      file =>
        ({
          id: undefined,
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          file: file
        } as AttachmentWithBlobFile)
    ) ?? [];
  let linkAttachments =
    workOrder.externalLinks?.map(
      link =>
        ({
          id: link.id,
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        } as AttachmentWithBlobFile)
    ) ?? [];
  let allAttachments = nonPhotoAttachments.concat(linkAttachments);
  isoFiles
    .filter(x => !x.isPhoto)
    .forEach(file => {
      allAttachments.push({
        id: file.id,
        type: "file",
        name: file.name,
        isPhoto: file.isPreviewable ?? false,
        isPreviewable: file.isPreviewable ?? false,
        canOpenInNew: canOpenFileInNewWindow(file.name),
        blobFile: file
      });
    });
  return {
    ...workOrder,
    type:
      workOrder.workOrderStatus == WorkOrderStatuses.Walkdown ||
      workOrder.workOrderStatus == WorkOrderStatuses.Estimated
        ? "walkdown"
        : "workOrder",
    workPackageNames: workOrder.workPackages?.map(
      x => (x.name ?? "") + " | " + (x.activityID ?? "")
    ),
    progress: !!workOrder.progress
      ? Math.max(Math.min(100, Math.round(workOrder.progress / 5) * 5), 0)
      : 0, // The progress picker is in increments of 5 so round to the nearest 5
    initialProgress: workOrder.progress ?? 0,
    priority: !!workOrder.priority && workOrder.priority > 0 ? Math.min(workOrder.priority, 5) : 5,
    siteContact: workOrder.siteContact?.trim(),
    specificWorkLocation: workOrder.specificWorkLocation?.trim(),
    detailedWorkDescription: workOrder.detailedWorkDescription?.trim(),
    photos: photos,
    nonPhotoFiles: nonPhotoFiles,
    attachments: allAttachments,
    fullAreaName: JoinNameValues(workOrder.areaName, workOrder.subAreaName, " - ")
  } as FormattedWorkOrder;
}

export default FDVue.extend({
  name: "fd-kanban-item",
  components: {
    "fd-kanban-item-value-display": () => import("./KanbanItemValueDisplay.vue"),
    "fd-kanban-item-expander-details-item": () => import("./KanbanItemExpanderDetailsItem.vue")
  },
  props: {
    item: { type: Object as PropType<FormattedWorkOrder>, default: undefined },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    inProgress: { type: Boolean, default: false },
    preventLabourEntry: { type: Boolean, default: false },
    preventNormsEntry: { type: Boolean, default: false },
    preventScaffoldLocationEntry: { type: Boolean, default: false },
    notesCount: { type: Number, default: 0 }
  },
  data: function() {
    return {
      inProgressValues: Array.from(Array(101).keys())
        .filter(x => x % 5 == 0)
        .map(x => ({
          text: x,
          value: x,
          // disabled: x < item.initialProgress
          disabled: x == 0
        }))
    };
  },
  computed: {
    statusClass(): string {
      if (this.itemIsCancelled) return "fd-kanban-item-cancelled";
      else if (this.itemIsDone) return "fd-kanban-item-done";
      else if (this.itemIsDeclinedWalkdown) return "fd-drag-item-on-hold";
      else if (this.itemIsOnHoldWorkOrder) return "fd-drag-item-on-hold";
      else if (this.item.isUrgent) return "fd-drag-item-urgent";
      else if (this.inProgress) return "fd-kanban-item--inprogress";
      else return "fd-kanban-item--todo";
    },
    itemIsDeclinedWalkdown(): boolean {
      return (
        this.item.type == "walkdown" &&
        this.item.workOrderStatus == WorkOrderStatuses.Walkdown &&
        !!this.item.walkdown &&
        this.item.walkdown.walkdownStatus == WalkdownStatuses.Declined
      );
    },
    itemIsCancelledWorkOrder(): boolean {
      return (
        this.item.type == "workOrder" && this.item.workOrderStatus == WorkOrderStatuses.Cancelled
      );
    },
    itemIsCancelledWalkdown(): boolean {
      return (
        !!this.item.walkdown && this.item.walkdown.walkdownStatus == WalkdownStatuses.Cancelled
      );
    },
    itemIsCancelled(): boolean {
      return this.itemIsCancelledWalkdown || this.itemIsCancelledWorkOrder;
    },
    itemIsStartedWorkOrder(): boolean {
      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return toDoItem.type == "workOrder" && toDoItem.workOrderStatus == WorkOrderStatuses.Started;
    },
    itemIsOnHoldWorkOrder(): boolean {
      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return toDoItem.type == "workOrder" && toDoItem.workOrderStatus == WorkOrderStatuses.OnHold;
    },
    itemIsCompletedWorkOrder(): boolean {
      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return (
        toDoItem.type == "workOrder" &&
        (toDoItem.workOrderStatus == WorkOrderStatuses.Completed ||
          toDoItem.workOrderStatus == WorkOrderStatuses.CompletionPendingAdministration)
      );
    },
    itemIsCompletedWalkdown(): boolean {
      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return toDoItem.type == "walkdown" && toDoItem.workOrderStatus == WorkOrderStatuses.Estimated;
    },
    itemIsDone(): boolean {
      return this.itemIsCancelled || this.itemIsCompletedWorkOrder || this.itemIsCompletedWalkdown;
    },
    itemNeedsCallout(): boolean {
      return this.itemIsOnHoldWorkOrder || this.itemIsDeclinedWalkdown;
    },
    itemNeedsStatusCallout(): boolean {
      return (
        this.itemIsCancelled ||
        this.itemIsOnHoldWorkOrder ||
        this.itemIsDeclinedWalkdown ||
        (this.item.isUrgent ?? false)
      );
    },

    cardTitle(): String {
      let workOrderID = this.item.internalNumber;
      let workOrderNumber = this.$t("to-do-list.work-order-number", [workOrderID]);

      if (this.item.type == "workOrder") {
        let progress = this.inProgress ? ` (${this.item.progress}%) ` : "";
        return `${workOrderNumber}${progress}`;
      } else if (this.item.type == "walkdown") {
        return `${workOrderNumber}`;
      }
      return "";
    },

    cardTitleShort(): String {
      let workOrderID = this.item.internalNumber;
      let workOrderNumber = this.$t("to-do-list.work-order-number-short", [workOrderID]);

      if (this.item.type == "workOrder") {
        let progress = this.inProgress ? ` (${this.item.progress}%) ` : "";
        return `${workOrderNumber}${progress}`;
      } else if (this.item.type == "walkdown") {
        return `${workOrderNumber}`;
      }
      return "";
    },

    externalTagNumberText(): string | undefined {
      return this.item.scaffoldExternalReferenceNumber ?? this.item.existingTagNumber;
    },
    internalTagNumberText(): string | undefined {
      if (!!this.item.scaffoldNumber && this.item.scaffoldNumber > 0)
        return `${this.$t("to-do-list.tag-number", [this.item.scaffoldNumber])}`;
      else return undefined;
    },

    tagNumberText(): string | undefined {
      if (!!this.externalTagNumberText?.length) return this.externalTagNumberText;
      else if (!!this.internalTagNumberText?.length) return this.internalTagNumberText;
      else return undefined;
    },

    statusIndicatorText(): String {
      if (this.itemIsCancelled)
        return `${this.$t("to-do-list.cancelled-label-caps")}`.toUpperCase();
      else if (this.itemIsOnHoldWorkOrder)
        return `${this.$t("to-do-list.on-hold-label-caps")}`.toUpperCase();
      else if (this.itemIsDeclinedWalkdown)
        return `${this.$t("to-do-list.declined-label-caps")}`.toUpperCase();
      else if (this.item.isUrgent)
        return `${this.$t("to-do-list.hot-job-label-caps")}`.toUpperCase();

      return "";
    },

    /* ** ADMIN DATA ** */
    itemTracksAnyAdminWork(): boolean {
      return (
        this.adminTracksCountSheetEntry ||
        this.adminTracksTimeEntry ||
        this.adminTracksNormsEntry ||
        this.adminTracksScaffoldLocationEntry
      );
    },
    itemRequiresAdminEntry(): boolean {
      return (
        this.requiresCountSheetEntry ||
        this.requiresManHoursEntry ||
        this.requiresNormsEntry ||
        this.requiresScaffoldLocationEntry
      );
    },
    itemAdminEntryIsPartial(): boolean {
      if (!this.itemRequiresAdminEntry) return false;

      return (
        (!this.requiresCountSheetEntry || !this.missingCountSheetEntry) &&
        (!this.requiresManHoursEntry || this.manHoursEntryIsPartial) &&
        (!this.requiresNormsEntry || !this.missingNormsEntry) &&
        (!this.requiresScaffoldLocationEntry || !this.missingScaffoldLocationEntry)
      );
    },
    itemAdminEntryIsComplete(): boolean {
      if (!this.itemRequiresAdminEntry) return false;
      return (
        (!this.requiresCountSheetEntry || !this.missingCountSheetEntry) &&
        (!this.requiresManHoursEntry || this.manHoursEntryIsComplete) &&
        (!this.requiresNormsEntry || !this.missingNormsEntry) &&
        (!this.requiresScaffoldLocationEntry || !this.missingScaffoldLocationEntry)
      );
    },

    cardStatusIsSuccess(): boolean {
      return this.itemRequiresAdminEntry && this.itemAdminEntryIsComplete;
    },
    cardStatusIsError(): boolean {
      if (!this.itemRequiresAdminEntry) return false;
      if (this.cardStatusIsSuccess) return false;

      return !this.itemAdminEntryIsPartial && !this.itemAdminEntryIsComplete;
    },
    cardStatusIsWarning(): boolean {
      if (!this.itemRequiresAdminEntry) return false;
      if (this.cardStatusIsSuccess) return false;

      return this.itemAdminEntryIsPartial && !this.itemAdminEntryIsComplete;
    },

    itemAttachments(): Attachment[] {
      return this.item.attachments ?? [];
    },
    itemPhotos(): FileData[] {
      return this.item.photos ?? [];
    },

    /*
      itemTracks[DATA] = Whether the current item cares about these data at all.
      canEnter[DATA] = Whether the item tracks, and the current user has permission.
      requires[DATA] = Whether the item is in a state where it would be an error if these data were missing.
      has[DATA] = Whether the item has the data fully filled in.
      missing[DATA] = Whether the item is in an error state due to missing data
    */

    //#region COUNT SHEET
    /// Whether this item cares about count sheet entry at all
    itemTracksCountSheetEntry(): boolean {
      return this.item.type == "workOrder";
    },
    // Whether work order completion admin checks care about this item
    adminTracksCountSheetEntry(): boolean {
      return this.itemTracksCountSheetEntry;
    },
    canEnterMaterial(): boolean {
      if (!this.itemTracksCountSheetEntry) return false;

      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return !!this.item.currentUserPermissions.canEditAnything;
    },
    // Whether this item is in a state REQUIRING count sheet data to be entered
    requiresCountSheetEntry(): boolean {
      return (
        this.adminTracksCountSheetEntry &&
        (this.itemIsCompletedWorkOrder || this.countSheetIsDeclined)
      );
    },

    // Whether this item requires count sheet entry but doesn't have valid data
    missingCountSheetEntry(): boolean {
      return this.requiresCountSheetEntry && !this.hasCountSheetData;
    },
    // Whether this item has any count sheet data at all
    hasCountSheetData(): boolean {
      return !!this.item.countSheet && !this.countSheetIsDraft && !this.countSheetIsDeclined;
    },

    countSheetIsDraft(): boolean {
      return (
        !(this.item as WorkOrderWithAllDetails).countSheet ||
        (this.item as WorkOrderWithAllDetails).countSheet.reviewStatusID ==
          CountSheetReviewStatus.Draft
      );
    },
    countSheetIsDeclined(): boolean {
      return (
        !!(this.item as WorkOrderWithAllDetails).countSheet &&
        (this.item as WorkOrderWithAllDetails).countSheet.reviewStatusID ==
          CountSheetReviewStatus.Declined
      );
    },
    //#endregion

    //#region TIME ENTRY
    // Whether this item cares about this data at all.  If not, don't even show the button
    itemTracksTimeEntry(): boolean {
      if (!!this.preventLabourEntry) return false;

      return (
        this.item.type == "workOrder" &&
        !!this.$store.state.curEnvironment.enableTimesheets &&
        !!this.$store.state.curEnvironment.enableLabourEntry
      );
    },
    // Whether work order completion admin checks care about this item
    adminTracksTimeEntry(): boolean {
      return this.itemTracksTimeEntry;
    },
    // Whether the current user is currently able to enter this information, either due to permission or item state
    canEnterManHours(): boolean {
      if (!this.itemTracksTimeEntry) return false;

      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return !!this.item.currentUserPermissions.canEditAnything;
    },

    requiresManHoursEntry(): boolean {
      return (
        this.adminTracksTimeEntry && (this.itemIsCompletedWorkOrder || this.itemIsStartedWorkOrder)
      );
    },

    manHoursEntryIsMissing(): boolean {
      return (
        this.requiresManHoursEntry && !this.item.isAnyTimeEntered && !this.item.isTimeEntryCompleted
      );
    },
    manHoursEntryIsPartial(): boolean {
      return (
        this.requiresManHoursEntry &&
        !!this.item.isAnyTimeEntered &&
        !this.item.isTimeEntryCompleted
      );
    },
    manHoursEntryIsComplete(): boolean {
      return this.requiresManHoursEntry && !!this.item.isTimeEntryCompleted;
    },
    //#endregion

    //#region NORMS
    // Whether this item cares about this data at all.  If not, don't even show the button
    itemTracksNormsEntry(): boolean {
      if (!!this.preventNormsEntry) return false;
      return this.item.type == "workOrder";
    },
    // Whether work order completion admin checks care about this item
    adminTracksNormsEntry(): boolean {
      return false;
    },
    // Whether the current user is currently able to enter this information, either due to permission or item state
    canEnterNorms(): boolean {
      if (!this.itemTracksNormsEntry) return false;

      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return !!this.item.currentUserPermissions.canEditBuildSheetResponses;
    },
    // Whether the admin tracking considered this data as required due to item state
    requiresNormsEntry(): boolean {
      return false;
    },
    // Whether this item has the data entered
    hasNormsData(): boolean {
      return true;
    },
    // Whether this item considered the data "missing", specifically it doesn't have the data and the data is considered required
    missingNormsEntry(): boolean {
      return this.requiresNormsEntry && !this.hasNormsData;
    },
    //#endregion

    //#region SCAFFOLD LOCATION
    // Whether this item cares about this data at all.  If not, don't even show the button
    itemTracksScaffoldLocationEntry(): boolean {
      if (!!this.preventScaffoldLocationEntry) return false;
      if (!this.$store.state.curEnvironment.enableScaffoldLocation) return false;

      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return (
        toDoItem.type == "workOrder" && toDoItem.scaffoldRequestType == ScaffoldRequestTypes.Erect
      );
    },
    // Whether work order completion admin checks care about this item
    adminTracksScaffoldLocationEntry(): boolean {
      return (
        this.itemTracksScaffoldLocationEntry &&
        this.$store.state.curEnvironment.erectWorkOrdersRequireScaffoldLocation
      );
    },
    // Whether the current user is currently able to enter this information, either due to permission or item state
    canEnterScaffoldLocation(): boolean {
      var currentUserCanModifyData = true;
      return this.itemTracksScaffoldLocationEntry && currentUserCanModifyData;
    },
    // Whether the admin tracking considered this data as required due to item state
    requiresScaffoldLocationEntry(): boolean {
      return !!this.adminTracksScaffoldLocationEntry && this.itemIsCompletedWorkOrder;
    },
    // Whether this item has the data entered
    hasScaffoldLocationData(): boolean {
      let toDoItem = this.item as FormattedWorkOrder;
      if (!toDoItem) return false;
      return (
        toDoItem.type == "workOrder" &&
        toDoItem.scaffoldLatitude !== undefined &&
        toDoItem.scaffoldLatitude !== null &&
        toDoItem.scaffoldLongitude !== undefined &&
        toDoItem.scaffoldLongitude !== null
      );
    },
    // Whether this item considered the data "missing", specifically it doesn't have the data and the data is considered required
    missingScaffoldLocationEntry(): boolean {
      return this.requiresScaffoldLocationEntry && !this.hasScaffoldLocationData;
    }
    //#endregion
  }
});

