var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "px-0 mx-0" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "p-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      !!_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.length"
                              ),
                              value: _vm.length,
                              "hide-details": "",
                              "hide-spin-buttons": "",
                              type: "number",
                              min: "0",
                              "highlight-on-focus": ""
                            },
                            on: {
                              input: v =>
                                _vm.$emit(
                                  "update:length",
                                  _vm.$parse.sanitizedNumber(v)
                                )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.length"
                              ),
                              value: _vm.$parse.sanitizedNumber(_vm.length),
                              "hide-details": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "px-1 pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      !!_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.width"
                              ),
                              value: _vm.width,
                              "hide-details": "",
                              "hide-spin-buttons": "",
                              type: "number",
                              min: "0",
                              "highlight-on-focus": ""
                            },
                            on: {
                              input: v =>
                                _vm.$emit(
                                  "update:width",
                                  _vm.$parse.sanitizedNumber(v)
                                )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.width"
                              ),
                              value: _vm.$parse.sanitizedNumber(_vm.width),
                              "hide-details": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pl-1 pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      !!_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.height"
                              ),
                              value: _vm.height,
                              "hide-details": "",
                              "hide-spin-buttons": "",
                              type: "number",
                              min: "0",
                              "highlight-on-focus": ""
                            },
                            on: {
                              input: v =>
                                _vm.$emit(
                                  "update:height",
                                  _vm.$parse.sanitizedNumber(v)
                                )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.height"
                              ),
                              value: _vm.$parse.sanitizedNumber(_vm.height),
                              "hide-details": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "p-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pt-0 pb-0", attrs: { cols: "6" } },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          disabled: _vm.processing,
                          readonly: true,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.total-norm-hours-label"
                          ),
                          value: _vm.$format.number(_vm.totalNormHours, 2),
                          "hide-details": ""
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pl-1 pt-0 pb-0", attrs: { cols: "6" } },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          disabled: _vm.processing,
                          readonly: true,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.total-tracked-time-label"
                          ),
                          value: _vm.$format.number(_vm.totalTimesheetHours, 2),
                          "hide-details": ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t(
                        "norms.work-order-entry-dialog.scaffold-type"
                      ),
                      items: _vm.scaffoldTypeModifiers,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.scaffoldTypeModifierID,
                      "hide-details": ""
                    },
                    on: {
                      input: v => _vm.$emit("update:scaffoldTypeModifierID", v)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t(
                        "norms.work-order-entry-dialog.scaffold-type"
                      ),
                      value: _vm.$lookup.scaffoldTypeModifier(
                        _vm.scaffoldTypeModifierID
                      ),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-radio-group",
                {
                  staticClass:
                    "fd-scaffold-hazard-status-radio-group pt-2 mt-0 ml-0 ml-sm-2",
                  attrs: {
                    value: _vm.hazardStatusResult,
                    disabled: _vm.processing,
                    readonly: !_vm.canEditBuildSheet,
                    row: ""
                  },
                  on: { change: v => _vm.$emit("update:hazardStatusResult", v) }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-radio", {
                        class: [
                          _vm.hazardStatusResult == "green"
                            ? "fd-radio-hazard-status-green"
                            : "",
                          "mr-5"
                        ],
                        attrs: {
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.hazard-status-green"
                          ),
                          value: "green",
                          "data-cy": "hazardstatusgreen"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("v-radio", {
                        class: [
                          _vm.hazardStatusResult == "yellow"
                            ? "fd-radio-hazard-status-yellow"
                            : "",
                          "mr-5"
                        ],
                        attrs: {
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.hazard-status-yellow"
                          ),
                          value: "yellow",
                          "data-cy": "hazardstatusyellow"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("v-radio", {
                        class: [
                          _vm.hazardStatusResult == "red"
                            ? "fd-radio-hazard-status-red"
                            : "",
                          "mr-0"
                        ],
                        attrs: {
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.hazard-status-red"
                          ),
                          value: "red",
                          "data-cy": "hazardstatusred"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t(
                        "work-order-estimates.modifiers.scaffold-elevation"
                      ),
                      items: _vm.scaffoldElevationModifiers,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.scaffoldElevationModifierID,
                      "hide-details": ""
                    },
                    on: {
                      input: v =>
                        _vm.$emit("update:scaffoldElevationModifierID", v)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t(
                        "norms.work-order-entry-dialog.scaffold-elevation"
                      ),
                      value: _vm.$lookup.scaffoldElevationModifier(
                        _vm.scaffoldElevationModifierID
                      ),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "p-0 m-0 pl-1" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        staticClass: "pt-0 mt-1 fd-build-sheet-switch",
                        attrs: {
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.confined-space"
                          ),
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          "input-value": _vm.confinedSpace,
                          "hide-details": ""
                        },
                        on: {
                          change: v => _vm.$emit("update:confinedSpace", v)
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        staticClass: "pt-0 mt-1 fd-build-sheet-switch",
                        attrs: {
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.ppe-required"
                          ),
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          "input-value": _vm.ppeRequired,
                          "hide-details": ""
                        },
                        on: { change: v => _vm.$emit("update:ppeRequired", v) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("fd-text-field", {
                attrs: {
                  cy: "decklevels",
                  label: _vm.$t("walkdowns.existing-walkdown.deck-levels"),
                  disabled: _vm.processing,
                  readonly: !_vm.canEditBuildSheet,
                  value: _vm.deckLevels,
                  "hide-details": ""
                },
                on: {
                  change: v =>
                    _vm.$emit(
                      "update:deckLevels",
                      _vm.$parse.sanitizedNumber(v)
                    )
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _vm.$store.state.curEnvironment.trackScaffoldVLF
                ? _c("fp-add-remove-field", {
                    attrs: {
                      label: _vm.$t(
                        "walkdowns.existing-walkdown.vertical-length-feet"
                      ),
                      loading: _vm.processing,
                      disabled: _vm.processing,
                      readonly: !_vm.canEditBuildSheet,
                      "before-value": _vm.vlfBeforeValue,
                      "allow-add": _vm.allowVLFAdd,
                      "allow-remove": _vm.allowVLFRemove,
                      value: _vm.vlfChangeAmount,
                      "show-info": false
                    },
                    on: {
                      input: v =>
                        _vm.$emit(
                          "update:vlfChangeAmount",
                          _vm.$parse.sanitizedNumber(v)
                        )
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c("fd-text-field", {
                staticClass: "mb-3",
                attrs: {
                  disabled: _vm.processing,
                  readonly: !_vm.canEditBuildSheet,
                  label: _vm.$t("norms.work-order-entry-dialog.notes"),
                  value: _vm.notes,
                  "hide-details": ""
                },
                on: { input: v => _vm.$emit("update:notes", v) }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("sp-build-sheet-norm-form", {
        attrs: {
          processing: _vm.processing,
          normGroupsWithResponses: _vm.normGroupsWithResponses,
          canEditBuildSheetResponses: _vm.canEditBuildSheetResponses
        },
        on: {
          "update:normGroupsWithResponses": function($event) {
            _vm.normGroupsWithResponses = $event
          },
          "update:norm-groups-with-responses": function($event) {
            _vm.normGroupsWithResponses = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }